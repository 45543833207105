import { useEffect, useState } from 'react';
import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'react-daisyui';

import { Logo } from '@/components/Logo';
import { Spinner } from '@/components/Spinner';
import PageContainer from '@/components/PageContainer';
import { BrandShadowBox } from '@/components/BrandShadowBox';
import { IUserContext, useUserContext } from '@/context/UserContext';

// eslint-disable-next-line import/prefer-default-export
function Logout() {
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const { auth, clearImpersonationSession, logout }: IUserContext =
    useUserContext();

  useEffect(() => {
    if (auth) {
      logout().finally(() => {
        clearImpersonationSession();
      });
    }
    setIsLoading(false);
    setAlertMessage('You have successfully logged out');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer name='logout'>
      <div className='h-full flex flex-col items-center pt-40 mx-auto'>
        <BrandShadowBox className='flex flex-col items-center'>
          <Logo
            className='text-2xl ml-4 items-center w-[160px]'
            iconClassName='w-[41px] h-[41px]'
          />
          {isLoading && <Spinner className='w-12 h-12 mt-10' />}

          <Alert
            className={`rounded-2xl opacity-60 my-10 text-sm text-center w-[80%] transition-opacity duration-500 ${
              alertMessage ? 'opacity-100' : 'opacity-0'
            }`}
            icon={<CheckCircleIcon className='w-5 h-5' />}
          >
            <div>{alertMessage}</div>
          </Alert>
          {!isLoading && (
            <Link to='/login'>
              <Button
                title='login'
                className='btn btn-accent'
                startIcon={<ArrowRightCircleIcon className='w-5 h-5' />}
              >
                log in again
              </Button>
            </Link>
          )}
        </BrandShadowBox>
      </div>
    </PageContainer>
  );
}
export default Logout;
export { Logout };
