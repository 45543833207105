import { get } from '@/utils/api';
import { Folder } from '@types';
import { Button, Modal } from 'react-daisyui';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalReportGeneration.css';

function MonthDatePicker({
  onDateChange,
}: {
  onDateChange: (date: Date) => void;
}) {
  const maxDate = DateTime.now().minus({ month: 1 }).endOf('quarter');
  const [selectedQuarter, setSelectedQuarter] = useState<DateTime>(maxDate);
  const onQuarterSelected = (date: Date) => {
    setSelectedQuarter(DateTime.fromJSDate(date).endOf('quarter'));
    if (onDateChange) onDateChange(date);
  };
  useEffect(() => {
    if (onDateChange) onDateChange(maxDate.plus({ days: 1 }).toJSDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='relative m-1 text-gray-600 focus-within:text-gray-400'>
      <DatePicker
        selected={selectedQuarter.toJSDate()}
        onChange={onQuarterSelected}
        customInput={
          <Button
            className='outlined'
            startIcon={<FontAwesomeIcon icon={faCalendarDays} />}
          >
            Select quarter from
          </Button>
        }
        dateFormat='yyyy, QQQ'
        showQuarterYearPicker
        selectedDates={[selectedQuarter.toJSDate()]}
        popperPlacement='right'
        minDate={DateTime.local(2020, 1, 1).toJSDate()}
        maxDate={maxDate.toJSDate()}
      />
      <span className='m-2 font-bold text-white'>
        {`   from ${
          selectedQuarter.minus({ year: 1 }).plus({ days: 1 }).toISODate() || ''
        } until ${selectedQuarter.toISODate() || ''}`}
      </span>
    </div>
  );
}
export default function ModalReportGeneration({
  folder,
  setFolder,
}: {
  folder: Folder | null;
  setFolder: (folder: Folder | null) => void;
}) {
  const [endDate, setEndDate] = useState<Date>();
  function generateReport() {
    if (folder) {
      get(
        `qbo/reportProfitAndLoss?teamId=${folder.teamId}${
          endDate ? `&endDate=${endDate?.toISOString()}` : ''
        }`
      )
        .then(() => {
          toast(
            'Report generated successfully. The reports will appear shortly in their specific folder',
            { type: 'success' }
          );
          setFolder(null);
        })
        .catch(() => {
          toast('Error generating report', { type: 'error' });
        });
    }
  }
  return (
    folder && (
      <Modal className='w-11/12 max-w-5xl top-5' open={Boolean(folder)}>
        <Button
          size='sm'
          color='ghost'
          shape='circle'
          className='absolute right-2 top-2'
          onClick={() => setFolder(null)}
        >
          x
        </Button>
        <Modal.Header>Report Generation</Modal.Header>
        <Modal.Body>
          <div className='grid grid-rows-3'>
            <div className='instruction-text'>
              This action will generate five <b>Profit and Loss</b> reports from
              your QuickBooks Online (QBO) companies associated with this
              customer account (4 quarterly and 1 annually). By clicking
              &rdquo;Generate&rdquo;, you will initiate the process of clearing
              all existing content in both the annual and quarterly folders,
              subsequently replacing them with the new reports.
            </div>
            <div className='date-picker-section'>
              <MonthDatePicker onDateChange={setEndDate} />
            </div>
            <div className='m-2'>Are you sure you want to continue?</div>
            <div className='justify-self-end ml-2'>
              <Button className='btn btn-error' onClick={() => setFolder(null)}>
                Cancel
              </Button>
              <Button
                className='btn btn-success ml-2'
                onClick={() => generateReport()}
              >
                Generate
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
}
