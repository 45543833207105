/* istanbul ignore file */
import { Provider, ErrorBoundary } from '@rollbar/react';

const env = import.meta.env || process.env;

const rollbarConfig = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  accessToken: env.VITE_ROLLBAR_CLIENT_TOKEN,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  environment: env.VITE_ENV, // will not log dev errors to cloud
};

export default function ErrorReporter({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
