const slugify = (text: string): string =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .trim();

export const getNameInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  return initials;
};
export default slugify;
export { slugify };

export const prettifyKey = (key: string) => key.replace(/[_]/g, ' ');

export const prettifyValue = (value: string | number) => {
  if (typeof value === 'number') {
    return value.toLocaleString();
  }
  return value;
};
