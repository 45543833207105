import { isEmpty } from 'lodash';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { DashboardCard, LoadingCard } from '@/components/DashboardCard';
import { get } from '@/utils/queries';
import FilesCard from '@/components/FilesCard/FilesCard';
import { LastFinancialFilesCard } from './LastFinancialFilesCard';
import { MonthlyFirstUploads } from '../../../../shared/types/files';

// eslint-disable-next-line import/prefer-default-export
export function LastUpdatedAccountCard({ clientId }: { clientId: number }) {
  const { data: files } = get<MonthlyFirstUploads>(`files/company/${clientId}`);
  const { auth }: IUserContext = useUserContext(); // Use the context

  const isFileEmpty = isEmpty(files);
  if (isFileEmpty) {
    return <LoadingCard />;
  }

  return (
    <DashboardCard data-testid='last-account-file'>
      {!auth?.isInvestor ? (
        <>
          <LastFinancialFilesCard files={files} />
          <hr className='my-4' />
        </>
      ) : null}

      <FilesCard clientId={clientId} />
    </DashboardCard>
  );
}
