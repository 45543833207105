import { EventLog } from '@types';
import moment from 'moment';
import { Column } from '@/components/Table/Table';

export interface EventLogsTableData {
  id?: number;
  action: string;
  email_address: string;
  human_readable: string;
  note: string;
  path: string;
}

export const columns: Column<EventLogsTableData>[] = [
  { header: 'Title', accessor: 'action', sortable: true },
  { header: 'User', accessor: 'email_address', sortable: true },
  { header: 'Date', accessor: 'human_readable', sortable: true },
  {
    header: 'Note',
    accessor: 'note',
    sortable: false,
  },
  { header: 'Link', accessor: 'path', sortable: true },
];

export const mapApiEventLogsToEventLogsData = (values: EventLog[]) => {
  return values.map((value) => ({
    action: value.action,
    email_address: value.extra_data?.email_address || '',
    human_readable: moment(
      new Date(value.human_readable as unknown as string)
    ).format('MMM DD, YYYY'),
    note:
      [
        value.extra_data?.uploaded_file,
        value.extra_data?.downloaded_file,
        value.extra_data?.category &&
          `Category: ${value.extra_data.category}, `,
        value.extra_data?.memo && `Memo: ${value.extra_data.memo} `,
      ]
        .filter(Boolean)
        .join(' ') || 'none',
    path: value.extra_data?.path || '',
    id: value.id,
  }));
};
