import { Logo } from '@/components/Logo';
import PageContainer from '@/components/PageContainer';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export default function QBOAccessCallback() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const hasSentMessageRef = useRef(false);

  const realmId = params.get('realmId');
  const code = params.get('code');
  const message = `Don't close this pop up window, it will close automatically in a few
  seconds.`;

  useEffect(() => {
    if (!hasSentMessageRef.current && realmId && code) {
      hasSentMessageRef.current = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.opener.postMessage({ realmId, code }, '*');
    }
  }, [realmId, code]);

  return (
    <PageContainer name='qbo'>
      <div className='w-full h-screen flex flex-col justify-center items-center'>
        <Logo className='flex items-center text-6xl' />

        <div className='text-sm mt-10'>{message}</div>
      </div>
    </PageContainer>
  );
}
export { QBOAccessCallback };
