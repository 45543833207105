import { useState } from 'react';
import { GenericModal } from '@/components/Modal';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { patch, put } from '@/utils/api';
import { alertMessageSuccess } from '@/utils/alerts';
import { Button, Input } from 'react-daisyui';
import PasswordPolicyChecker from '@/components/PasswordPolicyChecker/PasswordPolicyChecker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
function ChangePasswordModal({ isOpen, onClose }: Props) {
  const { auth, removeRequestPasswordChange }: IUserContext = useUserContext(); // Use the context
  const userHasPassword = auth?.hasPassword;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value !== confirmPassword && confirmPassword.length > 0) {
      setPasswordError("Passwords don't match");
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    if (password !== e.target.value) {
      setPasswordError("Passwords don't match");
    } else {
      setPasswordError('');
    }
  };

  const handlePasswordValidityChange = (isValid: boolean) => {
    setIsPasswordValid(isValid);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isPasswordValid && passwordError.length === 0) {
      removeRequestPasswordChange();
      if (userHasPassword) {
        await patch('users/update-password', {
          newPassword: password.length === 0 ? null : password,
          email: auth?.email_address,
        });
      } else {
        await put('users/update-properties', [
          {
            password: password.length === 0 ? null : password,
            enable2FA: false,
            enablePassword: true,
            email: auth?.email_address,
          },
        ]);
      }
      alertMessageSuccess('Password update!');
      setPassword('');
      setConfirmPassword('');
      onClose();
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <div className='p-4'>
        <h2 className='text-base uppercase font-bold mb-4'>
          {' '}
          Password settings
        </h2>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit} data-testid='form-2faenabled'>
          <div className='mt-4 space-y-4'>
            <div className='flex flex-wrap gap-4 pb-2 mt-4'>
              <div className='capitalize w-[23%] text-sm text-right pr-3'>
                New Password
              </div>
              <div className='font-bold text-sm w-[73%] text-left pl-3'>
                <Input
                  size='sm'
                  type='password'
                  className='border-accent'
                  data-testid='txt-password'
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>
            <div className='flex flex-wrap gap-4 pb-2 mt-4'>
              <div className='capitalize w-[23%] text-sm text-right pr-3'>
                Confirm New Password
              </div>
              <div className='font-bold text-sm w-[73%] text-left pl-3'>
                <Input
                  size='sm'
                  type='password'
                  className='border-accent'
                  data-testid='txt-new-password'
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <PasswordPolicyChecker
                  password={password}
                  isVisible={password !== ''}
                  onPasswordValidChange={handlePasswordValidityChange}
                />
                <div className='font-bold text-sm w-[73%] text-left pl-3 mt-6 '>
                  <Button
                    type='submit'
                    title='login'
                    color='accent'
                    size='sm'
                    className='btn btn-accent'
                    data-testid='submit-update-user'
                  >
                    <FontAwesomeIcon icon={faKey} className='mr-1' />
                    Update user
                  </Button>
                </div>
              </div>
            </div>

            {passwordError && <p className='text-red-600'>{passwordError}</p>}
          </div>
        </form>
      </div>
    </GenericModal>
  );
}

export default ChangePasswordModal;
