import { useMemo } from 'react';
import { GenericModal } from '@/components/Modal';
import { ScheduleCallCard } from '@/components/ScheduleCallCard';
import { IUserContext, useUserContext } from '@/context/UserContext';
import type { User } from '@types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
function ScheduleModal({ isOpen, onClose }: Props) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext(); // Use the context

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const clientId = useMemo(() => {
    // admins will always view this page with a clientId query param
    if ((auth?.isAdmin || auth?.isInvestor) && impersonatingAccountId) {
      return impersonatingAccountId;
    }

    const userClientId = auth?.clients?.[0]?.id;

    return userClientId ?? undefined;
  }, [impersonatingAccountId, auth]);

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <ScheduleCallCard
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        clientId={clientId as number}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        user={auth as unknown as User}
        test={false}
      />
    </GenericModal>
  );
}

export default ScheduleModal;
