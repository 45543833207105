import { get } from '@/utils/queries';
import { User } from '@shared/types/user';
import { mutate } from 'swr';

const baseRoute = 'users/list';
const getUsersList = () => get<User[]>(baseRoute);

function useUsersList() {
  const refreshUsersList = async () => {
    await mutate(baseRoute);
  };

  const getUsersListByClientId = (clientId: number) =>
    get<User[]>(`${baseRoute}/${clientId}`);

  const refreshUsersListByClientId = async (clientId: number) => {
    await mutate(`${baseRoute}/${clientId}`);
  };

  return {
    getUsersList,
    refreshUsersList,
    getUsersListByClientId,
    refreshUsersListByClientId,
  };
}

export default useUsersList;
