import { useMemo } from 'react';
import Select from 'react-select';
import { Alert } from 'react-daisyui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Client } from '@types';
import { get } from '@/utils/queries';
import { Spinner } from '@/components/Spinner';
import { EmptyState } from '@/components/EmptyState';

// eslint-disable-next-line import/prefer-default-export
export function ClientListInput({
  value,
  onChange,
  multi = true,
}: {
  value: { value: number; label: string }[];
  onChange: (newClientIds: unknown) => void;
  multi?: boolean;
}) {
  const { data: clients } = get<Client[]>('clients');

  const options = useMemo(
    () =>
      clients?.map((client) => ({
        value: client.id,
        label: client.client_name,
      })),
    [clients]
  );

  if (!clients) {
    return <Spinner />;
  }

  if (!clients.length) {
    return <EmptyState name='clients' />;
  }

  return (
    <>
      <Select
        isMulti={multi}
        className='react-select-container border-accent'
        classNamePrefix='react-select'
        placeholder='select clients ...'
        closeMenuOnSelect={false}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        options={options}
        value={value}
        onChange={onChange}
      />
      {!value?.length && multi && (
        <Alert
          icon={<ExclamationTriangleIcon className='w-5 h-5' />}
          className='my-2 text-sm'
        >
          Admin users should have at least one client assigned.
        </Alert>
      )}
    </>
  );
}
