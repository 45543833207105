import React, { useState } from 'react';
import { Button, Steps } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface GenericWizardSteps {
  title: string;
}

type StepElement = React.ReactElement<GenericWizardSteps>;

export default function WizardComponent({
  children,
  disableStep,
}: {
  children: StepElement[];
  disableStep: boolean;
}) {
  const [step, setStep] = useState<number>(1);
  const maxStep = children.length;

  return (
    <>
      <div className='flex justify-center'>
        <Steps className='w-full'>
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const { title } = child.props;
              return (
                <Steps.Step
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  color={step >= index + 1 ? 'primary' : undefined}
                >
                  {title}
                </Steps.Step>
              );
            }
            return null;
          })}
        </Steps>
      </div>
      <br />
      <br />
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{step === index + 1 ? child : null}</div>
      ))}
      <br />
      <br />
      <div className='flex items-center space-x-4 justify-between'>
        {step > 1 ? (
          <Button
            type='submit'
            title='next'
            color='primary'
            size='sm'
            className='btn btn-primary'
            onClick={() => {
              setStep(step - 1);
            }}
            data-testid='permissions-prev'
          >
            <FontAwesomeIcon icon={faArrowLeft} className='mr-1' />
            Previous
          </Button>
        ) : (
          <div />
        )}
        {step < maxStep ? (
          <Button
            type='submit'
            title='login'
            color='primary'
            size='sm'
            className='btn btn-primary'
            disabled={disableStep}
            onClick={() => {
              setStep(step + 1);
            }}
            data-testid='wizard-next'
          >
            <FontAwesomeIcon icon={faArrowRight} className='mr-1' />
            Next
          </Button>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}
