import { useMemo } from 'react';
import type { KVPairs, PlanData, DealData } from '@types';
import moment from 'moment';
import { AccountDealAccordionList } from './AccountDealAccordionList';

const NAMES_MAP: KVPairs = {
  company_group_email: 'Company Group Email',
  company_referred_by: 'Company Referred By',
  closed_date: 'Closed Date',
};

interface AccountPlanDetailsProps {
  plan: PlanData;
  size?: 'sm' | 'md';
  showHubspotLink?: boolean;
  deals: DealData[];
}

// eslint-disable-next-line import/prefer-default-export
export function AccountPlanDetails({
  plan,
  size = 'md',
  showHubspotLink,
  deals,
}: AccountPlanDetailsProps) {
  const currentPlan = useMemo(() => plan, [plan]);
  const fontSize = size === 'md' ? 'text-base' : 'text-sm';
  return (
    <div className='mb-4' data-testid='account-plan-details'>
      <div className='flex'>
        <div className='flex-grow'>
          <div className='mt-2'>
            <span className={`${fontSize} capitalize text-sm text-right pr-3`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP.company_group_email}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {currentPlan.company_group_email
                ? currentPlan.company_group_email
                : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} capitalize text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP.company_referred_by}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {currentPlan.company_referred_by
                ? currentPlan.company_referred_by
                : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} capitalize text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP.closed_date}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {currentPlan.closed_date
                ? moment(new Date(currentPlan.closed_date.toString())).format(
                    'MMM DD, YYYY'
                  )
                : 'N/A'}
            </span>
          </div>
        </div>
      </div>
      <AccountDealAccordionList
        deals={deals}
        fontSize={fontSize}
        showHubspotLink={showHubspotLink}
      />
    </div>
  );
}
