import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { QboInvoices } from '../../../shared/types';

function useInvoicesData() {
  const baseRoute = '/api/qbo/invoices/';

  const getInvoicesData = (clientId?: number) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<QboInvoices[], Error>(
      `${baseRoute}${Number(clientId)}`,
      fetcher,
      {
        revalidateOnMount: true,
        revalidateOnFocus: false,
      }
    );
  };

  return { getInvoicesData };
}

export default useInvoicesData;
