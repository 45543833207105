interface IndexedObject {
  [key: string]: string; // Allow indexing by string
}

export const rejectForDuplicatedStrings = (
  array: IndexedObject[],
  propertiesName: string[]
) => {
  const uniqueValues = propertiesName.map(() => new Set<string>());
  array.forEach((item) => {
    propertiesName.forEach((propertyName, index) => {
      if (uniqueValues[index].has(item[propertyName].toLocaleLowerCase())) {
        throw new Error(
          `Duplicate with property ${propertyName}: ${item[propertyName]}`
        );
      }
      uniqueValues[index].add(item[propertyName].toLocaleLowerCase());
    });
  });
};

export default { rejectForDuplicatedStrings };
