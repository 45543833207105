import { Client, InvestorReportCardDTO } from '@types';
import { InvestorReportCardNewStatus } from '@/pages/InvestorReporting/components/Clients/InvestorReportCardNewStatus';
import { InvestorReportCardCompleted } from '@/pages/InvestorReporting/components/Clients/InvestorReportCardCompleted';
import { ReportStatuses } from '../../../../../../shared/constants';

export function InvestorReportAnswer({
  investorReportsCard,
  client,
  setSpinner,
  onCardUpdated,
  isFullWidth,
}: {
  investorReportsCard: InvestorReportCardDTO;
  client: Client;
  setSpinner: (value: boolean) => void;
  onCardUpdated: () => void;
  isFullWidth?: boolean;
}) {
  return (
    <div
      className={`${
        isFullWidth ? 'w-full' : 'w-[50%]'
      } top-[60px] right-0 h-auto animate-in slide-in-from-right`}
      style={{ borderLeft: `${isFullWidth ? 'none' : '1px solid #d3d3d3'}` }}
    >
      <div className='p-4'>
        Title:
        <span className='ml-2 font-bold'>{investorReportsCard.title}</span>
      </div>
      <div className='px-4 pb-4'>
        Description:
        <span className='ml-2 font-bold'>
          {investorReportsCard.description}
        </span>
      </div>
      {investorReportsCard.status === ReportStatuses.new ? (
        <InvestorReportCardNewStatus
          investorReportsCard={investorReportsCard}
          client={client}
          setSpinner={setSpinner}
          onCardUpdated={onCardUpdated}
        />
      ) : null}
      {investorReportsCard.status === ReportStatuses.completed ? (
        <InvestorReportCardCompleted
          investorReportsCard={investorReportsCard}
        />
      ) : null}
    </div>
  );
}
