import { QboInvoices, Client, HubspotPlan } from '@types';
import { Column } from '@/components/Table/Table';
import { calculateDueDateBalances } from '@/pages/ClientList/utils';

export interface TableARData {
  id?: number;
  client_name: string;
  controller: string;
  less_than_30_days?: number;
  less_than_60_days?: number;
  less_than_90_days?: number;
  more_than_90_days?: number;
  balance: number;
  notes: string;
}

export const columns: Column<TableARData>[] = [
  { header: 'Client Name', accessor: 'client_name', sortable: true },
  { header: 'Controller', accessor: 'controller', sortable: true },
  { header: '1 - 30', accessor: 'less_than_30_days', sortable: false },
  { header: '31 - 60', accessor: 'less_than_60_days', sortable: false },
  { header: '61 - 90', accessor: 'less_than_90_days', sortable: false },
  { header: '90+', accessor: 'more_than_90_days', sortable: false },
  { header: 'Total Balance', accessor: 'balance', sortable: true },
  { header: 'Notes', accessor: 'notes', sortable: false },
];

export const mapApiInvoicesToTableARData = (
  invoices: QboInvoices[],
  handleController: (plan: HubspotPlan | undefined) => string | undefined
): TableARData[] => {
  const groupedInvoices: { [key: string]: QboInvoices[] } = {};
  invoices.forEach((invoice) => {
    const clientId = invoice.qbo_customer?.client?.id;
    if (clientId) {
      if (!groupedInvoices[clientId]) {
        groupedInvoices[clientId] = [];
      }
      groupedInvoices[clientId].push(invoice);
    }
  });

  return Object.keys(groupedInvoices).map((clientId) => {
    const clientInvoices = groupedInvoices[clientId];
    const client = clientInvoices[0].qbo_customer?.client as Client;

    const totalBalances = clientInvoices.reduce(
      (acc, invoice) => acc + Number(invoice.balance),
      0
    );

    const latestNotes = client.qbo_configuration_data?.internal_notes
      ? client.qbo_configuration_data?.internal_notes.slice(-1)[0]
          ?.internal_note
      : '';

    return {
      client_name: client.client_name,
      controller:
        handleController(client.hubspot_data?.detailedData?.plan) || '',
      less_than_30_days: clientInvoices
        .map((invoice) => calculateDueDateBalances(invoice)['1-30'])
        .reduce((a, b) => (a ?? 0) + (b ?? 0), 0),
      less_than_60_days: clientInvoices
        .map((invoice) => calculateDueDateBalances(invoice)['31-60'])
        .reduce((a, b) => (a ?? 0) + (b ?? 0), 0),
      less_than_90_days: clientInvoices
        .map((invoice) => calculateDueDateBalances(invoice)['61-90'])
        .reduce((a, b) => (a ?? 0) + (b ?? 0), 0),
      more_than_90_days: clientInvoices
        .map((invoice) => calculateDueDateBalances(invoice)['90+'])
        .reduce((a, b) => (a ?? 0) + (b ?? 0), 0),
      balance: totalBalances,
      notes: latestNotes,
      id: client.id,
    };
  });
};
