import type { KVPairs, DealData } from '@types';
import { openHubspotDeal } from '@/utils/hubspot';
import { Button } from 'react-daisyui';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Accordion } from '@/components/Accordion/Accordion';

const NAMES_MAP_DEAL: KVPairs = {
  status: 'Status',
  mrr: 'MRR',
  controller: 'Controller',
  business_lines: 'Business Lines',
  montly_hour: 'Monthly Hour Cap',
  sales_owner: 'Sales Owner',
};

interface SalesDealsProps {
  deal: DealData;
  index: number;
  fontSize: string;
  showHubspotLink?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function SalesDealDetails({
  deal,
  index,
  fontSize,
  showHubspotLink,
}: SalesDealsProps) {
  const linkValidStatus = ['closedwon', 'churned'];

  return (
    <Accordion
      key={`${deal.deal_id}`}
      showBottomSeparator
      open={index === 0}
      toggler={
        <span className={`${fontSize} font-bold capitalize`}>
          {deal.deal_name}
        </span>
      }
    >
      <div className='flex'>
        <div className='flex-grow'>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.status}:`}
            </span>
            <span className={`${fontSize} font-bold`}>{deal.status}</span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.mrr}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.amount_monthly ? `$ ${deal.amount_monthly}` : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.business_lines}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.business_lines
                ? deal.business_lines.split(';').join('\n')
                : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.controller}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.controller ? deal.controller : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.montly_hour}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.monthly_hours_cap ? `${deal.monthly_hours_cap}h` : 'N/A'}
            </span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.sales_owner}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.sales_owner ? deal.sales_owner : 'N/A'}
            </span>
          </div>
        </div>
        {showHubspotLink &&
        deal.deal_id &&
        deal.pipeline === 'default' &&
        linkValidStatus.includes(deal.status) ? (
          <div className='self-end'>
            <Button
              color='warning'
              data-testid='hubspot-button'
              variant='outline'
              size='sm'
              onClick={() => {
                openHubspotDeal(deal?.deal_id);
              }}
              startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
            />
          </div>
        ) : null}
      </div>
    </Accordion>
  );
}
