import { get } from '@/utils/queries';
import { RootFolder } from '@types';
import { mutate } from 'swr';

function useFileData() {
  const baseRoute = 'files';
  const getFileData = (id?: number) =>
    get<RootFolder>(id ? `${baseRoute}/${id}` : baseRoute);

  const refreshFileData = async (id?: number) => {
    await mutate(id ? `${baseRoute}/${id}` : baseRoute);
  };

  return { getFileData, refreshFileData };
}

export default useFileData;
