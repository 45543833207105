/* eslint-disable jsx-a11y/no-static-element-interactions */
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

interface GenericModalProps {
  isOpen?: boolean;
  onClose: () => void;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function GenericModal({
  isOpen = false,
  onClose,
  children,
  isFullWidth = false,
  isFullHeight = false,
  className,
}: GenericModalProps) {
  const checkRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const toggleModal = (openStatus: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    checkRef.current ? (checkRef.current.checked = openStatus) : null;
    if (!openStatus) onClose();
  };

  const cssWidth = () => {
    let result = 'modal-box';
    if (className) result += ` ${className}`;
    if (isFullWidth) result += ' max-w-full';
    return result;
  };

  useEffect(() => {
    toggleModal(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleModalClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Prevent closing the modal when clicking inside it
    event.stopPropagation();
  };

  return (
    <>
      {/* This checkbox is needed to handle the modal state, this was taken from here:  https://daisyui.com/components/modal/ */}
      <input
        type='checkbox'
        ref={checkRef}
        data-testid='modal-checkbox-status'
        id='modal-status'
        title='status'
        className='modal-toggle'
      />

      <div
        className='flex flex-col flex-1 modal'
        data-testid='modal'
        onClick={handleModalClick}
      >
        <div
          className={`${cssWidth()} ${
            isFullHeight ? 'max-h-screen h-screen' : ''
          }`}
          ref={modalRef}
        >
          <FontAwesomeIcon
            color='#d3d3d3'
            icon={faCircleXmark}
            data-testid='modal-close-button'
            size='xl'
            onClick={() => toggleModal(false)}
            className='pt-1 pb-1 cursor-pointer z-10 absolute right-2 top-2 '
          />
          {children}
        </div>
      </div>
    </>
  );
}
export { GenericModal };
