import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconDefinitionByName } from '@/components/Nav/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Spinner } from '@/components/Spinner';
import { Button } from 'react-daisyui';
import { FileOrFolderAccordion } from '@/components/UsersAccordionList/EditPermissionsModalSteps/fileOrFolderAccordion';
import { hasSubItems } from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { FileItem } from '@types';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import {
  FileItemExtended,
  CustomTabItemExtended,
  SyftItemExtended,
} from './interfaces';

interface OverviewStepProps extends GenericWizardSteps {
  folderList: (FileItem[] & FileItemExtended[]) | undefined;
  customTabs: CustomTabItemExtended[];
  isSaving: boolean;
  onSave: () => void;
  syftDashboardOption: SyftItemExtended[];
}

export default function OverviewStep({
  folderList,
  customTabs,
  isSaving,
  onSave,
  syftDashboardOption,
}: OverviewStepProps) {
  const showSelectedCustomTabs = () => (
    <>
      <div className='text-sm font-bold capitalize'>Selected Tabs</div>
      {customTabs.length === 0 && <div>No Custom Tabs Selected</div>}
      <div>
        {customTabs.map((tab) => (
          <div key={`selected-tab-${tab.titleOfTab}`}>
            <br />
            <div className='flex items-center cursor-pointer'>
              <FontAwesomeIcon
                icon={getIconDefinitionByName(tab.icon) as IconProp}
                size='lg'
                className='px-2'
              />
              <h3
                key={`selected-title-tab-${tab.titleOfTab}`}
                className='text-base ml-2'
              >
                {tab.titleOfTab}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  const showSyftDashboardDisplay = () => (
    <>
      <div className='text-sm font-bold capitalize'>Dashboard Selected</div>
      <br />
      {syftDashboardOption.length === 0 && <div>No</div>}
      <div>
        {syftDashboardOption[0]?.isChecked && (
          <div className='flex items-center cursor-pointer'>
            <h3 className='text-base ml-2'>Yes</h3>
          </div>
        )}
      </div>
    </>
  );

  const displayFiles = (files: FileItemExtended[] | undefined) => {
    if (!files) {
      return null;
    }
    return files.map((file) => (
      <FileOrFolderAccordion
        open={false}
        fatherIsOpen
        level={0}
        showCheckbox={false}
        file={file}
        // setFilesIsChecked={undefined}
        showAccordionIcon={hasSubItems(file)}
        key={`accordion-${file.id}`}
      />
    ));
  };

  const showSelectedFiles = () => (
    <>
      <div className='text-sm font-bold capitalize'>Selected Files</div>
      {!folderList || folderList?.length === 0 ? (
        <div>No Files Selected</div>
      ) : null}
      <div>{displayFiles(folderList)}</div>
    </>
  );

  const checkDisabled = () => {
    if (customTabs.length === 0 && (!folderList || folderList?.length === 0)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='text font-bold capitalize'>
          Confirm the following permissions:
        </div>
      </div>
      <br />
      {showSyftDashboardDisplay()}
      <br />
      {showSelectedCustomTabs()}
      <br />
      {showSelectedFiles()}
      <br />
      <div className='flex justify-center'>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onSave}
          disabled={checkDisabled()}
        >
          Confirm
          {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
        </Button>
      </div>
    </>
  );
}
