import { fetcher } from '@/utils/queries';
import useSWR, { mutate } from 'swr';
import type { Client } from '@types';

function useAccountData() {
  const baseRoute = '/api/account/';

  const getAccountData = (clientId?: number, avoidRevalidate?: boolean) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<Client, Error>(
      clientId ? `${baseRoute}${clientId}` : baseRoute,
      fetcher,
      {
        revalidateOnMount: !avoidRevalidate, // default is true
        revalidateOnFocus: false,
      }
    );
  };

  const refreshAccountData = async (clientId: number) => {
    await mutate(`${baseRoute}${clientId}`);
  };

  return { getAccountData, refreshAccountData };
}

export default useAccountData;
