/**
 * Retrieves the link value (embeddedLink or documentLink) corresponding to the specified linkToSearch in the params object,  regardless of the order in which the links appear.
 * If the linkToSearch is found, the function concatenates any additional param in the params object and returns the concatenated string.
 * If the linkToSearch is not found, the function returns an empty string.
 * If the linkToSearch is found more than once, the functions return the link for the first one.
 *
 * @param linkToSearch - The link to search for in the params object.
 * @param params - The object containing the parameters and their values.
 * @returns The link value corresponding to the linkToSearch in the params object. If the linkToSearch is not found, an empty string is returned.
 */
export const getLinkFromParams = (
  linkToSearch: string,
  params: URLSearchParams
) => {
  let linkValue = '';
  let linkFounded = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of params) {
    if (!linkFounded) {
      if (name === linkToSearch) {
        linkFounded = true;
        linkValue = value;
      }
    } else {
      if (
        name ===
        (linkToSearch === 'embeddedLink' ? 'documentLink' : 'embeddedLink')
      ) {
        break;
      }
      if (name === linkToSearch) {
        break;
      }
      linkValue = `${linkValue}&${name}=${value}`;
    }
  }
  return linkValue;
};

export const getLinksFromParams = (params: URLSearchParams) => {
  const documentLink = getLinkFromParams('documentLink', params);
  const embeddedLink = getLinkFromParams('embeddedLink', params);
  return { documentLink, embeddedLink };
};
