import React, { useEffect, useState } from 'react';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

export default function FeatureToggle({
  featureTag,
  filterByRole = false,
  filterByUser = false,
  children,
}: {
  featureTag: string;
  filterByRole?: boolean;
  filterByUser?: boolean;
  children: React.ReactNode;
}) {
  const isFeatureActive = useFeatureFlags(
    featureTag,
    filterByUser,
    filterByRole
  );
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(!!isFeatureActive);
  }, [isFeatureActive]);

  if (!isActive) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
