import { useState } from 'react';
import type { FileItem } from '@types';
import { post } from './queries';
import { validAdminsRoles } from '../../../shared/constants';

const useLogEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const logEvent = async (data: FileItem, clientId: number, role: string) => {
    setLoading(true);
    try {
      if (validAdminsRoles.includes(role)) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await post(`logs`, {
        name: data.name,
        type: data.type,
        created: data.created_at,
        fileId: data.id,
        fileTeamId: data.teamId,
        clientId,
      });
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  };

  return { logEvent, loading, error };
};

export default useLogEvent;
