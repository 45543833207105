import { GenericModal } from '@/components/Modal';
import { ReferralCard } from '@/components/ReferralCard';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { useMemo } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
function ReferralModal({ isOpen, onClose }: Props) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext(); // Use the context

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const clientId = useMemo(() => {
    // admins will always view this page with a clientId query param
    if ((auth?.isAdmin || auth?.isInvestor) && impersonatingAccountId) {
      return impersonatingAccountId;
    }

    const userClientId = auth?.clients?.[0]?.id;

    return userClientId ?? undefined;
  }, [impersonatingAccountId, auth]);
  const isInvestor = auth?.isInvestor;

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <ReferralCard
        clientId={clientId as number}
        isInvestor={isInvestor as boolean}
      />
    </GenericModal>
  );
}

export default ReferralModal;
