import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faRefresh, faBell } from '@fortawesome/free-solid-svg-icons';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { get } from '@/utils/queries';
import { LoadingPage } from '@/pages/LoadingPage';
import { EmptyState } from '@/components/EmptyState';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { Client, QBOCompanyData } from '@types';
import { Button } from 'react-daisyui';
import { useMemo, useState } from 'react';
import { get as getApi } from '@/utils/api';
import { TableTransactions } from './TableTransactions';
import ModalReminder from './ModalReminder';

export default function Transactions() {
  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);
  const [selectedQBOCompanyId, setSelectedQBOCompanyId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { companyName, impersonatingAccountId, auth }: IUserContext =
    useUserContext(); // Use the context

  const transactionsListAPIUrl = '/transactions/fulldata';
  const {
    data: qboData,
    isValidating: isLoading,
    error,
  } = impersonatingAccountId
    ? get<QBOCompanyData[]>(
        `${transactionsListAPIUrl}/${impersonatingAccountId}`
      )
    : get<QBOCompanyData[]>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${transactionsListAPIUrl}/${auth?.clients?.[0]?.id}`
      );

  const { data: clients } = get<Client[]>('account/list');

  const sortedCompanies = useMemo(
    () => {
      if (qboData && !isLoading) {
        const sortedComps = qboData.sort(
          (a, b) => b.qbo_transactions.length - a.qbo_transactions.length
        );
        return sortedComps;
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qboData]
  );

  const refreshQBOData = async () => {
    setRefreshInProgress(true);

    try {
      await getApi('transactions/syncQBO');
    } finally {
      setTimeout(() => setRefreshInProgress(false), 300);
    }
  };

  const handleCompanySelect = (qboCompanyId: number) => {
    setSelectedQBOCompanyId(qboCompanyId);
  };

  if (!qboData && isLoading) {
    return <LoadingPage hasNav />;
  }

  if (error) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper
          companyName={companyName as string}
          companyData={clients}
        >
          <ErrorAlert />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!sortedCompanies.length) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper
          companyName={companyName as string}
          companyData={clients}
        >
          <EmptyState name='transactions' />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer name='transactions'>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <DashboardWrapper
        companyName={companyName as string}
        companyData={clients}
      >
        {auth?.isAdmin ? (
          <ModalReminder
            clientId={impersonatingAccountId || auth?.clients?.[0]?.id}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        ) : null}
        <div className='p-4'>
          <div className='flex w-full justify-between items-center mb-2.5'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faTasks}
                size='lg'
                className='mr-4 text-white'
              />
              Help us Categorize these transactions
            </h1>
            {auth?.isAdmin ? (
              <div>
                <Button
                  color='accent'
                  id='reminder'
                  title='reminder'
                  size='sm'
                  className='btn btn-accent mr-2'
                  data-testid='reminder-button'
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faBell} size='lg' className='mr-2' />
                  Send task reminder
                </Button>
                <Button
                  color='accent'
                  id='refresh'
                  size='sm'
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={() => refreshQBOData()}
                  data-testid='refresh-button'
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    size='lg'
                    className={`mr-2 text-white ${
                      refreshInProgress ? 'spinning' : ''
                    }`}
                  />
                  Refresh QBO Data
                </Button>
              </div>
            ) : null}
          </div>
          {sortedCompanies.map((comp, index) => (
            <button
              type='button'
              key={`button-${comp.id as number}`}
              className={`${
                selectedQBOCompanyId === index ? 'bg-blue-500' : 'bg-gray-400'
              } hover:bg-blue-700 text-white font-bold rounded-full btn-sm m-1`}
              // disabled={selectedQBOCompanyId !== (index)}
              onClick={() => handleCompanySelect(index)}
            >
              {comp.name} - {comp.qbo_transactions.length}
            </button>
          ))}
          <div className='text-sm my-2 capitalize font-bold'>
            Transctions for:{' '}
            {(qboData as QBOCompanyData[])[selectedQBOCompanyId].name} - Pending
            Transactions:{' '}
            {
              (qboData as QBOCompanyData[])[selectedQBOCompanyId]
                .pending_transactions
            }
          </div>
          <TableTransactions
            transactions={
              (qboData as QBOCompanyData[])[selectedQBOCompanyId]
                .qbo_transactions
            }
          />
        </div>
      </DashboardWrapper>
    </PageContainer>
  );
}
