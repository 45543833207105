import { useMemo, useState } from 'react';

const useImpersonate = () => {
  const [impersonatingAccountId, setimpersonatingAccountId] = useState<
    number | null
  >(
    localStorage.getItem('impersonatingAccountId')
      ? parseInt(localStorage.getItem('impersonatingAccountId') as string, 10)
      : null
  );
  const [companyName, setCompanyName] = useState<string | null>(
    localStorage.getItem('companyName')
      ? localStorage.getItem('companyName')
      : null
  );
  const value = useMemo(
    () => ({
      impersonatingAccountId,
      setimpersonatingAccountId,
      companyName,
      setCompanyName,
      impersonate: (id: number, name: string) => {
        localStorage.setItem('impersonatingAccountId', id.toString());
        localStorage.setItem('companyName', name);
        setimpersonatingAccountId(id);
        setCompanyName(name);
      },
      clearImpersonationSession: () => {
        localStorage.removeItem('impersonatingAccountId');
        localStorage.removeItem('companyName');
        setimpersonatingAccountId(null);
        setCompanyName(null);
      },
    }),
    [impersonatingAccountId, companyName]
  );
  return value;
};
export default useImpersonate;
