import { useMemo } from 'react';
import type { DealData } from '@types';
import { SalesDealDetails } from '@/components/AccountDetails/components/DealDetails/SalesDealDetails';
import { TaxesDealDetails } from '@/components/AccountDetails/components/DealDetails/TaxesDealDetails';
import { filteredSalesDeals, filteredTaxesDeals } from '@/utils';

interface AccountDealAccordionListProps {
  deals: DealData[];
  fontSize: string;
  showHubspotLink?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function AccountDealAccordionList({
  deals,
  fontSize,
  showHubspotLink,
}: AccountDealAccordionListProps) {
  const salesDeals = useMemo(() => filteredSalesDeals(deals), [deals]);

  const taxesDeals = useMemo(() => filteredTaxesDeals(deals), [deals]);
  const dealsAmount =
    (salesDeals ? salesDeals.length : 0) + (taxesDeals ? taxesDeals.length : 0);

  if (dealsAmount <= 0) {
    return <h2>No Deals</h2>;
  }

  return (
    <>
      {dealsAmount > 0 && (
        <div>
          <hr className='mt-6 mb-2' />
          <h2 className='text-xl  my-2 capitalize'>Deals</h2>
        </div>
      )}
      {salesDeals?.map((deal: DealData, index: number) => (
        <SalesDealDetails
          deal={deal}
          key={`sales_deal_${deal.deal_id}`}
          index={index}
          fontSize={fontSize}
          showHubspotLink={showHubspotLink}
        />
      ))}
      {taxesDeals?.map((deal: DealData, index: number) => (
        <TaxesDealDetails
          deal={deal}
          key={`taxes_deal_${deal.deal_id}`}
          index={index}
          fontSize={fontSize}
          showHubspotLink={showHubspotLink}
        />
      ))}
    </>
  );
}
