import { useState } from 'react';
import {
  DocumentDuplicateIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { DashboardCard, LoadingCard } from '@/components/DashboardCard';

import { get } from '@/utils/queries';
import { slugify } from '@/utils/string';
import { Client } from '@types';

// eslint-disable-next-line import/prefer-default-export
export function ReferralCard({
  clientId,
  isInvestor,
  investorEmail,
}: {
  clientId: number;
  isInvestor: boolean;
  investorEmail?: string;
}): JSX.Element | null {
  const [copied, setCopied] = useState(false);
  if (!clientId && !investorEmail) return null;

  const { data: companyData } = get<Client>(`account/${clientId}`);

  const companyName: string =
    investorEmail ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    companyData?.hubspot_data?.detailedData?.business_info?.company_name ||
    '';

  // eslint-disable-next-line no-nested-ternary
  const link = companyName
    ? `${window.location.host}/referral/${slugify(companyName)}`
    : '';

  const copyLink = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(`https://${link}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  if (!companyName) {
    return <LoadingCard />;
  }

  return (
    <DashboardCard data-testid='referral-card'>
      <div className='flex h-full flex-col justify-center'>
        <div className='flex items-center'>
          <FontAwesomeIcon icon={faBullhorn} size='3x' className='mr-5' />
          <div className='w-full'>
            <div className='font-bold text-base'>
              {isInvestor
                ? 'Refer a Startup to get their accounting from OpStart'
                : 'Share OpStart with other founders to get $250 off your next month ' +
                  'and $250 off for them'}
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              // eslint-disable-next-line max-len
              className='bg-base-100 px-3 py-1.5 rounded-md border-solid border border-accent mt-2 text-sm cursor-pointer flex items-center text-gray-500 overflow-hidden'
              onClick={copyLink}
              data-testid='referral-card-link'
            >
              {link}
              <div
                className='h-5 mr-2 position: absolute right-10'
                title={copied ? 'copied' : 'copy'}
              >
                {copied ? (
                  <ClipboardDocumentIcon className='h-full text-secondary' />
                ) : (
                  <DocumentDuplicateIcon className='h-full' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  );
}
