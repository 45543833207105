// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HUBSPOT_CLIENT_ID = (import.meta.env || process.env)
  ?.VITE_HUBSPOT_CLIENT_ID;

export const openHubspotClient = (clientId: string | undefined) => {
  window.open(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `https://app.hubspot.com/contacts/${HUBSPOT_CLIENT_ID}/company/${clientId}`,
    '_blank',
    'noreferrer'
  );
};

export const openHubspotUser = (userId: string | undefined) => {
  window.open(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `https://app.hubspot.com/contacts/${HUBSPOT_CLIENT_ID}/contact/${userId}`,
    '_blank',
    'noreferrer'
  );
};

export const openHubspotDeal = (dealId: string | undefined) => {
  window.open(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `https://app.hubspot.com/contacts/${HUBSPOT_CLIENT_ID}/deal/${dealId}`,
    '_blank',
    'noreferrer'
  );
};
