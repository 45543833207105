import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
  children: React.ReactNode;
  className?: string;
  isExternal?: boolean;
}
function NavItem({ to, children, className, isExternal }: Props) {
  const baseClasses = `${
    className || ''
  } md:mb-0 md:mr-[72px] md:mt-[16px] md:pb-[16px] pl-1 pr-1 mr-5 sm:mt-2 sm:mb-2`;
  const activeClasses =
    'bg-[#00A089] md:bg-transparent md:border-b-[2px] md:border-[#00A089] md:text-[#00A089] md:pb-[14px] active';
  const inactiveClasses =
    'hover:bg-[#00A089] md:hover:bg-transparent md:hover:border-b-[2px] ' +
    'md:hover:border-[#00A089] md:hover:text-[#00A089] md:hover:pb-[14px]';
  return (
    <>
      {isExternal && (
        <a
          href={to}
          target='_blank'
          className={`${baseClasses} ${inactiveClasses}`}
          rel='noreferrer'
        >
          {children}
        </a>
      )}
      {!isExternal && (
        <NavLink
          to={to}
          className={({ isActive }) =>
            `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`
          }
        >
          {children}
        </NavLink>
      )}
    </>
  );
}

export default NavItem;
