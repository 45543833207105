import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartSimple,
  faSquarePollVertical,
  faSquarePollHorizontal,
  faChartPie,
  faChartLine,
  faChartColumn,
  faChartBar,
  faPiggyBank,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faRankingStar,
  faCreditCard,
  faCoins,
  faSackDollar,
  faHandHoldingDollar,
  faUser,
  faFileSignature,
  faAddressCard,
  faToolbox,
  faPeopleGroup,
  faFileExcel,
  faTable,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useRef, useState } from 'react';
import useClickOutside from '@/hooks/useClickOutside';

const iconList: IconDefinition[] = [
  faChartSimple,
  faSquarePollVertical,
  faSquarePollHorizontal,
  faChartPie,
  faChartLine,
  faChartColumn,
  faChartBar,
  faPiggyBank,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faRankingStar,
  faCreditCard,
  faCoins,
  faSackDollar,
  faHandHoldingDollar,
  faUser,
  faFileSignature,
  faAddressCard,
  faToolbox,
  faPeopleGroup,
  faFileExcel,
  faTable,
];

interface SelectIconProps {
  selectedIconName?: string;
  onIconSelect: (selectedIcon: string) => void;
}

function SelectIcon({ onIconSelect, selectedIconName }: SelectIconProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<string | null>(
    selectedIconName || null
  );
  const wrapperRef = useRef(null);

  const handleIconChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const icon = event.target.value;
    setSelectedIcon(icon);
    if (icon) {
      onIconSelect(icon);
    }
    setIsOpen(false);
  };

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const findIconByName = (name: string) =>
    iconList.find((icon) => icon.iconName === name);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className='relative inline-block cursor-pointer w-[58%]'>
      <div
        role='button'
        tabIndex={0}
        id='selector'
        onKeyDown={toggleDropdown}
        className='flex align-center px-2 py-1.5 border-1 rounded input-accent border w-full'
        onClick={toggleDropdown}
      >
        <span className='mr-2'>
          {selectedIcon ? (
            <>
              <FontAwesomeIcon
                icon={findIconByName(selectedIcon) as IconDefinition}
              />{' '}
              {selectedIcon}
            </>
          ) : (
            'List of icons'
          )}
        </span>
        <svg
          className={`mt-[3px] right-[6px] absolute transform ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='16'
          height='16'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <polyline points='6 9 12 15 18 9' />
        </svg>
      </div>
      {isOpen && (
        <div
          ref={wrapperRef}
          className={`absolute left-0 right-0 border-1 border-t-0 border rounded-lg
          input-accent max-h-[160px] no-scrollbar overflow-y-scroll`}
        >
          {iconList.map((icon) => (
            <div
              role='option'
              className='hover:bg-slate-800 p-2'
              key={icon.iconName}
              aria-selected={icon.iconName === selectedIcon}
              tabIndex={0}
              onKeyDown={() =>
                handleIconChange({
                  target: { value: icon.iconName },
                } as ChangeEvent<HTMLSelectElement>)
              }
              onClick={() =>
                handleIconChange({
                  target: { value: icon.iconName },
                } as ChangeEvent<HTMLSelectElement>)
              }
            >
              <FontAwesomeIcon className='p-1' icon={icon} size='lg' />{' '}
              <span className='relative top-[-5px]'>{icon.iconName}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectIcon;
