/* eslint-disable @typescript-eslint/no-use-before-define */
import { post } from '@/utils/api';
import { Auth, CUSTOM_TABS_OPTIONS } from '@/types';
import type { JWTUser, CustomTabItem, ClientConfig } from '@types';

const emptyUser = {
  user: null,
  isSuperAdmin: false,
  isAdmin: false,
  isClient: false,
  isInvestor: false,
  message: '',
  delegatedPermissionsActive: false,
  customTabs: [],
};
export const getEmptyUserWithError = (message: string) => ({
  ...emptyUser,
  message,
});

export const extractCustomTabsFromConfig = (
  clientConfig: ClientConfig[]
): CustomTabItem[] =>
  clientConfig
    .filter(
      ({ config_key, config_value }: ClientConfig) =>
        config_key === CUSTOM_TABS_OPTIONS &&
        config_value &&
        config_value !== ''
    )
    .flatMap(
      ({ config_value }: ClientConfig) =>
        JSON.parse(config_value) as CustomTabItem[]
    );

export const extractCustomTabsConfigValues = (
  userData: JWTUser
): CustomTabItem[] => {
  let testConfigValues: CustomTabItem[] = [];
  userData?.clients
    ?.filter((client) => client?.config)
    .forEach((client) => {
      testConfigValues = [
        ...testConfigValues,
        ...extractCustomTabsFromConfig(client.config as ClientConfig[]),
      ];
    });

  return testConfigValues;
};

export const logout = async () =>
  // eslint-disable-next-line no-console
  post('auth/logout', {}).catch((err) => console.log(err));

export const verifyUserRefreshData = () => {
  return localStorage.getItem('authentication_refreshed');
};

export const login = async (
  email: string,
  subject: string,
  type: string,
  redirectTo?: string,
  content?: string
): Promise<unknown> =>
  post(
    `auth/magiclogin`,
    Object.fromEntries(
      Object.entries({
        destination: email,
        emailSubject: subject,
        linkType: type,
        redirectUrl: redirectTo,
        mainContent: content,
        type: 'magicLink',
      }).filter(([, value]) => value !== null && value !== undefined)
    )
  ).then((res: Response) => res);

export const loginGrantingFilePermissions = async (
  email: string,
  subject: string,
  type: string,
  clientId: number,
  filePermissionsList: string[],
  redirectTo?: string,
  content?: string
): Promise<unknown> =>
  post(
    `auth/magicLoginGrantingFilePermissions`,
    Object.fromEntries(
      Object.entries({
        destination: email,
        emailSubject: subject,
        linkType: type,
        redirectUrl: redirectTo,
        mainContent: content,
        type: 'magicLink',
        clientId,
        filePermissionsList,
      }).filter(([, value]) => value !== null && value !== undefined)
    )
  ).then((res: Response) => res);

export const loginWithPassword = async (
  email: string,
  password: string
): Promise<unknown> =>
  post(`/auth/loginWithPassword`, {
    email,
    password,
  }).then((res: Response) => res);

export const loginWithPassword2FA = async (
  username: string,
  password: string
): Promise<unknown> =>
  post(`/auth/loginWithPassword2FA`, {
    username,
    password,
  }).then((res: Response) => res);

export function setCookie(name: string, value: string, days = 7) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; SameSite=Strict; path=/`;
}

export const sendLoginToOtherUsers = async ({
  email,
  subject,
  content,
  linkType,
  companyGroupEmail,
}: {
  email: string;
  subject?: string;
  content?: string;
  linkType?: string;
  companyGroupEmail?: string;
}) =>
  post('/auth/send', {
    destination: email,
    linkType: linkType || 'magicLink',
    emailSubject: subject,
    bodyContent: content,
    groupEmail: companyGroupEmail,
  });
export const getJwtUserData = () => {
  const accessToken: string = localStorage.getItem('access_token') as string;
  const jwtExpiresIn =
    new Date(
      parseInt(localStorage.getItem('expiresIn')?.toString() || '', 10)
    ) || new Date().getTime();
  const verifiedUser: JWTUser = JSON.parse(
    localStorage.getItem('User') || '{}'
  ) as JWTUser;

  return {
    token: accessToken,
    expiresIn: jwtExpiresIn,
    user: verifiedUser,
  };
};
export const setJwtUserData = (
  jwtUser: JWTUser,
  access_token?: string,
  jwtExpiresIn?: Date
) => {
  localStorage.setItem('User', JSON.stringify(jwtUser));
  if (access_token) localStorage.setItem('access_token', access_token);
  if (jwtExpiresIn)
    localStorage.setItem('expiresIn', jwtExpiresIn.getTime().toString());
};

export const getDefaultRoute = (authUser: Auth, isImpersonating = false) => {
  if (authUser) {
    if (authUser.isAdmin && isImpersonating) {
      return '/home';
    }
    if (authUser.isAdmin) {
      return '/clients';
    }
    if (authUser.isInvestor) {
      return '/i-home';
    }
    return '/home';
  }
  return `/login?redirectUrl=${window.location.href}`;
};
