import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { get } from '@/utils/queries';
import { Client } from '@types';
import { getLinksFromParams } from './utils';

function CustomTabUrl() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { companyName, auth }: IUserContext = useUserContext(); // Use the context
  const { documentLink, embeddedLink } = getLinksFromParams(params);

  const { data: companyData } = auth?.isClient
    ? get<Client>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const props = {
    ...(companyName && { companyName }),
    ...[companyData && { companyData }],
  };

  return (
    <PageContainer name='custom-tab'>
      <DashboardWrapper {...props}>
        <a
          href={documentLink}
          target='_blank'
          color='accent'
          className='btn btn-accent absolute top-[64px] right-[10px] btn-sm capitalize'
          rel='noreferrer'
        >
          Source Link
          <FontAwesomeIcon icon={faExternalLink} className='ml-2' />
        </a>
        <iframe
          title='Embedded content'
          role='presentation'
          src={embeddedLink}
          className='w-[100%] h-[calc(100vh-100px)]'
        />
      </DashboardWrapper>
    </PageContainer>
  );
}

export default CustomTabUrl;
