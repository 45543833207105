import { get, post } from '@/utils/queries';
import { useMemo, useState } from 'react';
import { User } from '@types';
import { Spinner } from '@/components/Spinner';
import type { FileItem } from '@types';
import useFileData from '@/hooks/useFileData';
import {
  getCheckedFileIdRecursive,
  setFilesIsChecked,
  transformFileItemtoFileItemExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { alertErrorMessage, alertMessageSuccessWithLink } from '@/utils/alerts';
import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { useRollbar } from '@rollbar/react';

interface Props {
  clientId: number;
  thirdPartyId: number;
  isOpen: boolean;
  fileItem: FileItem;
  onClose: () => void;
}

export default function AcceptPermission({
  clientId,
  thirdPartyId,
  isOpen,
  fileItem,
  onClose,
}: Props) {
  const rollbar = useRollbar();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const { getFileData, refreshFileData } = useFileData();
  const [selectedUsers, setSelectedUsers] = useState<number[]>(
    fileItem.permissions
      ?.find((t) => t.fileId === fileItem.id)
      ?.allowed_users_by_user_name?.map((u) => u.user_id) || []
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: userData, isValidating: isLoading } = get(
    `users/list/${clientId}`
  );
  const { data: rootFolder, isValidating: isLoadingFileData } =
    getFileData(clientId);

  const requestPermissionsUser = useMemo(() => {
    if (userData) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return (userData as User[]).find((u) => u.id === thirdPartyId);
    }
    return null;
  }, [thirdPartyId, userData]);

  const thirdPartyUsers = useMemo(() => {
    if (userData) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return (userData as User[])
        .filter((user) => user.role === 'investor')
        .map((u) => {
          return { user_id: u.id, user_name: `${u.first_name} ${u.last_name}` };
        });
    }
    return null;
  }, [userData]);

  const onSave = async () => {
    setIsSaving(true);
    if (
      submitAttempted ||
      (fileItem.permissions &&
        fileItem.permissions.length > 0 &&
        fileItem.permissions[0].allowedUsers.includes(thirdPartyId))
    ) {
      setSubmitAttempted(true);
      alertErrorMessage('The user already has access to this file.');
    } else {
      let error = false;
      const clientFolders = rootFolder?.files[0] as FileItem;
      try {
        for (const userId of selectedUsers) {
          if (!error) {
            const fileExt = transformFileItemtoFileItemExtended(
              clientFolders,
              userId
            );
            const result = setFilesIsChecked(fileExt, fileItem.id, true, true);
            const selectedFolderIds: string[] = [];
            getCheckedFileIdRecursive(result, selectedFolderIds);
            // eslint-disable-next-line no-await-in-loop
            await post(`permissions/files`, {
              filesIds: selectedFolderIds,
              clientId,
              userIds: [userId],
            });
          }
        }
      } catch (err) {
        error = true;
        rollbar.error(
          `AcceptPermission.tsx - onSave - Error trying to save config for file ${fileItem.id}`,
          err as Error
        );
        setIsSaving(false);
      }

      if (!error) {
        alertMessageSuccessWithLink(
          'Permission granted. To review permissions click ',
          'here',
          `${window.location.origin}/account/${clientId}/${thirdPartyId}?edit-permissions-modal=open&client-user=true`
        );
      } else {
        alertErrorMessage(
          'Something went wrong. Please try again using the link sent to your email.'
        );
      }
    }
    await refreshFileData();
    setIsSaving(false);
    onClose();
  };

  const handleShareWithAllUsers = () => {
    if (selectedUsers.length === thirdPartyUsers?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(thirdPartyUsers?.map((u) => u.user_id) || []);
    }
  };

  const addOrRemoveUser = (user: { user_id: number; user_name: string }) => {
    if (selectedUsers.includes(user.user_id)) {
      setSelectedUsers(selectedUsers.filter((u) => u !== user.user_id));
    } else {
      setSelectedUsers([...selectedUsers, user.user_id]);
    }
  };

  if (isLoading || isLoadingFileData) {
    return <Spinner />;
  }

  return (
    <GenericModal isOpen={isOpen} onClose={() => onClose()}>
      <div className='flex h-full flex-col'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>
              {`${requestPermissionsUser?.first_name as string} ${
                requestPermissionsUser?.last_name as string
              } `}
              has requested access to {fileItem.name}.
            </div>
            <br />
            <div className='font-bold text-base'>
              Edit access for {fileItem.name}
            </div>
            <br />
          </div>
        </div>
        <br />

        {thirdPartyUsers ? (
          <>
            <div className='flex items-start' key='div-main-all-users'>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                className='flex items-center cursor-pointer'
                htmlFor='enable-tab'
                key='label-tab-all-users'
                onClick={() => handleShareWithAllUsers()}
              >
                <input
                  type='checkbox'
                  key='checkbox-all-users'
                  data-testid='checkbox-all-users'
                  className='form-checkbox h-5 w-5 text-blue-600'
                  checked={selectedUsers.length === thirdPartyUsers?.length}
                  onChange={() => handleShareWithAllUsers()}
                />
                <h3
                  key='user-name-all-users'
                  className='text-base ml-2 break-all max-w-xs'
                >
                  Share with all
                </h3>
              </label>
            </div>
            <br />
            <hr className='w-1/2 self-center' />
            <br />
          </>
        ) : null}

        {thirdPartyUsers
          ? thirdPartyUsers.map((user) => (
              <div key={`div-main-${user.user_id}`}>
                <div
                  className='flex items-start'
                  key={`div-sub-${user.user_id}`}
                  id={`div-sub-${user.user_id}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    className='flex items-center cursor-pointer'
                    htmlFor='enable-tab'
                    key={`label-tab-${user.user_id}`}
                    id={`label-tab-${user.user_id}`}
                    onClick={() => addOrRemoveUser(user)}
                  >
                    <input
                      type='checkbox'
                      key={`checkbox-${user.user_id}`}
                      data-testid={`checkbox-${user.user_id}`}
                      className='form-checkbox h-5 w-5 text-blue-600'
                      checked={selectedUsers.includes(user.user_id)}
                      onChange={() => addOrRemoveUser(user)}
                    />
                    <h3
                      key={`user-name-${user.user_id}`}
                      id={`user-name-${user.user_id}`}
                      className='text-base ml-2 break-all max-w-xs'
                    >
                      {user.user_name}
                    </h3>
                  </label>
                </div>
                <br />
              </div>
            ))
          : null}
        <br />
        <div className='flex justify-center'>
          <Button
            type='submit'
            title='confirm'
            color='accent'
            size='sm'
            className='btn btn-accent'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSave}
          >
            Confirm
            {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}
