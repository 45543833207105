export enum ReportStatuses {
  new = 'new',
  partial = 'partial',
  completed = 'completed',
  overdue = 'overdue',
  closed = 'closed',
  deleted = 'deleted',
}

export enum ReportsFrequencies {
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  annual = 'Annual',
}

export enum ResponseTypes {
  newFile = 'newFile',
  existingFile = 'Existing File',
  externalFile = 'External File',
}
