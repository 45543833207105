import { QboInvoices } from '@types';

export const calculateDueDateBalances = (invoice: QboInvoices) => {
  const today = new Date();

  if (!invoice.due_date) {
    return { '1-30': 0, '31-60': 0, '61-90': 0, '90+': 0 };
  }

  const dueDate = new Date(invoice.due_date);

  if (Number.isNaN(dueDate.getTime())) {
    return { '1-30': 0, '31-60': 0, '61-90': 0, '90+': 0 };
  }

  const timeDiff = today.getTime() - dueDate.getTime();
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (dayDiff <= 30) {
    return { '1-30': invoice.balance, '31-60': 0, '61-90': 0, '90+': 0 };
  }
  if (dayDiff <= 60) {
    return { '1-30': 0, '31-60': invoice.balance, '61-90': 0, '90+': 0 };
  }
  if (dayDiff <= 90) {
    return { '1-30': 0, '31-60': 0, '61-90': invoice.balance, '90+': 0 };
  }
  return { '1-30': 0, '31-60': 0, '61-90': 0, '90+': invoice.balance };
};
