import { Link } from 'react-router-dom';
import { User } from '@types';
import { Accordion } from '@/components/Accordion/Accordion';

interface AdminUsersAccordionListProps {
  users: User[];
  size?: 'sm' | 'md';
}
export function AdminUsersAccordionList({
  users = [],
  size = 'md',
}: AdminUsersAccordionListProps) {
  const fontSize = size === 'md' ? 'text-base' : 'text-sm';

  return (
    <>
      <div className='my-4' data-testid='admin-users-list'>
        <h2 className={`text-xl ${fontSize} my-2 capitalize`}>Admins</h2>
      </div>
      {users
        .filter((user) => user.role.includes('admin'))
        .map((clientUser: User, index: number) => (
          <Accordion
            key={clientUser.email_address}
            showBottomSeparator
            open={index === 0}
            toggler={
              <Link to={`/users/${clientUser.id}/edit-user`}>
                <span className={`${fontSize} font-bold capitalize`}>
                  {clientUser.first_name} {clientUser.last_name}
                </span>
              </Link>
            }
          >
            <div className='flex'>
              <div className='flex-grow'>
                <div className='mt-2'>
                  <span className={`${fontSize} text-gray-400 mr-2`}>
                    contact_email:
                  </span>
                  <span className={`${fontSize} font-bold`}>
                    {clientUser.email_address}
                  </span>
                </div>
              </div>
            </div>
          </Accordion>
        ))}
    </>
  );
}
export default AdminUsersAccordionList;
