// generic API fetching
/* eslint-disable @typescript-eslint/no-explicit-any */

import moment, { DurationInputArg2 } from 'moment';
import { RefreshAuthResponse } from '@/types';
import { fetchWithTimeout, ObjectWithStringKeys } from './fetchWithTimeout';

const BASE_URL = '/api/';

type Options = ObjectWithStringKeys;

const defaultOptions: {
  credentials: RequestCredentials | undefined;
  headers: { [key: string]: string };
} = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};

async function fetchWithRetry<T = any>(
  url: string,
  method: string,
  options: Options,
  body: any = null,
  retry = true
): Promise<T> {
  const fetchOptions: RequestInit = {
    ...defaultOptions,
    ...options,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...defaultOptions.headers,
      ...options.headers,
      Authorization: `Bearer ${
        localStorage.getItem('access_token')?.toString() || ''
      }`,
    },
    method,
  };

  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  const response = await fetchWithTimeout(url, fetchOptions);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return handleResponse<T>(response, url, method, options, body, retry);
}

const handleResponse = async <T = any>(
  r: Response,
  url: string,
  method: string,
  options: Options,
  body: any,
  retry: boolean
) => {
  try {
    if (r.ok) {
      return (await r.json()) as T;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await r.json().catch(() => null);
    const json = payload as RefreshAuthResponse;
    const refreshAuthentication = json ? json.refreshAuthentication : undefined;

    if (r.status === 403 && refreshAuthentication && retry) {
      const { user, access_token: accessToken, maxAge } = refreshAuthentication;
      const date = moment()
        .add(maxAge[0], maxAge[1] as DurationInputArg2)
        .toDate();

      if (accessToken) localStorage.setItem('access_token', accessToken);

      localStorage.setItem('User', JSON.stringify(user));
      if (maxAge) localStorage.setItem('expiresIn', date.getTime().toString());
      localStorage.setItem('authentication_refreshed', 'true');

      return await fetchWithRetry<T>(url, method, options, body, false);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payloadMessage =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload?.message || payload?.error || payload?.error.message || undefined;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const msg = payloadMessage ?? `API Error: ${r.statusText}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(msg);
  } catch (e: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await r.json().catch(() => null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const msg = payload?.message ?? e?.message;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(msg);
  }
};

const defaultOptionsForPostFiles: {
  credentials: RequestCredentials | undefined;
  headers: { [key: string]: string };
} = {
  credentials: 'same-origin',
  headers: {},
};

export function get<T = any>(endpoint: string, options: Options = {}) {
  return fetchWithRetry<T>(`${BASE_URL}${endpoint}`, 'GET', options);
}

export function put<T = any>(
  endpoint: string,
  body: any,
  options: Options = {}
) {
  return fetchWithRetry<T>(`${BASE_URL}${endpoint}`, 'PUT', options, body);
}

export function patch<T = any>(
  endpoint: string,
  body: any,
  options: Options = {}
) {
  return fetchWithRetry<T>(`${BASE_URL}${endpoint}`, 'PATCH', options, body);
}

export function post<T = any>(
  endpoint: string,
  body: any,
  options: Options = {}
) {
  return fetchWithRetry<T>(`${BASE_URL}${endpoint}`, 'POST', options, body);
}

export function postFiles(
  endpoint: string,
  body: FormData,
  options: Options = {}
) {
  return fetchWithTimeout(`${BASE_URL}${endpoint}`, {
    method: 'POST',
    body,
    ...defaultOptionsForPostFiles,
    headers: {
      ...defaultOptionsForPostFiles.headers,
      Authorization: `Bearer ${
        localStorage.getItem('access_token')?.toString() || ''
      }`,
    },
    ...options,
  });
}

export function fetchDelete<T = any>(
  endpoint: string,
  body: any = {},
  options: Options = {}
) {
  return fetchWithRetry<T>(`${BASE_URL}${endpoint}`, 'DELETE', options, body);
}
