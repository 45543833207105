/* eslint-disable react/no-array-index-key */

import { Fragment, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from 'react-daisyui';
import { checkAllPropertiesInArrayHaveValues, prettifyKey } from '@/utils';
import { Spinner } from '@/components/Spinner';
import SelectIcon from '@/components/AccountDetails/components/SelectIcon/SelectIcon';
import { safeParseJson } from '@/components/AccountDetails/utils';
import { isValidURLOrEmpty } from '@/utils/url';
import {
  CustomTabItem,
  CustomTabItemProperties,
} from '../../../../../../shared/types/custom-tabs';
import { rejectForDuplicatedStrings } from '../../../../../../shared/utils/lists';

const CUSTOM_TABS_LIMIT = 5;
const FORM_FIELDS = [
  { label: 'Title of Tab', value: 'titleOfTab', type: 'text' },
  { label: 'Select Icon', value: 'icon', type: 'select' },
  { label: 'Embedded Link', value: 'embeddedLink', type: 'url' },
  { label: 'Document Link', value: 'documentLink', type: 'url' },
];
interface CustomTabFormProps {
  customTabsJsonString: string;
  onSave: (tabItems: CustomTabItem[]) => void;
  isLoading: boolean;
}

const newTabItem = {
  titleOfTab: '',
  icon: '',
  embeddedLink: '',
  documentLink: '',
};

function CustomTabsForm({
  onSave,
  customTabsJsonString,
  isLoading,
}: CustomTabFormProps) {
  const [tabItems, setTabItems] = useState<CustomTabItem[]>(() => {
    const incomingTabs = safeParseJson(
      customTabsJsonString,
      []
    ) as CustomTabItem[];
    return incomingTabs.length ? incomingTabs : [];
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: boolean;
  }>({});

  const handleInputChange = (index: number, name: string, value: string) => {
    const updatedTabItems: CustomTabItem[] = [...tabItems];
    updatedTabItems[index] = { ...tabItems[index], [name]: value };
    setTabItems(updatedTabItems);
    if (name === 'embeddedLink' || name === 'documentLink') {
      setValidationErrors({
        ...validationErrors,
        [name]: !isValidURLOrEmpty(value),
      });
    }
  };

  const handleSave = () => {
    const isValid = tabItems.every(
      (tab) =>
        (tab.type === 'url' &&
          isValidURLOrEmpty(tab.documentLink) &&
          isValidURLOrEmpty(tab.embeddedLink)) ||
        tab.type !== 'url'
    );
    if (isValid) onSave(tabItems);
  };

  const allTabsHaveData = useMemo(
    () =>
      tabItems.length === 0 ||
      checkAllPropertiesInArrayHaveValues(
        tabItems as unknown as { [key: string]: object }[]
      ),
    [tabItems]
  );

  const handleAddTabItem = () => {
    setTabItems([...tabItems, newTabItem]);
  };

  const removeTab = (index: number) => {
    setTabItems((oldTabItems) =>
      oldTabItems.slice(0, index).concat(oldTabItems.slice(index + 1))
    );
  };

  const dataHasChanged = useMemo(
    () =>
      JSON.stringify(tabItems) !==
      JSON.stringify(
        safeParseJson(customTabsJsonString, []) as CustomTabItem[]
      ),
    [tabItems, customTabsJsonString]
  );

  const findDuplicates = useMemo(() => {
    try {
      rejectForDuplicatedStrings(tabItems, ['titleOfTab', 'embeddedLink']);
    } catch (err) {
      return true;
    }

    return false;
  }, [tabItems]);

  return (
    <div>
      <div className='flex my-4'>
        <div className='w-1/2 flex items-center text-lg font-bold'>
          Custom Tabs
        </div>
        <div className='w-1/2 text-right'>
          <Button
            color='accent'
            size='sm'
            disabled={tabItems.length >= CUSTOM_TABS_LIMIT}
            onClick={handleAddTabItem}
          >
            <FontAwesomeIcon icon={faPlus} className='mr-1' />
            Add Tab
          </Button>
        </div>
      </div>
      <div>
        {tabItems.map((tabItem, index) => (
          <Fragment key={`remove_button_${index}_${tabItems.length}`}>
            {FORM_FIELDS.map((field, i) => (
              <div key={`${field.label.split(' ').join('_')}_${index}`}>
                <div
                  className={`my-5 break-all flex ${
                    index > 0 && i === 0 ? 'border-t border-accent pt-4' : ''
                  }`}
                >
                  <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
                    {`${prettifyKey(field.label)} ${
                      index > 0 ? index + 1 : ''
                    }`}
                  </div>
                  <div className='font-bold text-sm w-[58%] text-left pl-3'>
                    {['text', 'url'].includes(field.type) ? (
                      <>
                        <Input
                          size='sm'
                          type={field.type}
                          name={field.value}
                          className={`border-accent ${
                            field.type === 'url' &&
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            !isValidURLOrEmpty(tabItem[field.value])
                              ? 'border-red-500'
                              : ''
                          }`}
                          onChange={(e: {
                            target: { value: string; name: string };
                          }) =>
                            handleInputChange(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          defaultValue={
                            tabItem[field.value as CustomTabItemProperties]
                          }
                          data-testid={`input_${field.label}`}
                        />
                        {validationErrors[field.value] &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        !isValidURLOrEmpty(tabItem[field.value]) ? (
                          <div className='text-red-500'>Invalid URL</div>
                        ) : null}
                      </>
                    ) : null}
                    {field.type === 'select' && (
                      <SelectIcon
                        selectedIconName={tabItem.icon}
                        onIconSelect={(item) =>
                          handleInputChange(index, 'icon', item)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className='text-right'>
              <Button color='accent' size='sm' onClick={() => removeTab(index)}>
                <FontAwesomeIcon icon={faMinus} className='mr-1' />
                Remove Tab
              </Button>
            </div>
          </Fragment>
        ))}
      </div>
      <div className='text-center pt-2'>
        <Button
          color='accent'
          size='md'
          disabled={!dataHasChanged || !allTabsHaveData || findDuplicates}
          onClick={handleSave}
        >
          Save
          {isLoading && <Spinner className='mx-auto w-4 text-base ml-4' />}
        </Button>
      </div>
    </div>
  );
}

export default CustomTabsForm;
