import { DashboardCard } from '@/components/DashboardCard';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvestorReportDTO, JWTUser } from '@types';
import { useState } from 'react';
import { Input } from 'react-daisyui';
import InvestorClientList from '@/pages/InvestorReporting/components/Investors/InvestorClientList';
import { InvestorReportsListForClients } from './components/Clients/InvestorReportsListForClients';

interface Props {
  investorReports: InvestorReportDTO[];
  user?: JWTUser | null;
}

export function InvestorReportingManagement({ investorReports, user }: Props) {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(40);

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='client-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Reports'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <span className='text-white ml-10'>
            {`Total Reports: `}
            <strong>{investorReports.length}</strong>
          </span>
        </div>

        {user?.isInvestor ? (
          <InvestorClientList
            investorReports={investorReports}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
          />
        ) : null}
        {user?.isClient ? (
          <InvestorReportsListForClients
            investorReports={investorReports || []}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
          />
        ) : null}
      </div>
    </DashboardCard>
  );
}
