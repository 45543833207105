import { toast } from 'react-toastify';

type PositionTypes =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left';

export const alertMessageSuccessWithLink = (
  message: string,
  linkMessage: string,
  link: string
) => {
  function CustomToastWithLink() {
    return (
      <div>
        <span>{message}</span>
        <button
          type='button'
          onClick={() => {
            window.location.href = link;
          }}
          className='text-blue-600 font-bold'
        >
          {linkMessage}
        </button>
      </div>
    );
  }
  toast.info(CustomToastWithLink, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export const alertMessage = (position: PositionTypes, message: string) => {
  toast(message, {
    position,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export const alertMessageSuccess = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export const alertErrorMessage = (message: string) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export const alertLoadingMessage = (message: string) => {
  toast.loading(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};
