/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '@/components/Spinner';
import type { FileItem, FirmData, Folder, FirmClients } from '@types';
import { FileSearchItem } from '@/components/FileBrowser/types';
import { Auth } from '@/types';
import { UserValue } from '../share/SharePermission';
import { useGrantPermission } from './useGrantPermissionModal';

interface Props {
  auth: Auth | null | undefined;
  isOpen: boolean;
  clientId: number;
  folderOrFile: Folder | FileSearchItem | FileItem;
  onClose: () => void;
  onSave: (
    usersToAdd: UserValue[],
    usersToRemove: UserValue[],
    firmsToAdd: FirmData[],
    firmsToRemove: FirmData[]
  ) => void;
  firmClientsData?: FirmClients[];
  firmUserIds?: number[];
}

export default function GrantPermissionModal({
  auth,
  isOpen,
  clientId,
  folderOrFile,
  onClose,
  onSave,
  firmClientsData,
  firmUserIds,
}: Props) {
  const {
    selectedUsers,
    selectedFirms,
    handleShareAllFirms,
    handleShareAllUsers,
    toggleUserSelection,
    toggleFirmSelection,
    getPermissionIcon,
    saveData,
    isLoading,
    investorList,
    setSelectedUsers,
    originallyAllowedUsers,
    setRemovedUsers,
  } = useGrantPermission({
    auth,
    clientId,
    folderOrFile,
    fileUserIds: firmUserIds,
    firmClientsData,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={() => {
        setSelectedUsers(originallyAllowedUsers);
        setRemovedUsers([]);
        onClose();
      }}
    >
      <div className='flex h-full flex-col overflow-hidden'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>
              View which external parties have access to this folder
            </div>
            <br />
            <div className='text-base px-4'>
              Select users or firms to share this folder with:
            </div>
          </div>
        </div>
        <br />

        <div className='flex items-center'>
          <div className='flex' key='div-main-all-firms'>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className='flex items-center cursor-pointer'
              htmlFor='enable-tab'
              key='label-tab-all-firms'
              onClick={handleShareAllFirms}
            >
              <input
                type='checkbox'
                key='checkbox-all-users'
                className='form-checkbox h-5 w-5 text-blue-600'
                data-testid='checkbox-all-firms'
                checked={selectedFirms.length === firmClientsData?.length}
                onChange={() => handleShareAllFirms}
              />
              <h3 className='text-base ml-2 break-all max-w-xs mr-6'>
                All firms
              </h3>
            </label>
          </div>

          <div className='flex' key='div-main-all-users'>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className='flex items-center cursor-pointer'
              htmlFor='enable-tab'
              key='label-tab-all-users'
              onClick={handleShareAllUsers}
            >
              <input
                type='checkbox'
                key='checkbox-all-users'
                className='form-checkbox h-5 w-5 text-blue-600'
                data-testid='checkbox-all-users'
                checked={selectedUsers.length === investorList?.length}
                onChange={() => handleShareAllUsers}
              />
              <h3 className='text-base ml-2 break-all max-w-xs'>All users</h3>
            </label>
          </div>
        </div>
        <br />
        <hr className='w-1/2 self-center' />
        <br />

        <div className='overflow-y-auto'>
          <h3 className='mb-3 font-bold'>Firms</h3>
          {firmClientsData && firmClientsData?.length > 0 ? (
            firmClientsData?.map((firmClient) => (
              <div key={`div-main-firm-${Number(firmClient?.firm_id)}`}>
                <div
                  className='flex items-start'
                  key={`div-sub-firm-${Number(firmClient?.firm_id)}`}
                  id={`div-sub-firm-${Number(firmClient?.firm_id)}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    className='flex items-center cursor-pointer'
                    htmlFor='enable-tab'
                    key={`label-tab-firm-${Number(firmClient?.firm_id)}`}
                    id={`label-tab-firm-${Number(firmClient?.firm_id)}`}
                    onClick={() =>
                      toggleFirmSelection(Number(firmClient?.firm_id))
                    }
                  >
                    <FontAwesomeIcon
                      icon={getPermissionIcon(
                        Number(firmClient?.firm_id),
                        true
                      )}
                      size='xl'
                      className={`px-2 ${
                        getPermissionIcon(Number(firmClient?.firm_id), true) ===
                        faSquare
                          ? 'grey-icon'
                          : 'text-blue-300'
                      }`}
                      data-testid={`checkbox-firm-${Number(
                        firmClient?.firm_id
                      )}`}
                    />
                    <h3
                      key={`firm-name-${Number(firmClient?.firm_id)}`}
                      id={`firm-name-${Number(firmClient?.firm_id)}`}
                      className='text-base ml-2 break-all max-w-xs'
                    >
                      {firmClient.firm?.firm_name}
                    </h3>
                  </label>
                </div>
                <br />
              </div>
            ))
          ) : (
            <div>No Firms Available</div>
          )}

          <br />

          <h3 className='mb-3 font-bold'>Individual Users</h3>
          {investorList.length > 0 ? (
            <>
              {investorList.map((user) => (
                <div key={`div-main-user-${user.id}`}>
                  <div
                    className='flex items-start'
                    key={`div-sub-user-${user.id}`}
                    id={`div-sub-user-${user.id}`}
                  >
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className='flex items-center cursor-pointer'
                      htmlFor='enable-tab'
                      key={`label-tab-user-${user.id}`}
                      id={`label-tab-user-${user.id}`}
                      onClick={() => toggleUserSelection(user.id)}
                    >
                      <FontAwesomeIcon
                        icon={getPermissionIcon(user.id, false)}
                        size='xl'
                        className={`px-2 ${
                          getPermissionIcon(user.id, false) === faSquare
                            ? 'grey-icon'
                            : 'text-blue-300'
                        }`}
                        data-testid={`checkbox-user-${user.id}`}
                      />
                      <h3
                        key={`user-name-${user.id}`}
                        id={`user-name-${user.id}`}
                        className='text-base ml-2 break-all max-w-xs'
                      >
                        {user.label}
                      </h3>
                    </label>
                  </div>
                  <br />
                </div>
              ))}
            </>
          ) : null}
          <br />
        </div>

        <div className='flex justify-center'>
          <Button
            type='submit'
            title='Send'
            color='accent'
            size='sm'
            className='btn btn-accent'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => saveData(onSave)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}
