import { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Select } from 'react-daisyui';
import { FirmsDTO } from '@types';
import AddInvestorModal from '@/components/InvestorAddUserCard/AddInvestorModal';
import { get } from '@/utils/queries';
import { Spinner } from '@/components/Spinner';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';

// eslint-disable-next-line import/prefer-default-export
export function InvestorAddUserCard(): JSX.Element | null {
  const {
    data: firms,
    isValidating: isLoadingFirms,
    error: firmsError,
  } = get<FirmsDTO[]>('firms');
  const [selectedFirm, setSelectedFirm] = useState<FirmsDTO | null>(null);
  const [showModal, setShowModal] = useState(false);

  const { Option } = Select;

  if (isLoadingFirms) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  if (firmsError) {
    return <ErrorAlert />;
  }

  return (
    <div
      className='flex flex-col md:flex-row'
      data-testid='investor-add-user-card'
    >
      <FontAwesomeIcon
        icon={faUserPlus}
        size='3x'
        className='text-violet-600 mr-2 py-3'
      />
      <div className='text-base grow py-3 min-w-[270px]'>
        <div className='font-bold text-base'>Add a user for a firm</div>
        <div className='text-base'>
          <Select
            defaultValue='default'
            value={selectedFirm?.id}
            className='select select-bordered select-accent w-full mt-4'
            onChange={(selectedFirmId: number) => {
              setSelectedFirm(
                firms?.find(
                  (firm) => firm.id === Number(selectedFirmId)
                ) as FirmsDTO
              );
            }}
          >
            <Option value='default' disabled>
              Select a firm
            </Option>

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
            {
              firms?.map((firm) => (
                <Option key={String(firm.id)} value={String(firm.id)}>
                  {firm.firm_name}
                </Option>
              )) as unknown as ReactElement
            }
          </Select>
        </div>
        {selectedFirm?.id ? (
          <div className='mt-2'>
            <Button
              type='submit'
              title='login'
              color='accent'
              size='sm'
              className='btn btn-accent bg-transparentp-2 m-2 ml-0'
              data-testid='add-user-investor-button'
              onClick={() => setShowModal((prev) => !prev)}
            >
              Add User
            </Button>
            {showModal ? (
              <AddInvestorModal
                firm={selectedFirm}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
