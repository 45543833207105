import React, { createContext, useState, useContext, useEffect } from 'react';
import { FeatureFlag } from '@types';
import { useUserContext } from '@/context/UserContext';
import { useFetchFeatureFlags } from '@/hooks/useFetcherFeatureFlags';

interface FeatureFlagContextType {
  featureFlags: FeatureFlag[];
  loading: boolean;
  error: Error | null;
  setFilterByUser: (value: boolean) => void;
  setFilterByRole: (value: boolean) => void;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
  undefined
);

export function FeatureFlagProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { auth } = useUserContext();
  const [filterByUser, setFilterByUser] = useState<boolean>(false);
  const [filterByRole, setFilterByRole] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { featureFlags, error, loading, refetch } = useFetchFeatureFlags(
    filterByUser,
    filterByRole,
    auth?.id,
    auth?.role
  );

  useEffect(() => {
    refetch();
    const intervalId = setInterval(() => {
      refetch();
    }, 60000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = () => {
    return {
      featureFlags,
      loading,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error,
      setFilterByUser,
      setFilterByRole,
    };
  };

  return (
    <FeatureFlagContext.Provider value={value()}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export const useFeatureFlagContext = (): FeatureFlagContextType => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlagContext must be used within a FeatureFlagProvider'
    );
  }
  return context;
};
