import { useState, useCallback, useRef } from 'react';
import { debounce, isEmpty } from 'lodash';
import { fetcher } from '@/utils/queries';
import { SearchInput, SearchInputOptions } from '@/components/SearchInput';
import { QBOCategory } from '../../../../shared/types/qbo_data';

interface Props {
  value?: string | undefined;
  onOptionSelected: (category: QBOCategory | undefined) => void;
  disabled?: boolean;
  optionsCategories?: QBOCategory[];
  clientId?: number;
  qboCompanyId?: number;
}

function TransactionAutoComplete({
  value,
  onOptionSelected,
  disabled = false,
  optionsCategories = [],
  clientId,
  qboCompanyId,
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string>(value || '');
  const [searchMap, setSearchMap] = useState<SearchInputOptions[]>([]);
  const categories = useRef<QBOCategory[]>(optionsCategories);

  const autocomplateOptions: SearchInputOptions[] = categories.current.map(
    (category) => ({
      value: category.id || 0,
      label: category.name,
    })
  );

  const apiCategoriesCall = async (newQuery: string) => {
    const categoriesListAPIUrl = '/api/transactions/categories';
    if (!newQuery) {
      return [];
    }
    const res = await fetcher<QBOCategory[]>(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${categoriesListAPIUrl}/${clientId}/${qboCompanyId}/${newQuery.toLowerCase()}`
    );
    return res;
  };

  const filterCategories = async (newQuery: string) => {
    if (optionsCategories.length === 0) {
      categories.current = await apiCategoriesCall(newQuery);
    } else {
      categories.current = optionsCategories.filter((e) =>
        e.name.toString().toLowerCase().includes(newQuery.toLowerCase())
      );
    }
    const searchInputs: SearchInputOptions[] = categories.current.map(
      (category) => ({
        value: category.id || 0,
        label: category.name,
      })
    );
    setSearchMap(searchInputs);
  };

  const handleOptionSelect = (option: string | number) => {
    const selectedCategory = categories.current.find(
      (category) => category.id === option
    );
    onOptionSelected(selectedCategory);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterCategories(newQuery);
      if (isEmpty(newQuery)) handleOptionSelect(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
      if (isEmpty(newQuery)) handleOptionSelect(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  return (
    <SearchInput
      className='mb-2 ml-1'
      value={searchQuery}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onChange={handleSearchQueryChange}
      typeahead
      onOptionSelect={(option) => handleOptionSelect(option)}
      autocomplateOptions={searchMap}
      disabled={disabled}
    />
  );
}

export default TransactionAutoComplete;
export { TransactionAutoComplete };
