/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { FirmsWithUserDTO, User } from '@shared/types';
import { openHubspotUser } from '@/utils/hubspot';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { Accordion } from '@/components/Accordion/Accordion';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { useRollbar } from '@rollbar/react';
import { faTrash, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { fetchDelete } from '@/utils/api';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import useAccountData from '@/hooks/useAccountData';
import SendLoginEmailToInvestorModal from '@/components/Modal/SendLoginEmailToInvestorModal';
import EditPermissionsModal from '@/components/UsersAccordionList/EditPermissionsModalSteps/EditPermissionsModal';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { sendLoginToOtherUsers } from '@/utils';

export default function RenderFirms({
  firms = [],
  size = 'md',
  companyName,
  forInvestor,
  clientId,
  showHubspotLink,
}: {
  firms: FirmsWithUserDTO[];
  size?: 'sm' | 'md';
  companyName: string;
  forInvestor: boolean;
  clientId?: number;
  showHubspotLink?: boolean;
}) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const { refreshAccountData } = useAccountData();
  const rollbar = useRollbar();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [modalState, setModalState] = useState({
    showRemoveModal: false,
    showEmailModal: false,
    showModalEditAccess: false,
  });
  const [modalFirm, setModalFirm] = useState<FirmsWithUserDTO | undefined>(
    undefined
  );
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedFirm, setSelectedFirm] = useState<
    FirmsWithUserDTO | undefined
  >(undefined);
  const [modalClientId, setModalClientId] = useState<number | undefined>(
    undefined
  );
  const fontSize = size === 'md' ? 'text-base' : 'text-sm';
  const showDeleteButton = auth?.isAdmin || (auth?.isClient && auth.primary);

  const sendLogin = async (user: User) => {
    if (user.role === 'investor' && !forInvestor) {
      setSelectedUser(user);
      setModalState({ ...modalState, showEmailModal: true });
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error, success } = await sendLoginToOtherUsers({
      email: user.email_address,
    }).catch(() => {
      alertErrorMessage('Login failed');
    });

    if (error as Error) {
      alertErrorMessage('Login failed');
    } else if (success) {
      alertMessageSuccess('Login Sent Successfully!');
    }
  };

  const onShowModalEditAccess = (firm: FirmsWithUserDTO, show = true) => {
    if (!forInvestor && clientId) {
      if (show) {
        const clientIdValue: number | undefined = clientId
          ? Number(clientId)
          : impersonatingAccountId || auth?.clients[0].id;
        setModalClientId(clientIdValue);
        setSelectedFirm(firm);
      }
      setModalState({ ...modalState, showModalEditAccess: show });
    }
  };

  const removeFirmFromClient = async (firmId: number) => {
    if (!forInvestor && clientId) {
      setIsSaving(true);
      try {
        await fetchDelete(`firms/firmClient/${firmId}/${clientId}`);
        await refreshAccountData(clientId);
        alertMessageSuccess('Firm removed successfully');
      } catch (err) {
        rollbar.error(
          `firmsAccordionList.tsx - removeFirmFromClient - FirmId: ${firmId} - ClientId: ${clientId}`,
          err as Error
        );
      } finally {
        setIsSaving(false);
        setModalState({ ...modalState, showRemoveModal: false });
      }
    }
    alertErrorMessage('Failed to remove firm from client');
  };

  if (firms.length === 0) {
    return <div className='text-center'>No connected firms</div>;
  }

  return (
    <>
      <h2 className={`text-xl ${fontSize} mb-2 capitalize`}>Connected Firms</h2>
      {showDeleteButton && modalFirm && modalState.showRemoveModal ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() => removeFirmFromClient(modalFirm?.id as number)}
          onCloseAction={() =>
            setModalState({ ...modalState, showRemoveModal: false })
          }
          showModal={modalState.showRemoveModal}
          modalMessage={`Are you sure you want to remove access to firm:  
            ${modalFirm?.firm_name}?`}
          isLoading={isSaving}
        />
      ) : null}
      {firms.map((firm, index) => (
        <Accordion
          key={`${firm.id}_${firm?.firm_name}`}
          showBottomSeparator
          open={index === 0}
          toggler={
            <div className='flex'>
              <span className={`${fontSize} font-bold capitalize`}>
                {`${firm?.firm_name}`}
              </span>
            </div>
          }
        >
          <div className='flex'>
            <div className='flex-grow'>
              <div className='mt-2 flex justify-between'>
                <div>
                  <span className={`${fontSize} text-gray-400 mr-2`}>
                    Domain:
                  </span>
                  <span className={`${fontSize} font-bold`}>
                    <a
                      href={
                        firm.domain_name?.startsWith('http://') ||
                        firm.domain_name?.startsWith('https://')
                          ? firm.domain_name
                          : `${
                              firm.domain_name
                                ? `https://${firm.domain_name}`
                                : ''
                            }`
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {firm.domain_name}
                    </a>
                  </span>
                </div>
                <div className='flex'>
                  {showHubspotLink ? (
                    <Button
                      color='warning'
                      data-testid={`hubspot-button-${firm?.hubspot_id}`}
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        openHubspotUser(firm?.hubspot_id || undefined);
                      }}
                      startIcon={
                        <FontAwesomeIcon icon={faHubspot as IconProp} />
                      }
                    />
                  ) : null}
                  {showDeleteButton && !forInvestor ? (
                    <div className='ml-2'>
                      <Button
                        color='error'
                        data-testid={`remove-firm-client-${firm?.hubspot_id}`}
                        variant='outline'
                        size='sm'
                        onClick={() => {
                          setModalFirm(firm);
                          setModalState({
                            ...modalState,
                            showRemoveModal: true,
                          });
                        }}
                        startIcon={
                          <FontAwesomeIcon icon={faTrash as IconProp} />
                        }
                      />
                    </div>
                  ) : null}
                  {(auth?.primary || auth?.isAdmin) &&
                  !auth?.isInvestor &&
                  !forInvestor &&
                  clientId ? (
                    <div className='ml-2'>
                      <Button
                        type='submit'
                        title='permissions'
                        color='accent'
                        data-testid={`edit-access-firm-${firm?.hubspot_id}`}
                        size='sm'
                        className='btn btn-accent'
                        onClick={() => {
                          onShowModalEditAccess(firm);
                        }}
                      >
                        <FontAwesomeIcon icon={faLock} className='mr-1' />
                        Edit Access
                      </Button>
                      {!auth?.isInvestor &&
                      modalState.showModalEditAccess &&
                      selectedFirm?.id === firm?.id ? (
                        <EditPermissionsModal
                          clientId={modalClientId as number}
                          user={null}
                          firm={selectedFirm || null}
                          isOpen={modalState.showModalEditAccess}
                          onClose={() => {
                            setModalState({
                              ...modalState,
                              showModalEditAccess: false,
                            });
                            setSelectedFirm(undefined);
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='mt-2 ml-2 w-[90%]'>
                <Accordion
                  key={`accordion-user-${firm.id}_${firm.firm_name}`}
                  showBottomSeparator={false}
                  open={index === 0}
                  toggler={
                    <div className='flex mb-0'>
                      <span className={`${fontSize} font-bold capitalize`}>
                        users:
                      </span>
                    </div>
                  }
                >
                  <div key={`header-${firm.id}_${firm.firm_name}`}>
                    {firm.firm_users?.map((fu, subIndex) => {
                      return (
                        <Fragment key={`fragment_${firm.id}_${fu.user.id}`}>
                          <div
                            key={`summary_${firm.id}_${fu.user.id}`}
                            className='flex justify-between'
                          >
                            <div>
                              <div
                                className={`flex ${
                                  subIndex === 0 ? '' : 'mt-2'
                                }`}
                              >
                                <span
                                  className={`${fontSize} text-gray-400 mr-2`}
                                >
                                  Name:
                                </span>
                                <span
                                  className={`${fontSize} font-bold`}
                                  key={`name_${firm.id}_${fu.user.id}}`}
                                >
                                  {fu?.user?.first_name} {fu?.user?.last_name}
                                </span>
                                {fu?.primary && auth?.isAdmin ? (
                                  <div className='flex items-start ml-2'>
                                    <div className='badge badge-primary font-bold'>
                                      Primary
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className='mt-0'>
                                <span
                                  className={`${fontSize} text-gray-400 mr-2`}
                                >
                                  Email:
                                </span>
                                <span
                                  className={`${fontSize}`}
                                  key={`email_${firm.id}_${fu.user.id}}`}
                                >
                                  {fu?.user?.email_address}
                                </span>
                              </div>

                              {auth?.isAdmin ? (
                                <div className='mt-0'>
                                  <span
                                    className={`${fontSize} text-gray-400 mr-2`}
                                  >
                                    Job Title:
                                  </span>
                                  <span
                                    className={`${fontSize} font-bold`}
                                    key={`job_${firm.id}_${fu.user.id}}`}
                                  >
                                    {fu?.user?.job_title}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div className='flex items-center'>
                              {fu?.user?.email_address !==
                                auth?.email_address && (
                                <div className='flex justify-between w-full'>
                                  <div className='flex items-center space-x-4'>
                                    <Button
                                      type='submit'
                                      title='login'
                                      color='accent'
                                      size='sm'
                                      className='btn btn-accent'
                                      onClick={() => {
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                        sendLogin(fu?.user as User);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className='mr-1'
                                      />
                                      Send login
                                    </Button>
                                    {modalState.showEmailModal &&
                                    selectedUser ? (
                                      <SendLoginEmailToInvestorModal
                                        companyName={companyName}
                                        companyEmail={companyName}
                                        showModal={modalState.showEmailModal}
                                        email={selectedUser.email_address}
                                        onClose={() => {
                                          setModalState({
                                            ...modalState,
                                            showEmailModal: false,
                                          });
                                          setSelectedUser(undefined);
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <hr
                            className={
                              firm.firm_users?.length === subIndex + 1
                                ? 'hidden'
                                : 'my-2 mr-40 border-none bg-gray-600'
                            }
                            style={{ height: '1px' }}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </Accordion>
      ))}
    </>
  );
}
