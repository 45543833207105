import { DashboardCard } from '@/components/DashboardCard';
import formatBytes from '@/components/DragAndDrop/utils';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { get, postFiles, patch } from '@/utils/api';
import { Client, FileItem, InvestorReportCardDTO } from '@types';
import { useRef, useState } from 'react';
import { Button, Input } from 'react-daisyui';
import { isValidURLOrEmpty } from '@/utils/url';
import SelectFileComponent from '@/pages/InvestorReporting/components/Clients/SelectFileComponent';
import {
  acceptedFileExtensions,
  MAX_SINGLE_FILE_SIZE,
} from '@/utils/constants';

const answerTypes = [
  { index: 0, label: 'Upload File' },
  { index: 1, label: 'Select a File' },
  { index: 2, label: 'Use external link' },
];

export function InvestorReportCardNewStatus({
  investorReportsCard,
  client,
  setSpinner,
  onCardUpdated,
}: {
  investorReportsCard: InvestorReportCardDTO;
  client: Client;
  setSpinner: (value: boolean) => void;
  onCardUpdated: () => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileItem | undefined>(
    undefined
  );
  const [externalLink, setExternalLink] = useState<string>('');
  const [answerType, setAnswerTypes] = useState<
    { index: number; label: string } | undefined
  >(undefined);

  const openFileExplorer = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const onChangeAnswerType = (type: string) => {
    const index = answerTypes.findIndex((item) => item.label === type);
    if (index > -1) {
      setAnswerTypes(answerTypes[index]);
    }
    if (type === 'Upload File' && files.length === 0) {
      openFileExplorer();
    }
  };

  const removeFile = (idx: number) => {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles(newArr);
  };

  const checkDisabled = () => {
    if (!answerType) return true;
    switch (answerType?.label) {
      case 'Upload File':
        return !(files.length > 0);
      case 'Select a File':
        return !selectedFile;
      case 'Use external link':
        return !externalLink || !isValidURLOrEmpty(externalLink);
      default:
        return true;
    }
  };

  const handleSubmitFile = async () => {
    try {
      const formData = new FormData();
      formData.append('teamId', client.ms_team_id as string);
      formData.append('directoryName', client.ms_team_id as string);
      files.forEach((file) => {
        formData.append('', file, encodeURIComponent(file.name));
      });
      await postFiles(
        `/investorReports/fileUpload/client/${client.id}/card/${
          investorReportsCard.id as number
        }`,
        formData,
        {
          timeout: 60000,
        }
      );
      alertMessageSuccess(
        'Files Successfully Uploaded! - OpStart team review in progress.'
      );
      await get('files/syncTempFiles');
      onCardUpdated();
    } catch (_err) {
      alertErrorMessage('Error Uploading Files');
    }
  };

  const handleExternalLink = async () => {
    try {
      await patch(
        `investorReports/investorReportsCard/client/${client.id}/response/${
          investorReportsCard.id as number
        }`,
        {
          response: {
            externalUrl: externalLink,
          },
        }
      );
      onCardUpdated();
      alertMessageSuccess('Card updated successfully.');
    } catch {
      alertErrorMessage('Error saving external link, please try again later');
    }
  };

  const handleSelecteFile = async () => {
    try {
      await patch(
        `investorReports/investorReportsCard/client/${client.id}/response/${
          investorReportsCard.id as number
        }`,
        {
          response: {
            fileId: selectedFile?.id,
            teamId: selectedFile?.teamId,
            webUrl: selectedFile?.webUrl,
          },
        }
      );
      onCardUpdated();
      alertMessageSuccess('Card updated successfully.');
    } catch {
      alertErrorMessage('Error saving external link, please try again later');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files.length > 0) {
        const newFiles: File[] = Array.from(e.target.files);
        setFiles((prevState: File[]) => [...prevState, ...newFiles]);
      }
    }
  };

  const onConfirm = async () => {
    setSpinner(true);
    switch (answerType?.label) {
      case 'Upload File':
        if (files.length > 0) {
          await handleSubmitFile();
        }
        break;
      case 'Select a File':
        await handleSelecteFile();
        break;
      case 'Use external link':
        await handleExternalLink();
        break;
      default:
        break;
    }
    setFiles([]);
    setExternalLink('');
    setSelectedFile(undefined);
    setSpinner(false);
  };

  return (
    <>
      <input
        data-testid='file-drag-input'
        placeholder='fileInput'
        className='hidden'
        ref={inputRef}
        type='file'
        onChange={handleChange}
        accept={acceptedFileExtensions}
      />
      <div className='pl-4 flex'>
        {answerTypes.map((data) => {
          return (
            <span key={`${data.index + 1}_frequency_span`}>
              <Button
                size='sm'
                onClick={() => onChangeAnswerType(data.label)}
                className={`${
                  data.label === answerType?.label ? 'btn-active' : ''
                }`}
                key={`${data.index + 1}_frequency_button`}
              >
                {data.label}
              </Button>
            </span>
          );
        })}
      </div>
      <br />
      {answerType?.label === 'Upload File' ? (
        <div className='flex flex-col items-center p-3 w-[100%] px-4 pb-8'>
          {files.map((file: File, idx: number) => (
            <div
              key={`${file.name}_${file.size}`}
              className='flex flex-row space-x-5 w-[100%]'
            >
              <span className='w-3/6'>{file.name}</span>
              <span
                className={`w-1/6 ${
                  file.size > MAX_SINGLE_FILE_SIZE
                    ? 'text-red-600 text-bold'
                    : ''
                }`}
              >
                {formatBytes(file.size)}
              </span>
              <span
                className='text-red-500 cursor-pointer w-2/6'
                onClick={() => removeFile(idx)}
                onKeyDown={() => removeFile(idx)}
                role='button'
                tabIndex={-1}
              >
                remove
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {answerType?.label === 'Select a File' ? (
        <div className='px-4'>
          {!selectedFile ? (
            <DashboardCard className='p-4' cardBodyclassName='p-0'>
              <SelectFileComponent
                clientId={client.id}
                teamId={client.ms_team_id as string}
                onSelectFile={(file) => setSelectedFile(file)}
              />
            </DashboardCard>
          ) : null}

          {selectedFile ? (
            <div
              key={`${selectedFile.name}_${selectedFile.id}`}
              className='flex flex-row space-x-5 w-[100%]'
            >
              <span className='w-3/6'>{selectedFile.name}</span>
              <span
                className='text-red-500 cursor-pointer w-2/6'
                onClick={() => setSelectedFile(undefined)}
                onKeyDown={() => setSelectedFile(undefined)}
                role='button'
                tabIndex={-1}
              >
                remove
              </span>
            </div>
          ) : null}
          <br />
        </div>
      ) : null}
      {answerType?.label === 'Use external link' ? (
        <div className='px-4 pb-4'>
          <Input
            type='url'
            placeholder='Enter URL'
            className={`border-accent ${
              !isValidURLOrEmpty(externalLink) ? 'border-red-500' : ''
            }`}
            onChange={(e) => setExternalLink(e.target.value)}
          />
          {!isValidURLOrEmpty(externalLink) ? (
            <div className='text-red-500'>Invalid URL</div>
          ) : null}
        </div>
      ) : null}

      <div className='px-4'>
        <Button
          type='submit'
          color='accent'
          size='sm'
          className='btn btn-accent'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onConfirm}
          disabled={checkDisabled()}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}
