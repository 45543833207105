import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconDefinitionByName } from '@/components/Nav/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { CustomTabItemExtended } from './interfaces';

interface CustomTabsStepProps extends GenericWizardSteps {
  displayName: string;
  customTabs: CustomTabItemExtended[];
  updateCustomTabs: (customTabs: CustomTabItemExtended[]) => void;
}

export default function CustomTabsStep({
  displayName,
  customTabs,
  updateCustomTabs,
}: CustomTabsStepProps) {
  const setCustomTabIsChecked = (index: number) => {
    const updatedCustomTabsState = customTabs.map((item, itemIndex) =>
      itemIndex === index
        ? ({ ...item, isChecked: !item.isChecked } as CustomTabItemExtended)
        : item
    );
    updateCustomTabs(updatedCustomTabsState);
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='text-sm font-bold capitalize'>
          Do you want to Share Dashboard Tabs with {displayName}?
        </div>
      </div>
      {customTabs.length > 0 ? (
        customTabs.map((tab, index) => (
          <div key={`tab-${tab.titleOfTab}`}>
            <br />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className='flex items-center cursor-pointer'
              htmlFor='enable-tab'
              key={`label-tab-${tab.titleOfTab}`}
              onClick={() => setCustomTabIsChecked(index)}
            >
              <input
                key={`enable-tab-${tab.titleOfTab}`}
                id={`enable-tab-${tab.titleOfTab}`}
                type='checkbox'
                className='form-checkbox h-5 w-5 text-blue-600'
                checked={tab.isChecked}
                onChange={() => setCustomTabIsChecked(index)}
                value={tab.titleOfTab}
              />
              <FontAwesomeIcon
                icon={getIconDefinitionByName(tab.icon) as IconProp}
                size='lg'
                className='px-2'
              />
              <h3
                key={`title-tab-${tab.titleOfTab}`}
                className='text-base ml-2'
              >
                {tab.titleOfTab}
              </h3>
            </label>
          </div>
        ))
      ) : (
        <>
          <br />
          <span>No Custom Tabs to assign</span>
        </>
      )}
    </>
  );
}
