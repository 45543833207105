import { useEffect, useState } from 'react';
import { Input, Textarea } from 'react-daisyui';
import { ToastContainer } from 'react-toastify';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';

function GenericCustomEmailModal({
  showModal,
  initialSubject,
  initialContent,
  onSubmit,
  onClose,
  modalMessage = 'Edit the message we will send to your founder:',
  email,
}: {
  showModal: boolean;
  initialSubject: string;
  initialContent: string;
  onSubmit: (subject: string, content: string, email?: string) => void;
  onClose: () => void;
  modalMessage?: string;
  email?: string;
}) {
  const [showModalState, setShowModalState] = useState(showModal);
  const [subject, setSubject] = useState(initialSubject);
  const [content, setContent] = useState(initialContent);

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.target.value);
  };

  const handleSubmit = () => {
    if (email) {
      onSubmit(subject, content, email);
      return;
    }
    onSubmit(subject, content);
    setShowModalState(false);
  };

  useEffect(() => {
    setShowModalState(showModal);
  }, [showModal]);

  return (
    <GenericConfirmationModal
      onAcceptAction={handleSubmit}
      onCloseAction={() => {
        onClose();
        setShowModalState(false);
      }}
      showModal={showModalState}
      modalMessage={modalMessage}
      isFullWidth
    >
      <ToastContainer />
      <div className='font-bold text-sm w-full text-left'>
        <Input
          size='md'
          type='text'
          className='input-accent w-full'
          required
          data-testid='working-email-subject'
          placeholder='Subject Line'
          value={subject}
          onChange={handleSubjectChange}
        />
        <Textarea
          id='message-founders'
          className='w-full input-accent p-2 rounded-md mt-4 text-left'
          rows={18}
          placeholder='Content'
          value={content}
          onChange={handleContentChange}
        />
      </div>
    </GenericConfirmationModal>
  );
}

export default GenericCustomEmailModal;
