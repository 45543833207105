function QboIcon() {
  return (
    <i>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0,0,256,256'
        width='32px'
        height='32px'
      >
        <g
          fill='#00a089'
          fillRule='nonzero'
          stroke='none'
          strokeWidth='1'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          strokeDasharray=''
          strokeDashoffset='0'
          fontFamily='none'
          fontWeight='none'
          fontSize='none'
          textAnchor='none'
        >
          <g transform='scale(8,8)'>
            <path
              d={`M16,3c-7.16786,0 -13,5.83214 -13,13c0,7.16786 5.83214,13 13,13c7.16786,0 13,-5.83214 13,-13c0,
            -7.16786 -5.83214,-13 -13,-13zM16,5c6.08698,0 11,4.91302 11,11c0,6.08698 -4.91302,11 -11,11c-6.08698,0 -11,
            -4.91302 -11,-11c0,-6.08698 4.91302,-11 11,-11zM17,9v12h2h2c2.28746,0 4,-2.10382 4,-4.5c0,-2.39618 -1.71254,
            -4.5 -4,-4.5v2c1.02654,0 2,1.03018 2,2.5c0,1.46982 -0.97346,2.5 -2,2.5h-2v-10zM11,11c-2.28746,0 -4,
            2.10382 -4, 4.5c0,2.39618 1.71254,4.5 4,4.5v-2c-1.02654,0 -2,-1.03018 -2,-2.5c0,-1.46982 0.97346,-2.5 2,
            -2.5h2v10h2v-12h-1h-1z`}
            />
          </g>
        </g>
      </svg>
    </i>
  );
}

export default QboIcon;
