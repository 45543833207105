import { Badge } from 'react-daisyui';
import type { Role } from '@types';
import { Color } from '@/types';

function RoleBadge({ role }: { role: Role }) {
  const roleColor = {
    admin: 'primary',
    superadmin: 'warning',
    specialadmin: 'warning',
    client: 'accent',
    investor: 'accent',
  };

  return (
    <Badge
      size='md'
      variant='outline'
      color={(roleColor[role] as Color) ?? 'accent'}
      className='capitalize'
    >
      {role}
    </Badge>
  );
}

export default RoleBadge;
export { RoleBadge };
