import { Routes, Route } from 'react-router-dom';
import { Login } from '@/pages/Login/Login';
import { Files } from '@/pages/Files';
import { UsersList } from '@/pages/UsersList';
import MyAccount from '@/pages/MyAccount/MyAccount';
import { APISample } from '@/pages/APISample';
import { QBOAccessCallback } from '@/pages/QBOAccessCallback';
import Referral from '@/pages/Referral/Referral';
import { Logout } from '@/pages/Logout/Logout';
import Home from '@/pages/Home/Home';
import { ClientList } from '@/pages/ClientList';
import CustomTabUrl from '@/pages/CustomTabUrl/CustomTabUrl';
import MagicLoginCallback from '@/pages/Login/MagicLoginCallback';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import NavigateToDefaultPath from '@/components/ProtectedRoute/NavigateToDefaultPath';
import AccountInvestor from '@/pages/InvestorAccount/AccountInvestor';
import { InvestorReportsDetails } from '@/pages/InvestorReporting/components/InvestorReportsDetails';
import Transactions from './pages/Transactions/Transactions';
import InvestorReporting from './pages/InvestorReporting/InvestorReporting';
import '@/css/App.css';
import {
  validAdminsRoles,
  validSuperAndSpecialAdminsRoles,
} from '../../shared/constants';

export default function Router() {
  return (
    <Routes>
      <Route key='sample' path='/sample' element={<APISample />} />
      <Route
        key='QBOAuthCallback'
        path='/QBOAuthCallback'
        element={<QBOAccessCallback />}
      />
      <Route path='/verify' element={<MagicLoginCallback />} />
      <Route
        path='/addNewInvestor'
        element={<MagicLoginCallback addInvestor />}
      />
      <Route key='referral' path='/referral/*' element={<Referral />} />
      <Route key='logout' path='/logout' element={<Logout />} />
      <Route key='login' path='/login/*' element={<Login />} />
      <Route
        path='/home/*'
        element={
          <ProtectedRoute usersAllowed={['client']} allowImpersonation>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path='/i-home/*'
        element={
          <ProtectedRoute usersAllowed={['investor']}>
            <Home wipeImpersionation />
          </ProtectedRoute>
        }
      />
      <Route
        path='/i-files/*'
        element={
          <ProtectedRoute usersAllowed={['investor']}>
            <Files />
          </ProtectedRoute>
        }
      />
      <Route
        path='/i-account/*'
        element={
          <ProtectedRoute usersAllowed={['investor']}>
            <AccountInvestor />
          </ProtectedRoute>
        }
      />
      <Route
        path='/i-reporting/'
        element={
          <ProtectedRoute usersAllowed={['investor', 'client']}>
            <InvestorReporting />
          </ProtectedRoute>
        }
      />
      <Route
        path='/i-reporting-details/*'
        element={
          <ProtectedRoute usersAllowed={['investor', 'client']}>
            <InvestorReportsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/files'
        element={
          <ProtectedRoute usersAllowed={[]}>
            <Files />
          </ProtectedRoute>
        }
      />
      <Route
        path='/files/*'
        element={
          <ProtectedRoute usersAllowed={[]}>
            <Files />
          </ProtectedRoute>
        }
      />
      <Route
        path='/calendar'
        element={
          <ProtectedRoute usersAllowed={[]}>
            <Files />
          </ProtectedRoute>
        }
      />
      <Route
        path='/custom-tab-option/*'
        element={
          <ProtectedRoute usersAllowed={[]}>
            <CustomTabUrl />
          </ProtectedRoute>
        }
      />
      <Route
        path='/clients'
        element={
          <ProtectedRoute usersAllowed={validAdminsRoles}>
            <ClientList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/clients/:clientId/:tab'
        element={
          <ProtectedRoute usersAllowed={validAdminsRoles}>
            <ClientList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/categorizations'
        element={
          <ProtectedRoute
            usersAllowed={[...validAdminsRoles, 'client']}
            notAllowedUsers={['investor']}
          >
            <Transactions />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users'
        element={
          <ProtectedRoute usersAllowed={['superadmin']}>
            <UsersList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users/:userId/:tab'
        element={
          <ProtectedRoute usersAllowed={validSuperAndSpecialAdminsRoles}>
            <UsersList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/account'
        element={
          <ProtectedRoute usersAllowed={[]}>
            <MyAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path='/account/:clientId/:userId'
        element={
          <ProtectedRoute usersAllowed={[...validAdminsRoles, 'client']}>
            <MyAccount />
          </ProtectedRoute>
        }
      />
      <Route path='*' element={<NavigateToDefaultPath />} />
    </Routes>
  );
}
