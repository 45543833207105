import { FeatureFlag } from '@types';
import useSWR from 'swr';
import { fetcher } from '@/utils/queries';
import { useMemo } from 'react';

export const useFetchFeatureFlags = (
  filterByUser?: boolean,
  filterByRole?: boolean,
  userId?: number,
  userRole?: string
) => {
  const params = useMemo(() => {
    const queryParams = [];

    if (filterByUser && userId) {
      queryParams.push(`users_allowed=${userId}`);
    }

    if (filterByRole && userRole) {
      queryParams.push(`roles_allowed=${userRole}`);
    }

    return queryParams.length > 0 ? `${queryParams.join('&')}` : '';
  }, [filterByUser, filterByRole, userId, userRole]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, error, mutate } = useSWR<FeatureFlag[]>(
    `/api/featureFlags${params ? `?${params}` : ''}`,
    fetcher,
    { refreshInterval: 0 }
  );

  const refetch = () => {
    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    mutate();
  };

  return {
    featureFlags: data || [],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    error,
    loading: !data && !error,
    refetch,
  };
};
