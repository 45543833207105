/* eslint-disable react/destructuring-assignment */
import { InvestorReportDTO } from '@types';
import { Button } from 'react-daisyui';
import { Column } from '@/components/Table/Table';
import {
  formatDate,
  getStatusColor,
  InvestorReportingTableData,
} from '../../utils/investorReports';

export const columnsForClientsView: Column<InvestorReportingTableData>[] = [
  {
    header: 'investor name',
    accessor: 'investor_name',
    sortable: true,
    Cell: (row) => (
      <div role='button' tabIndex={0}>
        {row.investor_name}
      </div>
    ),
  },
  {
    header: 'Status',
    accessor: 'status',
    sortable: true,
    Cell: (row: InvestorReportingTableData) => (
      <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
    ),
  },
  { header: 'Frequency', accessor: 'frequency', sortable: true },
  {
    header: 'Last Invertor Update',
    accessor: 'last_investor_update',
    sortable: true,
  },
  {
    header: 'Date of Next Request',
    accessor: 'next_request_date',
    sortable: true,
  },
];

export const columnsForInvestorView = (
  openNudgeModal: (row: InvestorReportingTableData) => void
): Column<InvestorReportingTableData>[] => {
  return [
    {
      header: 'Company Name',
      accessor: 'company_name',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className='flex items-center'>
          <span>{row.company_name}</span>
          {row.status === 'overdue' &&
            row.investor_report_nudge &&
            row.investor_report_nudge.length === 0 && (
              <Button
                color='accent'
                size='xs'
                className='ml-3 rounded-xl'
                onClick={() => openNudgeModal(row)}
              >
                Nudge
              </Button>
            )}
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: 'status',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
      ),
    },
    {
      header: 'Firm',
      accessor: 'firm_name',
      sortable: true,
    },
    {
      header: 'Date of Next Request',
      accessor: 'next_request_date',
      sortable: true,
    },
    {
      header: 'Last Invertor Update',
      accessor: 'last_investor_update',
      sortable: true,
    },
    { header: 'Frequency', accessor: 'frequency', sortable: true },
  ];
};

export const mapApiInvestorReportsDateToTableData = (
  values: InvestorReportDTO[]
) => {
  return values.map((value) => ({
    ...value,
    id: value.id,
    company_name: value.client?.client_name || '',
    investor_name: value.firm
      ? value.firm.firm_name
      : `${value.user?.first_name as string} ${
          value.user?.last_name as string
        }`,
    status: value.status,
    frequency: value.frequency,
    last_investor_update:
      formatDate(value.created_at as unknown as string) || '',
    next_request_date: value.investor_report_template?.starting_date || 1,
    firm_name: value.firm?.firm_name || '',
  }));
};
