import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faFile,
  faCircleChevronLeft,
  faSquare,
  faSquareMinus,
  faSquareCheck,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { hasSubItems } from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { FileItemExtended } from './interfaces';
import './fileOrFolderAccordion.css';

interface Props {
  open: boolean;
  fatherIsOpen: boolean;
  showAccordionIcon: boolean;
  level: number;
  showCheckbox: boolean;
  file: FileItemExtended;
  setFilesIsChecked?(fileId: string): void;
}

export function FileOrFolderAccordion({
  open,
  fatherIsOpen,
  showAccordionIcon,
  level,
  showCheckbox,
  file,
  setFilesIsChecked,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [icon, setIcon] = useState<IconDefinition>(faSquare);
  const childFatherIsOpen = fatherIsOpen && isOpen;

  useEffect(() => {
    const countFilesRecursivly = (
      files: FileItemExtended[],
      onlyChecked: boolean
    ): number => {
      const totalFiles = files
        .map(
          (f) =>
            f.files?.filter(
              (sf: FileItemExtended) => !onlyChecked || sf.isChecked
            ).length || 0
        )
        .reduce((total, amount) => total + amount, 0);
      const descendants = files
        .map((f) => countFilesRecursivly(f.files || [], onlyChecked))
        .reduce((total, amount) => total + amount, 0);
      return totalFiles + descendants;
    };

    const isPartiallySelected = (files: FileItemExtended[]) => {
      if (files.some((f: FileItemExtended) => !f.isChecked)) {
        return true;
      }
      const total = countFilesRecursivly(files, false);
      const descendantsChecked = countFilesRecursivly(files, true);

      if (descendantsChecked === total) {
        return false;
      }
      return true;
    };

    if (!file.isChecked) {
      setIcon(faSquare);
    } else if (file.files) {
      if (isPartiallySelected(file.files)) {
        setIcon(faSquareMinus);
      } else {
        setIcon(faSquareCheck);
      }
    } else {
      setIcon(faSquareCheck);
    }
  }, [file]);

  return (
    <>
      <br />
      <div
        className='flex items-start custom-accordion'
        key={`div-main-${file.id}-${level} `}
      >
        <input
          type='checkbox'
          id={`checkbox-accordion-${file.id}-${level}`}
          className='custom-accordion-checkbox'
          checked={isOpen}
          onChange={() => {}}
        />
        <div
          key={`div-folder-${file.id}-${level}`}
          className={`accordion-sibling ${
            level === 0 ? 'pl-0' : 'pl-6'
          } w-[95%]`}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className={`flex items-center ${
              showCheckbox ? 'cursor-pointer' : ''
            } custom-accordion-label`}
            htmlFor={`checkbox-accordion-${file.id}-${level}`}
            key={`label-folder-${file.id}-${level}`}
            onClick={() => {
              if (showCheckbox && setFilesIsChecked) {
                setFilesIsChecked(file.id);
              }
            }}
          >
            {showCheckbox ? (
              <FontAwesomeIcon
                icon={icon}
                size='xl'
                className={`px-2 ${
                  icon === faSquare ? 'grey-icon' : 'text-blue-300'
                }`}
              />
            ) : null}
            <FontAwesomeIcon
              icon={file.type === 'folder' ? faFolder : faFile}
              size='lg'
              className='px-2'
            />
            <h3
              key={`folder-name-${file.id}-${level}`}
              className='text-base ml-2 break-all max-w-xs'
            >
              {file.name}
            </h3>
          </label>
          <div className='custom-accordion-content'>
            {file.files?.map((subfolder: FileItemExtended) => (
              <FileOrFolderAccordion
                open={level + 1 <= 1}
                fatherIsOpen={childFatherIsOpen}
                level={level + 1}
                showCheckbox={showCheckbox}
                file={subfolder}
                setFilesIsChecked={setFilesIsChecked}
                showAccordionIcon={hasSubItems(subfolder)}
                key={`accordion-${subfolder.id}`}
              />
            ))}
          </div>
        </div>
        <div
          className=' text-right flex absolute right-4 w-[5%]'
          key={`div-accordion-${file.id}-${level}`}
        >
          {showAccordionIcon ? (
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              onClick={() => setIsOpen(!isOpen)}
              className={`text-base cursor-pointer transform duration-100 transition ease-linear h-[24px] ${
                isOpen ? '-rotate-90' : ''
              } ${fatherIsOpen ? '' : 'hidden'}`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
