import { useNavigate } from 'react-router-dom';
import { Button, Select } from 'react-daisyui';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client } from '@types';
import { useState, useEffect, useMemo } from 'react';

function ImpersonationMessage({
  companyName,
  companyData,
}: {
  companyName?: string;
  companyData?: Client[];
}) {
  const [selectedOptionValue, setSelectedOptionValue] = useState('');
  const { setCompanyName, setimpersonatingAccountId, auth }: IUserContext =
    useUserContext();
  const navigate = useNavigate();

  const closeSession = () => {
    if (setCompanyName) {
      setCompanyName(null);
      localStorage.removeItem('companyName');
    }
    if (setimpersonatingAccountId) {
      setimpersonatingAccountId(null);
      localStorage.removeItem('impersonatingAccountId');
    }

    if (auth?.isInvestor) {
      navigate('/i-home');
      return;
    }
    navigate('/clients');
  };

  const sortClients = () => {
    if (!companyData) {
      return [];
    }

    return companyData
      .filter((client) =>
        ['closedwon', 'churned'].includes(client.deal_status as string)
      )
      .filter(
        (startup) =>
          auth?.isSuperAdmin || auth?.authorizedClientIds.includes(startup.id)
      );
  };

  const sortedClients = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => sortClients(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  useEffect(() => {
    if (sortedClients && sortedClients.length > 0 && companyName) {
      // Find the company in companyData to set the initial selectedOptionValue
      const company = sortedClients.find((c) => c.client_name === companyName);
      if (company) {
        setSelectedOptionValue(`${company.client_name}|${company.id}`);
      }
    }
  }, [companyName, sortedClients]);

  const handleCompanyChange = (value: string) => {
    setSelectedOptionValue(value);
    const [newCompanyName, companyIdStr] = value.split('|');
    const companyId = parseInt(companyIdStr, 10);
    if (setCompanyName) {
      localStorage.setItem('companyName', newCompanyName);
      setCompanyName(newCompanyName);
    }

    if (setimpersonatingAccountId) {
      localStorage.setItem('impersonatingAccountId', `${companyId}`);
      setimpersonatingAccountId(companyId);
    }

    navigate('/home');
  };

  return (
    <div className='w-full  text-center text-white p-1 bg-cyan-500 font-bold text-xl'>
      Viewing
      {sortedClients && sortedClients.length > 1 ? (
        <Select
          size='sm'
          className='select select-bordered max-w-xs m-2 bg-cyan-500 border-white'
          value={selectedOptionValue}
          onChange={handleCompanyChange}
          data-testid='client-select'
        >
          {sortedClients?.map((company: Client) => (
            <Select.Option
              key={company.id}
              value={`${company.client_name}|${company.id}`}
            >
              {company.client_name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <span> {companyName}</span>
      )}
      <Button
        onClick={closeSession}
        size='xs'
        data-testid='exit-button'
        className={`absolute right-2 btn-warning mr-4 text-base ${
          sortedClients && sortedClients.length > 1 ? 'mt-3' : 'mt-1 mb-1'
        }`}
      >
        Exit
      </Button>
    </div>
  );
}

export default ImpersonationMessage;
