import { IUserContext, useUserContext } from '@/context/UserContext';
import usePendo from '@/hooks/usePendo';
import { Auth } from '@/types';
import { getDefaultRoute } from '@/utils/auth';
import { PropsWithChildren, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const isDev = (import.meta.env || process.env)?.VITE_ENV === 'local';
interface Props extends PropsWithChildren {
  children: React.ReactNode;
  usersAllowed: string[];
  notAllowedUsers?: string[];
  allowImpersonation?: boolean;
}
function ProtectedRoute({
  usersAllowed = [],
  notAllowedUsers = [],
  children,
  allowImpersonation = false,
}: Props) {
  const pendo = usePendo();
  const {
    auth,
    impersonatingAccountId,
    refreshAuthenticationData,
    clearImpersonationSession,
  }: IUserContext = useUserContext(); // Use the context
  const role = auth?.role;
  refreshAuthenticationData();
  const allowed =
    auth &&
    (usersAllowed.length === 0 ||
      usersAllowed.includes(role as string) ||
      (allowImpersonation &&
        (auth?.isInvestor || auth?.isAdmin) &&
        impersonatingAccountId &&
        (auth?.isSuperAdmin ||
          auth?.authorizedClientIds.includes(impersonatingAccountId))));

  if (
    (auth?.isInvestor || auth?.isAdmin) &&
    impersonatingAccountId &&
    !auth?.authorizedClientIds.includes(impersonatingAccountId) &&
    !auth.isSuperAdmin
  ) {
    if (clearImpersonationSession) clearImpersonationSession();
  }

  const getAccountInformation = (
    isAdmin?: boolean,
    isInvestor?: boolean,
    clientId?: number
  ) => {
    if (isAdmin) {
      return 'Opstart';
    }
    if (isInvestor) {
      return 'investor';
    }

    return clientId;
  };

  useEffect(() => {
    const getPendoConfig = () => ({
      visitor: {
        id: auth?.id,
        email: auth?.email_address,
      },
      account: {
        id: getAccountInformation(
          auth?.isAdmin,
          auth?.isInvestor,
          auth?.clients[0]?.id
        ),
        accountType: auth?.role as string,
      },
    });

    if (!isDev && auth && pendo) {
      if (pendo.isInstalled() && !pendo.isReady()) {
        pendo.initialize(getPendoConfig());
      } else if (pendo.isInstalled() && pendo.isReady()) {
        pendo.identify(getPendoConfig());
      }
    }
  }, [pendo, auth]);

  if (!allowed || notAllowedUsers.includes(role as string)) {
    return (
      <Navigate
        to={getDefaultRoute(auth as Auth, Boolean(impersonatingAccountId))}
        replace
      />
    );
  }

  return children as JSX.Element;
}

export default ProtectedRoute;
