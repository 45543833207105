import { useEffect, useState } from 'react';
import GenericCustomEmailModal from '@/components/Modal/GenericCustomEmailModal';
import { post } from '@/utils/api';
import { useRollbar } from '@rollbar/react';
import { alertMessageSuccess, alertErrorMessage } from '@/utils/alerts';
import { JWTUser } from '@types';

function AddNonOpstartUserModal({
  showModalStatus = false,
  email,
  onClose,
  auth,
  verifiedEmail,
  companyName,
}: {
  showModalStatus: boolean;
  email: string;
  onClose: () => void;
  auth: JWTUser;
  verifiedEmail: boolean;
  companyName: string;
}) {
  const rollbar = useRollbar();
  const [showModal, setShowModal] = useState(showModalStatus);
  const newUserMessage =
    `Hey, \n` +
    `I'm using <a href='https://opstart.co'>Opstart</a> to collect financial reports from other portfolio ` +
    `companies, and I am wondering if you'd like to use it too. \n\n` +
    `OpStart is essentially an accounting firm that ` +
    `specializes in serving startups - their team handles your bookkeeping/taxes/forecasts/etc., ` +
    `and their software portal automates investor reporting. \n\n` +
    `Highly recommend you check them out. I've cc'ed Nick Nielson who can answer any questions and help with ` +
    `pricing. \n\n` +
    `Thanks, \n` +
    `${auth.first_name} ${auth.last_name}`;

  const opstartUserMessage =
    `Name: ${auth.first_name} ${auth.last_name} (${auth.email_address}) \n\n` +
    `Click approve below to choose what Files and Dashboards to share.`;

  const initialSubject = verifiedEmail
    ? `I would like access to ${companyName} on the OpStart Platform`
    : 'Opstart - Financial Ops & Reporting';

  const initialContent = verifiedEmail ? opstartUserMessage : newUserMessage;

  const handleSubmit = (subject: string, content: string) => {
    const paragraphs = content.split('\n').map((paragraph) => paragraph.trim());
    const wrappedParagraphs = paragraphs.map(
      (paragraph) => `
    <p style="color:#FFFDF6;font-size:12px;line-height:20px;width:100%;word-break:break-all;">
    ${paragraph}</p>
    `
    );
    const formattedText = wrappedParagraphs.join('');

    post('investors/authorize-access', {
      clientEmail: email,
      subjectLine: subject,
      bodyContent: formattedText,
    })
      .then(() => {
        alertMessageSuccess('An email was sent to the mail you provided!');
        setShowModal(false);
        onClose();
      })
      .catch((ex) => {
        rollbar.error(
          `Error! Email not sent! - details: ${(ex as Error)?.message}`,
          ex as Error
        );
        alertErrorMessage('Error! Email not sent!');
      });

    setShowModal(false);
  };

  useEffect(() => {
    setShowModal(showModalStatus);
  }, [showModalStatus]);
  return (
    <GenericCustomEmailModal
      showModal={showModal}
      initialSubject={initialSubject}
      initialContent={initialContent}
      onSubmit={handleSubmit}
      onClose={() => setShowModal(false)}
    />
  );
}

export default AddNonOpstartUserModal;
