import NavItem from '@/components/Nav/NavItem';
import { MenuOption } from '@/types/menu-options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  options: MenuOption[];
  isMobile: boolean;
}
function NavItemsList({ options, isMobile }: Props) {
  return (
    <>
      {options.map((option) => (
        <NavItem
          to={option.url}
          isExternal={option.isExternal}
          className={option.className ? option.className : ''}
          key={option.label}
        >
          <FontAwesomeIcon
            icon={option.icon}
            size='lg'
            className='pr-2'
            fixedWidth={isMobile}
          />
          {` ${option.label}`}
        </NavItem>
      ))}
    </>
  );
}

export default NavItemsList;
