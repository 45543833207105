import { MenuAction } from '@/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  itemToPerformActionOn: unknown;
  icon: IconProp;
  options: MenuAction[];
  testId?: string;
  id: string;
}
function TableDropDown({
  itemToPerformActionOn,
  icon,
  options,
  testId,
  id,
}: Props) {
  return (
    <details className='dropdown dropdown-end' data-testid='table_drop_down'>
      <summary
        tabIndex={0}
        role='button'
        className='btn btn-accent btn-sm'
        data-testid={testId}
      >
        <FontAwesomeIcon icon={icon} />
      </summary>
      <div
        tabIndex={0}
        role='listbox'
        className='dropdown-content menu p-2 shadow bg-base-100 rounded-box'
      >
        <ul tabIndex={0} role='menu'>
          {options.map((option) => (
            <li
              role='menuitem'
              key={`${id}_${option.label.split(' ').join('_')}`}
            >
              <span
                role='presentation'
                onClick={() => option.action(itemToPerformActionOn)}
                onKeyDown={() => option.action(itemToPerformActionOn)}
              >
                <FontAwesomeIcon icon={option.icon} />
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </details>
  );
}

export default TableDropDown;
