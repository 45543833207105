import { useState } from 'react';
import moment from 'moment';
import { Button, Input, Table } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircleChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { TransactionAutoComplete } from '@/pages/Transactions/TransactionAutoComplete';
import {
  QBOCategory,
  QBODetailedTransaction,
} from '../../../../shared/types/qbo_data';

function TableTransactionsRow({
  transaction,
  isEven,
  onConfirm,
}: {
  transaction: QBODetailedTransaction;
  isEven: boolean;
  onConfirm: (
    transaction: QBODetailedTransaction
  ) => Promise<QBODetailedTransaction | null>;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(
    !!transaction.modified_memo || !!transaction.modified_qbo_categories_id
  );
  const [modifiedTransaction, setModifiedTransaction] =
    useState<QBODetailedTransaction>(transaction);

  const onCategoryChange = (category: QBOCategory | undefined) => {
    setModifiedTransaction({
      ...modifiedTransaction,
      modified_qbo_categories_id: category?.id || undefined,
      modified_qbo_category: {
        name: category?.name || '',
      },
    });
  };

  const getModifiedCategoryName = (
    updatedTransaction: QBODetailedTransaction
  ) => updatedTransaction.modified_qbo_category?.name;

  const onChange = (newQuery: string) => {
    setModifiedTransaction({
      ...modifiedTransaction,
      modified_memo: newQuery,
    });
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <>
      <tr
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        id={`tr-${modifiedTransaction.id}`}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data-testid={`tr-${modifiedTransaction.id}`}
        onClick={() => setIsOpen(!isOpen)}
        className={`${isEven ? 'tr-even' : 'tr-odd'}`}
      >
        <td>{modifiedTransaction.payee}</td>
        <td>{modifiedTransaction.memo}</td>
        <td>
          {modifiedTransaction.date
            ? moment(new Date(modifiedTransaction.date.toString())).format(
                'MM/DD/YY'
              )
            : 'N/A'}
        </td>
        <td
          className={`font-semibold ${
            (modifiedTransaction.spent ? modifiedTransaction.spent : 0) -
              (modifiedTransaction.received
                ? modifiedTransaction.received
                : 0) >=
            0
              ? 'text-green-500'
              : 'text-red-500'
          }`}
        >
          {formatter.format(
            (modifiedTransaction.spent ? modifiedTransaction.spent : 0) -
              (modifiedTransaction.received ? modifiedTransaction.received : 0)
          )}
        </td>
        <td>{modifiedTransaction.bank}</td>
        <td>
          <div className='flex-1 text-right'>
            <FontAwesomeIcon
              icon={isUpdated ? faCheck : faCircleChevronLeft}
              onClick={() => setIsOpen(!isOpen)}
              className={`text-base cursor-pointer duration-100 transform transition ease-linear h-[24px] ${
                isOpen && !isUpdated ? '-rotate-90' : ''
              } ${isUpdated ? 'text-green-600' : ''}`}
            />
          </div>
        </td>
      </tr>

      {isOpen ? (
        <tr className={`${isEven ? 'tr-even' : 'tr-odd'}`}>
          <td colSpan={6} className='py-0 px-7 overflow-excluded'>
            <Table
              className='w-full table-inside'
              id='table-transaction-inside'
            >
              <tbody>
                <tr>
                  <td className='pb-0' colSpan={3}>
                    <div className='pb-0 overflow-hidden text-ellipsis whitespace-nowrap'>
                      <span className='text-sm capitalize text-gray-400 mr-2'>
                        Payee:
                      </span>
                      <span className='text-sm font-bold'>
                        {modifiedTransaction.payee}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='pb-0' colSpan={3}>
                    <div className='pb-0 overflow-hidden text-ellipsis whitespace-nowrap'>
                      <span className='text-sm capitalize text-gray-400 mr-2'>
                        Description:
                      </span>
                      <span className='text-sm font-bold'>
                        {modifiedTransaction.memo}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='pb-0'>
                    <span className='text-sm capitalize text-gray-400 mr-2'>
                      Bank:
                    </span>
                    <span className='text-sm font-bold'>
                      {modifiedTransaction.bank}
                    </span>
                  </td>
                  <td className='pb-0'>
                    <span className='text-sm capitalize text-gray-400 mr-2 ml-8'>
                      Amount:
                    </span>
                    <span className='text-sm font-bold'>
                      {formatter.format(
                        (modifiedTransaction.spent
                          ? modifiedTransaction.spent
                          : 0) -
                          (modifiedTransaction.received
                            ? modifiedTransaction.received
                            : 0)
                      )}
                    </span>
                  </td>
                  <td className='pb-0'>
                    <span className='text-sm capitalize text-gray-400 mr-2 ml-8'>
                      Date:
                    </span>
                    <span className='text-sm font-bold'>
                      {modifiedTransaction.date
                        ? moment(
                            new Date(modifiedTransaction.date.toString())
                          ).format('MM/DD/YY')
                        : 'N/A'}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className='td-division overflow-excluded'>
                    <div className='flex justify-around items-center'>
                      <div className='grid'>
                        <span>What is this for?</span>
                        <div className='mt-2 mb-1'>
                          <Input
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            data-testid={`new-memo-${modifiedTransaction.id}`}
                            size='sm'
                            className='border-accent w-[300px] mb-2 ml-1'
                            placeholder='Set New Memo'
                            type='search'
                            value={
                              modifiedTransaction.modified_memo
                                ? modifiedTransaction.modified_memo
                                : ''
                            }
                            onChange={(e) => onChange(e.target.value ?? '')}
                          />
                        </div>
                      </div>
                      <div className='grid'>
                        <span>&nbsp;</span>
                        <div className='mt-2 mb-1'>
                          <span>AND / OR</span>
                        </div>
                      </div>
                      <div className='grid'>
                        <span>Choose a Category (Optional)</span>
                        <TransactionAutoComplete
                          key={`row-${modifiedTransaction.txn_id}-${
                            modifiedTransaction.qbo_company_id as number
                          }`}
                          value={
                            modifiedTransaction.modified_qbo_categories_id
                              ? getModifiedCategoryName(modifiedTransaction)
                              : undefined
                          }
                          onOptionSelected={onCategoryChange}
                          clientId={transaction.client_id as number}
                          qboCompanyId={transaction.qbo_company_id as number}
                        />
                      </div>
                      <div className='grid'>
                        <Button
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          data-testid={`confirm-${modifiedTransaction.id}`}
                          className={`mt-2 btn-accent ${
                            !modifiedTransaction.modified_memo &&
                            !modifiedTransaction.modified_qbo_categories_id
                              ? 'mt-2 btn-disabled'
                              : ''
                          }`}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onClick={async () => {
                            await onConfirm(modifiedTransaction).then(
                              (updatedTransaction) => {
                                if (updatedTransaction) {
                                  setIsOpen(false);
                                  setIsUpdated(true);
                                  setModifiedTransaction(updatedTransaction);
                                }
                              }
                            );
                          }}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div />
          </td>
        </tr>
      ) : null}
    </>
  );
}

export default TableTransactionsRow;
export { TableTransactionsRow };
