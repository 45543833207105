export function BrandShadowBox({
  className = '',
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={`bg-zinc-800 w-[450px] p-4 min-h-[156px] ${className} rounded-2xl`}
    >
      {children}
    </div>
  );
}

export default BrandShadowBox;
