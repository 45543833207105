import { useRef } from 'react';
import { isEmpty } from 'lodash';
import {
  logout as authLogout,
  getJwtUserData,
  setJwtUserData,
  verifyUserRefreshData,
} from '@/utils/auth/auth';
import type { JWTUser } from '@types';

const useIsLoggedIn = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const redirectUrl = params.get('redirectUrl') || '/';
  const isVerified = useRef<boolean>(false);

  const { token, expiresIn, user: verifiedUser } = getJwtUserData();
  let user: JWTUser | null = null;
  if (!isEmpty(verifiedUser) && !isEmpty(token)) {
    if (!isVerified.current) {
      user = {
        ...verifiedUser,
      };
      isVerified.current = true;
    }
  }
  const logout = async () => {
    await authLogout();
    isVerified.current = false;
    localStorage.removeItem('access_token');
    localStorage.removeItem('User');
    localStorage.removeItem('expiresIn');
  };
  const updateDelegationStatus = (delegationStatus: boolean) => {
    user = {
      ...verifiedUser,
      delegatedPermissionsActive: delegationStatus,
    };
    setJwtUserData(user);

    localStorage.setItem('User', JSON.stringify(user));
  };

  const authenticationRefreshed = localStorage.getItem(
    'authentication_refreshed'
  );

  return {
    isVerified: isVerified.current,
    redirectUrl,
    User: user,
    token,
    expiresIn: new Date(expiresIn),
    setJwtUserData,
    getJwtUserData,
    logout,
    updateDelegationStatus,
    verifyUserAuthRefreshData: verifyUserRefreshData,
    authenticationRefreshed,
  };
};

export default useIsLoggedIn;
