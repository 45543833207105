const useStorageData = () => {
  const getSyftDashboardStorageData = (emailAddress: string) =>
    localStorage.getItem(`investor-user${emailAddress}-syft-dashboard`);

  const saveSyftDashboardStorageData = (emailAddress: string, data: object) => {
    const stringifiedData = JSON.stringify(data);
    localStorage.setItem(
      `investor-user${emailAddress}-syft-dashboard`,
      stringifiedData
    );
  };

  return {
    getSyftDashboardStorageData,
    saveSyftDashboardStorageData,
  };
};
export default useStorageData;
