import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'react-daisyui';
import { useEffect, useState } from 'react';
import { Spinner } from '@/components/Spinner';

export interface SearchInputOptions {
  value: string | number;
  label: string | number;
}

interface SearchInputProps {
  value: string;
  onChange: (query: string) => void;
  placeholder?: string;
  'data-testid'?: string;
  className?: string;
  autocomplateOptions?: SearchInputOptions[];
  noResultsMessage?: string;
  typeahead?: boolean;
  onOptionSelect?: (option: string | number) => void;
  loading?: boolean;
  maxResults?: number;
  disabled?: boolean;
}

export function SearchInput({
  value,
  onChange,
  placeholder = 'Search',
  'data-testid': testId = 'search-input',
  className = '',
  typeahead = false,
  autocomplateOptions,
  noResultsMessage = 'No results found',
  onOptionSelect,
  loading = false,
  maxResults = 10,
  disabled = false,
}: SearchInputProps) {
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [slicedAutocomplateOptions, setSlicedAutocomplateOptions] =
    useState<SearchInputOptions[]>();

  useEffect(() => {
    setSlicedAutocomplateOptions(autocomplateOptions?.slice(0, maxResults));
  }, [autocomplateOptions, maxResults]);

  const onSelectItem = (option: SearchInputOptions) => {
    onChange(String(option.label));
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onOptionSelect && onOptionSelect(option.value);
    setHasFocus(false);
  };

  const replaceStringForBold = (text: string) => {
    const re = new RegExp(`\\${value}`, 'gi');
    return text.replace(
      re,
      (match) => `<span class="font-bold">${match}</span>`
    );
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setTimeout(() => setHasFocus(false), 2000);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (slicedAutocomplateOptions && slicedAutocomplateOptions.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex === slicedAutocomplateOptions.length - 1
              ? 0
              : prevIndex + 1
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex === 0
              ? slicedAutocomplateOptions.length - 1
              : prevIndex - 1
          );
          break;
        case 'Enter':
          if (highlightedIndex !== -1) {
            e.preventDefault();
            const selectedOption = slicedAutocomplateOptions[highlightedIndex];
            onSelectItem(selectedOption);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={`relative ${className}`}>
      <div className='mt-2 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[263px] flex items-center pl-2'>
            {loading ? (
              <Spinner className='mx-auto w-4 text-base ml-4' />
            ) : (
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            )}
          </span>
          <Input
            data-testid={testId}
            size='sm'
            className='border-accent w-[300px]'
            placeholder={placeholder}
            type='search'
            value={value}
            onChange={(e) => onChange(e.target.value ?? '')}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        </div>
      </div>
      {typeahead && onOptionSelect && (
        <ul className='menu bg-base-100 absolute z-10 min-w-[300px] w-max'>
          {slicedAutocomplateOptions &&
            value &&
            hasFocus &&
            slicedAutocomplateOptions?.map((option, index) => (
              <li
                key={option.value}
                className={
                  index === highlightedIndex ? 'bg-gray-700 text-[#00A089]' : ''
                }
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                <a role='button' onClick={() => onSelectItem(option)}>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: replaceStringForBold(String(option.label)),
                    }}
                  />
                </a>
              </li>
            ))}
        </ul>
      )}
      {typeahead &&
      value &&
      autocomplateOptions?.length === 0 &&
      !disabled &&
      hasFocus ? (
        <ul className='menu bg-base-100 absolute w-full z-10'>
          <li>{noResultsMessage}</li>
        </ul>
      ) : null}
    </div>
  );
}
export default SearchInput;
