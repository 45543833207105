import { useEffect, useState } from 'react';
import { useFeatureFlagContext } from '@/context/FeatureFlagContext';

export const useFeatureFlags = (
  featureTag: string,
  filterByUser = false,
  filterByRole = false
) => {
  const { featureFlags, loading, error, setFilterByUser, setFilterByRole } =
    useFeatureFlagContext();
  const [isActive, setIsActive] = useState<boolean | null>(null);

  useEffect(() => {
    setFilterByUser(filterByUser);
    setFilterByRole(filterByRole);
  }, [filterByUser, filterByRole, setFilterByUser, setFilterByRole]);

  useEffect(() => {
    if ((!loading && !error) || !featureFlags) {
      setIsActive(false);
    }
    const featureFlag = featureFlags.find(
      (feature) => feature.name === featureTag
    );

    setIsActive(!!featureFlag);
  }, [featureFlags, loading, error, featureTag]);
  return isActive;
};
