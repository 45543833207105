import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { SyftItemExtended } from './interfaces';

interface SyftDashboardStepProps extends GenericWizardSteps {
  syftDashboardOptions: SyftItemExtended[];
  updateSyftDashboardOptions: (
    syftDashboardOptions: SyftItemExtended[]
  ) => void;
}

export default function SyftDashboardStep({
  syftDashboardOptions,
  updateSyftDashboardOptions,
}: SyftDashboardStepProps) {
  const setCustomTabIsChecked = () => {
    const updatedCustomTabs = [
      {
        ...syftDashboardOptions[0],
        isChecked: !syftDashboardOptions[0].isChecked,
      },
    ];
    updateSyftDashboardOptions(updatedCustomTabs);
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='text-sm font-bold capitalize'>
          The financial dashboard on your home page will be visible to this user
          unless you uncheck the box below
        </div>
      </div>
      {syftDashboardOptions.length > 0 ? (
        <div key={`tab-${syftDashboardOptions[0].syftUrl}`}>
          <br />
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className='flex items-center cursor-pointer'
            htmlFor='enable-tab'
            onClick={() => setCustomTabIsChecked()}
          >
            <input
              id='enable-tab-syft-dashboard'
              type='checkbox'
              data-testid='syft-dashboard'
              className='form-checkbox h-5 w-5 text-blue-600'
              checked={syftDashboardOptions[0].isChecked}
              onChange={setCustomTabIsChecked}
              value={syftDashboardOptions[0].syftUrl}
            />
            <h3 className='text-base ml-2'>Dashboard</h3>
          </label>
        </div>
      ) : (
        <>
          <br />
          <span>Syft dashboard not activated</span>
        </>
      )}
    </>
  );
}
