import { ChangeEvent, useRef, useState } from 'react';
import useClickOutside from '@/hooks/useClickOutside';
import { useClient } from '@/hooks/useClient';

const colorList: string[] = ['green', 'red', 'yellow', 'gray'];

interface Props {
  clientId: number;
  clientStatus?: number;
}

function SelectColor({ clientId, clientStatus = 0 }: Props) {
  const { upsertClient, numberToColor } = useClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>(
    numberToColor(clientStatus)
  );
  const [oldSelectedColor, setOldSelectedColor] = useState<string>(
    numberToColor(clientStatus)
  );
  const wrapperRef = useRef(null);

  const handleColorChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setOldSelectedColor(selectedColor);
    const color = event.target.value;
    setSelectedColor(color);
    setIsOpen(false);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const keepStatus = await upsertClient(clientId, color);

    if (keepStatus) {
      setSelectedColor(oldSelectedColor);
    }
  };

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className={`${isOpen ? 'relative' : ''} inline-block cursor-pointer`}>
      <div
        role='button'
        tabIndex={0}
        id='selector'
        onKeyDown={toggleDropdown}
        className='flex align-center px-2 py-1.5 rounded input-accent  w-full static'
        onClick={toggleDropdown}
        data-testid='client-select-button-status'
      >
        <span className=''>
          {selectedColor ? (
            <div
              className={`w-4 h-4 circle-${selectedColor} rounded-full`}
              data-testid='client-select-status'
            />
          ) : (
            'List of icons'
          )}
        </span>
      </div>
      {isOpen && (
        <div
          ref={wrapperRef}
          className={`absolute left-0 right-0 border-1 border-t-0 border rounded-lg
        input-accent max-h-[160px] no-scrollbar overflow-y-scroll z-20`}
        >
          {colorList.map((color) => {
            return (
              <div
                role='option'
                className='hover:bg-slate-800 p-2'
                // eslint-disable-next-line react/no-array-index-key
                key={color}
                aria-label='option color'
                aria-selected={color === selectedColor}
                tabIndex={0}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onKeyDown={() =>
                  handleColorChange({
                    target: { value: color },
                  } as ChangeEvent<HTMLSelectElement>)
                }
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() =>
                  handleColorChange({
                    target: { value: color },
                  } as ChangeEvent<HTMLSelectElement>)
                }
              >
                <div className={`w-4 h-4 rounded-full circle-${color}`} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SelectColor;
