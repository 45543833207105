const CURRENT_ENV = (import.meta.env || process.env)?.VITE_ENV as string;

const QBO_INVOICE_LINK_SANDBOX =
  'https://app.sandbox.qbo.intuit.com/app/customerdetail';
const QBO_INVOICE_LINK_PROD = 'https://app.qbo.intuit.com/app/customerdetail';
export const openQboInvoice = (invoiceId: number) => {
  const invoiceLink =
    CURRENT_ENV === 'prod'
      ? `${QBO_INVOICE_LINK_PROD}?nameId=${invoiceId}`
      : `${QBO_INVOICE_LINK_SANDBOX}?nameId=${invoiceId}`;

  window.open(invoiceLink, '_blank', 'noreferrer');
};
