/* eslint-disable import/prefer-default-export */
import { LastUpdatedAccountCard } from '@/components/LastUpdatedAccountCard';
import FilesEventCard from '@/components/FilesEventCard/FilesEventCard';

import { IUserContext, useUserContext } from '@/context/UserContext';
import { LoadingPage } from '@/pages/LoadingPage';
import SyftDashboard from '@/components/SyftDashboard/SyftDashboard';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export function HomeClient({
  clientId,
  syftUrl,
}: {
  clientId: number;
  syftUrl?: string;
}) {
  const { auth }: IUserContext = useUserContext(); // Use the context
  useEffect(() => {
    const investorAdded: {
      firstName: string;
      lastName: string;
      email: string;
    } = JSON.parse(localStorage.getItem('investorAdded') as string) as {
      firstName: string;
      lastName: string;
      email: string;
    };
    if (investorAdded)
      toast.success(
        // eslint-disable-next-line max-len
        `The investor ${investorAdded.firstName} ${investorAdded.lastName} with email ${investorAdded.email} was allowed to access to your platform`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    return () => {
      localStorage.removeItem('investorAdded');
    };
  }, []);
  if (!auth) {
    return <LoadingPage hasNav />;
  }

  return (
    <>
      {syftUrl ? <SyftDashboard syftUrl={syftUrl} /> : null}
      <div
        className={`grid p-4 ${
          auth.isInvestor || auth.isClient
            ? 'w-full'
            : 'gap-4 lg:grid-cols-2 md:grid-cols-1 '
        }`}
      >
        <div key='files'>
          {auth.isClient ? (
            <FilesEventCard clientId={clientId} />
          ) : (
            <LastUpdatedAccountCard clientId={clientId} />
          )}
        </div>

        {/* #region SC-2348 - TEMP: Turn off calendars
        TODO: This is a temporary solution, should be enabled when we decide to start using calendars
        {(auth.isSuperAdmin || auth.isAdmin) && (
          <div key='calendar'>
            <Calendar />
          </div>
        )}
        #endregion */}
      </div>
    </>
  );
}
