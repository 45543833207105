import { useMemo } from 'react';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { get } from '@/utils/queries';
import { AccountClientDetails } from '@/components/AccountClientDetails';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import useAccountData from '@/hooks/useAccountData';
import { AccountData, Client } from '../../../../shared/types';

export default function MyAccount({ test = false }: { test?: boolean }) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext(); //  Use the context
  const { getAccountData } = useAccountData();
  const {
    data: companyData,
    isValidating,
    error,
    // eslint-disable-next-line no-nested-ternary
  } = impersonatingAccountId
    ? getAccountData(impersonatingAccountId)
    : auth?.clients?.[0]?.id
    ? getAccountData(auth?.clients?.[0]?.id)
    : { data: null, isValidating: false, error: null };

  const { data: clients } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const clientDetails: AccountData = useMemo(
    () =>
      ({
        ...(companyData?.hubspot_data?.detailedData as unknown as AccountData),
        users: companyData?.users,
        firms: companyData?.firms,
      } as AccountData),
    [companyData]
  );

  const companyName = useMemo<string>(
    () => (companyData as Client)?.client_name,
    [companyData]
  );

  if (!companyData || isValidating) {
    return <LoadingPage hasNav />;
  }

  if (error) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer name='my-account'>
      <DashboardWrapper companyName={companyName} companyData={clients}>
        <AccountClientDetails clientData={clientDetails} test={test} />
      </DashboardWrapper>
    </PageContainer>
  );
}
