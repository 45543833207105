import { FileItemExtended } from '@/components/UsersAccordionList/EditPermissionsModalSteps/interfaces';
import { hasSubItems } from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import useFileData from '@/hooks/useFileData';
// eslint-disable-next-line max-len
import { InvestorReportFileOrFolderAccordion } from '@/pages/InvestorReporting/components/Clients/InvestorReportFileOrFolderAccordion';
import { FileItem } from '@types';
import { useEffect, useState } from 'react';

export default function SelectFileComponent({
  clientId,
  teamId,
  onSelectFile,
}: {
  clientId: number;
  teamId: string;
  onSelectFile: (file: FileItem) => void;
}) {
  const [files, setFiles] = useState<FileItem | undefined>(undefined);
  const { getFileData } = useFileData();
  const { data: rootFolder, isValidating: isLoading } = getFileData(clientId);

  useEffect(() => {
    if (!isLoading) {
      const clientFolders: FileItem | undefined =
        !isLoading && rootFolder
          ? rootFolder.files.find((e: FileItem) => e.id === teamId)
          : undefined;
      setFiles(clientFolders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {files?.files?.map((file) => (
        <InvestorReportFileOrFolderAccordion
          clientId={clientId}
          level={0}
          file={file}
          showAccordionIcon={hasSubItems(file as FileItemExtended)}
          key={`select-file-component-${file.id}`}
          open={false}
          fatherIsOpen
          onSelectFile={onSelectFile}
        />
      ))}
    </>
  );
}
