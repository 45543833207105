import { Spinner } from '@/components/Spinner';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  open: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[] | string;
  actionButtons?: JSX.Element;
  loading: boolean;
}

export default function SideModal({
  open,
  onClose,
  children,
  actionButtons,
  loading,
}: Props): JSX.Element {
  if (open)
    return (
      <>
        <div className='fixed top-[60px] right-0 w-[55%] h-full bg-gray-800 z-[101] animate-in slide-in-from-right'>
          <div className='flex justify-between'>
            <FontAwesomeIcon
              color='#d3d3d3'
              icon={faCircleXmark}
              size='2xl'
              onClick={onClose}
              className='p-4 cursor-pointer'
            />
            {actionButtons && !loading && (
              <div className='mt-5 justify-end mr-4'>{actionButtons}</div>
            )}
          </div>
          <div className='m-4'>
            {!loading && children}
            {loading && (
              <div className='flex justify-center'>
                <Spinner className='opacity-50 w-[70px] h-[70px] mt-20' />
              </div>
            )}
          </div>
        </div>
        <div className='fixed top-[60px] left-0 w-full h-full bg-black z-[100] opacity-[70%] animate-in fade-in-70' />
      </>
    );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
