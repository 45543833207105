import { post, get } from '@/utils/api';
import { get as getSWR } from '@/utils/queries';
import { PreviewUrlObj } from '@types';

type DownloadFile = {
  downloadUrl: string;
};

const fetchUrl = async (fileId: string, fileTeamId: string) => {
  const { fileUrl: fileURL } = await get<{ fileUrl: string }>(
    `file/${fileId}/${fileTeamId}`
  );
  return fileURL;
};

const fetchPreviewUrl = (fileId: string, fileTeamId: string) => {
  return getSWR<PreviewUrlObj>(`file/preview/${fileId}/${fileTeamId}`, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateIfStale: true,
  });
};

const processDownloadUrl = async (url: string, fileName: string) => {
  const data = await post<DownloadFile>('files/download', {
    url,
    fileName,
  });

  if (data.downloadUrl) {
    window.open(data.downloadUrl, '_blank');
  } else {
    throw new Error('Download URL not found');
  }
};

export { fetchUrl, fetchPreviewUrl, processDownloadUrl };
