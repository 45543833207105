import { useParams } from 'react-router-dom';
import * as typeform from '@typeform/embed-react';

import PageContainer from '@/components/PageContainer';

const { Widget } = typeform;

const isDev = (import.meta.env || process.env)?.VITE_ENV === 'local';

const REFERRAL_TYPEFORM_ID = 'rkBEQ2dJ';

export default function Referral({ test = false }: { test?: boolean }) {
  return (
    <PageContainer name='referral'>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <ReferralTypeFormWidget test={test} />
    </PageContainer>
  );
}

/* istanbul ignore next */
function ReferralTypeFormWidget({ test }: { test: boolean }) {
  const params = useParams();
  if (test) return null;

  const referredby = params['*'] || 'unknown';

  return (
    <Widget
      id={REFERRAL_TYPEFORM_ID}
      className='w-full h-full'
      hidden={{ referredby }}
      enableSandbox={isDev}
    />
  );
}
