import { useRollbar } from '@rollbar/react';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-daisyui';
import formatBytes from '@/components/DragAndDrop/utils';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { Spinner } from '@/components/Spinner';
import {
  acceptedFileExtensions,
  MAX_SINGLE_FILE_SIZE,
} from '@/utils/constants';

const MAX_FILES_SIZE = 1024 * 1024 * 20; // 20 mb

interface Props {
  isLoading: boolean;
  handleSubmit: (files: File[]) => Promise<void>;
  errorUploading?: boolean;
}
function DragAndDrop({ isLoading, handleSubmit, errorUploading }: Props) {
  const rollbar = useRollbar();
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files.length > 0) {
        const newFiles: File[] = Array.from(e.target.files);
        setFiles((prevState: File[]) => [...prevState, ...newFiles]);
      }
    }
  };

  const totalFilesSize = (filesToUpload: File[]) =>
    filesToUpload.reduce((accum: number, curr: File) => accum + curr.size, 0);

  const isDisabled = () =>
    isLoading ||
    !files.length ||
    totalFilesSize(files) >= MAX_FILES_SIZE ||
    files.some((file) => file.size > MAX_SINGLE_FILE_SIZE);

  const handleSubmitFile = async () => {
    if (files.length > 0) {
      await handleSubmit(files);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      setFiles((prevState: File[]) => [
        ...prevState,
        ...Array.from(e.dataTransfer.files),
      ]);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const removeFile = (fileName: string, idx: number) => {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles(newArr);
  };

  const openFileExplorer = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  useEffect(() => {
    const erroredFiles = files
      .filter((file) => file.size > MAX_SINGLE_FILE_SIZE)
      .map((file) => `${file.size} => ${formatBytes(file.size)}}`);
    if (erroredFiles.length) {
      rollbar.error(
        `Trying to upload files that are too big: ${erroredFiles.join(', ')}`
      );
    }
  }, [files, rollbar]);

  return (
    <div
      className='flex items-center justify-center w-[100%]'
      data-testid='file-uploader'
    >
      <form
        className={`${
          dragActive ? 'bg-neutral' : 'bg-neutral-focus'
        }  p-4 w-[100%] rounded-lg  min-h-[10rem] text-center flex flex-col items-center justify-center`}
        onDragEnter={handleDragEnter}
        onSubmit={(e) => e.preventDefault()}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
      >
        <input
          data-testid='file-drag-input'
          placeholder='fileInput'
          className='hidden'
          ref={inputRef}
          type='file'
          multiple
          onChange={handleChange}
          accept={acceptedFileExtensions}
        />

        <p className='pt-4'>
          Drag & Drop files or{' '}
          <span
            className='font-bold cursor-pointer underline'
            onClick={openFileExplorer}
            onKeyDown={openFileExplorer}
            role='button'
            tabIndex={-1}
          >
            Select files
          </span>{' '}
          to upload
        </p>

        <div className='flex flex-col items-center p-3 w-[100%]'>
          {files.map((file: File, idx: number) => (
            <div
              key={`${file.name}_${file.size}`}
              className='flex flex-row space-x-5 w-[100%]'
            >
              <span className='w-3/6'>{file.name}</span>
              <span
                className={`w-1/6 ${
                  file.size > MAX_SINGLE_FILE_SIZE
                    ? 'text-red-600 text-bold'
                    : ''
                }`}
              >
                {formatBytes(file.size)}
              </span>
              <span
                className='text-red-500 cursor-pointer w-2/6'
                onClick={() => removeFile(file.name, idx)}
                onKeyDown={() => removeFile(file.name, idx)}
                role='button'
                tabIndex={-1}
              >
                remove
              </span>
            </div>
          ))}
        </div>
        {(totalFilesSize(files) >= MAX_FILES_SIZE ||
          files.some((file) => file.size > MAX_SINGLE_FILE_SIZE)) && (
          <div className='text-left w-full'>
            <ErrorAlert
              message='The files are too big'
              description={`The Maximum allowed file size is ${formatBytes(
                MAX_SINGLE_FILE_SIZE
              )} or total files to upload is ${formatBytes(MAX_FILES_SIZE)}`}
            />
          </div>
        )}
        {errorUploading && (
          <div className='text-left w-full'>
            <ErrorAlert
              message='There was an error uploading the files'
              description='Please try again uploading the files'
            />
          </div>
        )}
        {files.length > 0 && (
          <Button
            className='btn-accent'
            disabled={isDisabled()}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmitFile}
          >
            <span className='p-2 text-white'>Upload</span>
            {isLoading && (
              <Spinner
                data-testid='loading-spinner'
                className='mx-auto w-4 text-base ml-4'
              />
            )}
          </Button>
        )}
      </form>
    </div>
  );
}

export default DragAndDrop;
