import type { DealData, HubspotPlan } from '@types';
import {
  SALES_DEAL_PIPELINE,
  TAXES_DEAL_PIPELINES,
} from '../../../shared/constants/hubspotConstants';

const linkValidStatus = ['closedwon', 'churned'];

const sortDealsByClosedDate = (prev: DealData, curr: DealData) => {
  if (prev.closed_date === null && curr.closed_date === null) {
    return 0;
  }
  if (prev.closed_date === null) {
    return 1;
  }
  if (curr.closed_date === null) {
    return -1;
  }
  return (
    new Date(curr.closed_date).getTime() - new Date(prev.closed_date).getTime()
  );
};

export const filteredSalesDeals = (deals: DealData[]) =>
  deals
    ?.filter(
      (deal) =>
        deal?.status &&
        linkValidStatus.includes(deal.status) &&
        deal?.pipeline === SALES_DEAL_PIPELINE
    )
    .sort(sortDealsByClosedDate);

export const filteredTaxesDeals = (deals: DealData[]) =>
  deals
    ?.filter(
      (deal) =>
        deal?.status &&
        linkValidStatus.includes(deal.status) &&
        TAXES_DEAL_PIPELINES.includes(deal?.pipeline)
    )
    .sort(sortDealsByClosedDate);

export const handleController = (plan: HubspotPlan | undefined) => {
  const deals = plan?.deals as unknown as DealData[];
  if (!deals) return '';

  const salesDeals = filteredSalesDeals(deals);

  if (!salesDeals || salesDeals.length === 0) return '';

  const closedWonDeals = salesDeals.filter(
    (deal) => deal.status === 'closedwon'
  );

  if (!closedWonDeals || closedWonDeals.length === 0) return '';

  const mostRecentClosedWonDeal = closedWonDeals.reduce((prev, curr) =>
    new Date(curr.closed_date).getTime() > new Date(prev.closed_date).getTime()
      ? curr
      : prev
  );
  return mostRecentClosedWonDeal.controller;
};

export const handleFinancialDeliveryDateDeal = (
  plan: HubspotPlan | undefined
) => {
  const deals = plan?.deals as unknown as DealData[];
  if (!deals) return '';

  const salesDeals = filteredSalesDeals(deals);

  if (!salesDeals || salesDeals.length === 0) return '';

  const closedWonDeals = salesDeals.filter(
    (deal) => deal.status === 'closedwon'
  );

  if (!closedWonDeals || closedWonDeals.length === 0) return '';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { financial_delivery_date } = closedWonDeals.reduce((prev, curr) =>
    new Date(curr.closed_date).getTime() > new Date(prev.closed_date).getTime()
      ? curr
      : prev
  );

  return financial_delivery_date ?? '-';
};
