import { InvestorReportDTO } from '@types';
import { DateTime } from 'luxon';
import { ReportStatuses } from '../../../../../shared/constants';

export const getStatusColor = (report: InvestorReportDTO) => {
  switch (report.status) {
    case ReportStatuses.new:
      return 'yellow';
    case ReportStatuses.partial:
      return 'yellow';
    case ReportStatuses.completed:
      return 'green';
    case ReportStatuses.closed:
      return 'green';
    case ReportStatuses.overdue:
      return 'red';
    case ReportStatuses.deleted:
      return 'gray';
    default:
      return 'gray';
  }
};

export const formatDate = (date?: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
  }
  return ' - ';
};

export interface InvestorListProps {
  investorReports: InvestorReportDTO[];
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (page: number) => void;
  searchText: string;
}

export interface InvestorReportingTableData extends InvestorReportDTO {
  company_name: string;
  investor_name: string;
  last_investor_update: string;
  next_request_date: number;
  firm_name: string;
}

export interface InvestorReportsDetailsData {
  investor_name: string;
  investor_reports: InvestorReportDTO[];
  selected_investor_report?: InvestorReportDTO;
}
