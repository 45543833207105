import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { AccountInvestorDetails } from '@/components/AccountInvestorDetails';

export default function AccountInvestor({ test = false }: { test?: boolean }) {
  return (
    <PageContainer name='investor-account'>
      <DashboardWrapper>
        <AccountInvestorDetails test={test} />
      </DashboardWrapper>
    </PageContainer>
  );
}
