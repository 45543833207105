export const fileOptions = [
  {
    title: 'Financial Statements (P&L and Balance Sheet)',
    description:
      'Upload Excel file of last 12 month profit and loss and balance sheet',
  },
  {
    title: 'KPI Report',
    description:
      'Upload PDF report of KPIs, summary financials, AR/AP breakdown, etc.',
  },
  {
    title: 'Cap Table',
    description:
      'Link to cap table software or upload cap table file only if cap table has changed recently; ' +
      'otherwise, mark as closed',
  },
  {
    title: 'Latest Articles of Incorporation',
    description: 'Upload most recent Articles of Incorporation',
  },
  {
    title: 'Board Reports and other Investor Updates',
    description:
      'Upload most recent board/investor meeting minutes or slide deck',
  },
  {
    title: 'Latest Financial Model, Forecast, or Budget',
    description:
      'Upload most recent financial projections in an Excel model or budget format',
  },
];
