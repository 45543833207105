import { put } from '@/utils/api';
import { alertErrorMessage } from '@/utils/alerts';

const colorToNumber = (color: string): number => {
  const colorMap: { [key: string]: number } = {
    gray: 1,
    green: 2,
    yellow: 3,
    red: 4,
  };

  return colorMap[color.toLowerCase()];
};
const numberToColor = (number: number): string => {
  const colorMap: { [key: number]: string } = {
    1: 'gray',
    2: 'green',
    3: 'yellow',
    4: 'red',
  };
  return colorMap[number] || 'gray';
};

const upsertClient = async (
  id: number,
  status: string
  // eslint-disable-next-line consistent-return
): Promise<boolean | undefined> => {
  try {
    await put('clients/updateStatus', {
      id,
      status: colorToNumber(status),
    });
  } catch (err) {
    alertErrorMessage(
      `There was an error saving the status. Try again or contact the system administrator.`
    );
    return true;
  }
};

export const useClient = () => ({
  upsertClient,
  numberToColor,
});
export default useClient;
