import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import useImpersonate from '@/hooks/useImpersonate';
import useIsLoggedIn from '@/hooks/useIsLoggedIn';

import type { JWTUser } from '@types';

export type Auth = JWTUser & { token?: string; expiresIn?: Date };
export interface IUserContext {
  auth: null | Auth | undefined;
  error?: Error;
  isLoading: boolean;
  impersonatingAccountId: number | null;
  companyName: string | null;
  redirectUrl: string | null;
  logout: () => Promise<void>;
  setimpersonatingAccountId?: (accountNumber: number | null) => void;
  setCompanyName?: (companyName: string | null) => void;
  setAuth?: (
    user: JWTUser,
    token?: string,
    expiresIn?: Date,
    userChangeAttempt?: boolean
  ) => void;
  setError?: (err: Error) => void;
  impersonate?: (companyId: number, companyName: string) => void;
  clearImpersonationSession: () => void;
  setRequestPasswordChange: () => void;
  hasRequestPasswordChange: () => boolean;
  removeRequestPasswordChange: () => void;
  refreshAuthenticationData: () => void;
}

const defaultState = {
  auth: null,
  impersonatingAccountId: null,
  setImpersonatingAccountId: () => null,
  companyName: null,
  isLoading: false,
  redirectUrl: null,
  logout: () => Promise.resolve(),
  setimpersonatingAccountId: () => null,
  setCompanyName: () => null,
  clearImpersonationSession: () => null,
  setRequestPasswordChange: () => null,
  hasRequestPasswordChange: () => false,
  removeRequestPasswordChange: () => null,
  refreshAuthenticationData: () => null,
};

export const UserContext = createContext<IUserContext>(defaultState);
export function UserProvider({ children }: { children: ReactNode }) {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [error, setError] = useState<Error>();
  const {
    User,
    token: accessToken,
    expiresIn: expires,
    redirectUrl,
    logout: userLogout,
    setJwtUserData,
    verifyUserAuthRefreshData,
    getJwtUserData,
    authenticationRefreshed,
  } = useIsLoggedIn(window.location.search);
  const setAuthWrapper = (
    user: JWTUser | null,
    token?: string,
    expiresIn?: Date,
    userChangeAttempt?: boolean
  ) => {
    if (
      user &&
      (!auth || (auth && accessToken !== token) || userChangeAttempt)
    ) {
      setAuth({ ...user, token, expiresIn });
      setJwtUserData(user, token, expiresIn);
    }
  };
  const logout = async () => {
    await userLogout();
    setAuth(null);
  };

  const updateAuthenticationData = () => {
    const result = verifyUserAuthRefreshData();
    if (result) {
      const { token, expiresIn, user: verifiedUser } = getJwtUserData();
      setAuthWrapper(verifiedUser, token, expiresIn);
      localStorage.removeItem('authentication_refreshed');
    }
  };

  if (User) setAuthWrapper(User, accessToken, expires);

  const {
    impersonatingAccountId,
    companyName,
    setimpersonatingAccountId,
    setCompanyName,
    impersonate,
    clearImpersonationSession,
  } = useImpersonate();

  const contextValue = useMemo(
    () => ({
      auth,
      error,
      isLoading: !auth && !error,
      user: auth as JWTUser,
      setAuth: setAuthWrapper,
      logout,
      impersonatingAccountId: impersonatingAccountId as number,
      companyName,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setimpersonatingAccountId,
      setCompanyName,
      setError,
      redirectUrl,
      impersonate,
      clearImpersonationSession,
      setRequestPasswordChange: () => {
        localStorage.setItem('requestPasswordChange', 'true');
      },
      hasRequestPasswordChange: () => {
        return JSON.parse(
          localStorage.getItem('requestPasswordChange') as string
        ) as boolean;
      },
      removeRequestPasswordChange: () => {
        localStorage.removeItem('requestPasswordChange');
      },
      refreshAuthenticationData: updateAuthenticationData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      auth,
      companyName,
      error,
      impersonatingAccountId,
      setCompanyName,
      setimpersonatingAccountId,
      setError,
      redirectUrl,
      impersonate,
      clearImpersonationSession,
      authenticationRefreshed,
    ]
  );
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
};
