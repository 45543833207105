/* eslint-disable @typescript-eslint/no-use-before-define */
import { DashboardCard } from '@/components/DashboardCard';
import { Input, Button, Textarea } from 'react-daisyui';
import React, { useEffect, useMemo, useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { TableSort } from '@/types';
import {
  faPenToSquare,
  faTrash,
  faFloppyDisk,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  QboInvoices,
  InvoiceColumn,
  QboConfigData,
  ClientInternalNote,
} from '@types';
import { Spinner } from '@/components/Spinner';
import { getNewSortStateTable, openQboInvoice, getNameInitials } from '@/utils';
import type { Auth } from '@/context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get as getApi } from '@/utils/api';
import { alertMessageSuccess } from '@/utils/alerts';
import InvoicesTable from './InvoicesTable';
import { formatter } from '../../utils';
import QboIcon from './QboIcon';
import { InvoiceTableData } from './invoices.util';

interface Props {
  auth: Auth | null | undefined;
  invoicesData: QboInvoices[];
  sort: TableSort<InvoiceTableData>;
  selectedPage: number;
  recordsPerPage: number;
  setSort: (sort: TableSort<InvoiceTableData>) => void;
  setSelectedPage: (page: number) => void;
  setRecordsPerPage: (records: number) => void;
  isLoading: boolean;
  clientQboConfigurationData: QboConfigData;
  addNewNotes: (notes: string) => Promise<ClientInternalNote[]>;
  updatedInternalNotes: (
    notes: ClientInternalNote
  ) => Promise<ClientInternalNote[]>;
  deleteInternalNote: (noteId: string) => Promise<void>;
}
function Invoices({
  auth,
  invoicesData = [],
  sort,
  selectedPage,
  recordsPerPage,
  setSort,
  setSelectedPage,
  setRecordsPerPage,
  isLoading,
  clientQboConfigurationData,
  addNewNotes,
  updatedInternalNotes,
  deleteInternalNote,
}: Props) {
  const [internalNotes, setInternalNotes] = useState<ClientInternalNote[]>(
    clientQboConfigurationData.internal_notes || []
  );
  const [notes, setNotes] = useState<string>('');
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
  const [updatedNote, setUpdatedNote] = useState<string>('');
  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);
  const totalBalance = invoicesData[0]?.qbo_customer?.balance || 0;
  const rollbar = useRollbar();

  const getSortValue = (sortField: string, invoice: QboInvoices) => {
    switch (sortField) {
      case 'doc_number':
        return invoice.doc_number;
      case 'qbo_customer.email_address':
        return invoice.qbo_customer?.email_address;
      case 'due_date':
        return invoice.due_date ? new Date(invoice.due_date).getTime() : 0;
      case 'balance':
        return Number(invoice.balance);
      default:
        return invoice[sortField as InvoiceColumn];
    }
  };

  const sortInvoices = (): QboInvoices[] => {
    return [...invoicesData].sort((a, b) => {
      const aValue = getSortValue(sort.field, a) ?? '';
      const bValue = getSortValue(sort.field, b) ?? '';

      if (aValue > bValue) return sort.asc ? 1 : -1;
      if (aValue < bValue) return sort.asc ? -1 : 1;
      return 0;
    });
  };

  const invoices = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => sortInvoices(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, selectedPage, invoicesData]
  );

  useEffect(() => {
    setSelectedPage(1);
  }, [sort, recordsPerPage, setSelectedPage]);

  const sortBy = (fieldName: keyof InvoiceTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await addNote();
    }
  };

  const addNote = async () => {
    if (notes.trim()) {
      const newNotes = await addNewNotes(notes);
      setInternalNotes(newNotes);
      setNotes('');
    }
  };

  const handleEditNote = (index: number) => {
    setEditingNoteIndex(index);
    setUpdatedNote(internalNotes[index].internal_note);
  };

  const handleSaveNote = async () => {
    if (updatedNote.trim() && editingNoteIndex !== null) {
      const noteToUpdate = internalNotes[editingNoteIndex];
      const updatedNoteWithId = { ...noteToUpdate, internal_note: updatedNote };
      const newNote = await updatedInternalNotes(updatedNoteWithId);
      setInternalNotes(newNote);
      setEditingNoteIndex(null);
      setUpdatedNote('');
    }
  };

  const handleDeleteNote = (noteId: string) => {
    deleteInternalNote(noteId).catch((err) =>
      rollbar.error('Invoices.tsx ~ handleDeleteNote', err as Error)
    );
    setInternalNotes(internalNotes.filter((note) => note.id !== noteId));
  };

  const refreshQBOData = async () => {
    setRefreshInProgress(true);

    try {
      alertMessageSuccess(
        'Invoices sync in progress. Please wait a few minutes and then refresh the tab.'
      );
      await getApi('qbo/syncQboInvoices');
    } finally {
      setRefreshInProgress(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DashboardCard
      className='mt-4 p-0'
      cardBodyclassName='p-0'
      data-testid='invoices'
    >
      <div className='mt-3 ml-2 mb-1 mr-5'>
        <div className='relative flex justify-between mt-2'>
          {auth?.isSuperAdmin ? (
            <div className='flex items-center'>
              <Button
                className='hover:border-[#00A089] '
                data-testid='qbo-icon-button'
                variant='link'
                size='sm'
                onClick={() =>
                  openQboInvoice(
                    Number(invoicesData[0]?.qbo_customer?.external_id)
                  )
                }
                startIcon={<QboIcon />}
              />
            </div>
          ) : null}
          <Button
            color='accent'
            className='hover:border-[#00A089] ml-1'
            size='sm'
            // variant='outline'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => refreshQBOData()}
            data-testid='refresh-invoices-button'
            startIcon={
              <FontAwesomeIcon
                icon={faRefresh}
                color='accent'
                className={`${refreshInProgress ? 'spinning' : ''}`}
              />
            }
          />
          {/* {refreshInProgress && <Spinner className='mx-2' />} */}
          <div className='relative font-bold ml-auto'>
            Total Balance: {formatter.format(Number(totalBalance))}
          </div>
        </div>
        <div className='w-full m-2'>
          <h3 className='font-bold'>Notes</h3>
          <div className='max-h-[calc(100vh-700px)] overflow-y-auto'>
            {internalNotes.length > 0 ? (
              internalNotes.map((note, index) => (
                <ul className='pt-3' key={note.id}>
                  <li className='text-xs text-gray-400 flex items-center'>
                    {editingNoteIndex === index ? (
                      <Input
                        className='w-full p-2 bg-gray-800 text-white'
                        value={updatedNote}
                        size='sm'
                        onChange={(e) => setUpdatedNote(e.target.value)}
                      />
                    ) : (
                      <span>
                        {note.internal_note} -{' '}
                        <span
                          id='invoices-tooltip'
                          className='tooltip tooltip-right'
                          data-tip={note.name}
                        >
                          {getNameInitials(note.name)}
                        </span>{' '}
                      </span>
                    )}
                    <FontAwesomeIcon
                      data-testid='save-note-button'
                      icon={
                        editingNoteIndex === index
                          ? (faFloppyDisk as IconProp)
                          : (faPenToSquare as IconProp)
                      }
                      className='ml-2 cursor-pointer accent'
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() =>
                        editingNoteIndex === index
                          ? handleSaveNote()
                          : handleEditNote(index)
                      }
                    />
                    <FontAwesomeIcon
                      data-testid='delete-note-1'
                      icon={faTrash as IconProp}
                      className='ml-2 cursor-pointer black'
                      onClick={() => handleDeleteNote(note.id)}
                    />
                  </li>
                </ul>
              ))
            ) : (
              <div> No notes available </div>
            )}
          </div>
          <Textarea
            className='w-full p-2 bg-gray-800 text-white mt-5'
            value={notes}
            rows={1}
            placeholder='Add internal notes...'
            onChange={(e) => setNotes(e.target.value)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>
      {invoicesData.length > 0 ? (
        <div className='pl-0 pr-4 mb-2'>
          <InvoicesTable
            invoices={invoices}
            sortBy={sortBy}
            sort={sort}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
          />
        </div>
      ) : (
        <div className='text-center mb-4'>No Invoices Found</div>
      )}
    </DashboardCard>
  );
}

export default Invoices;
