import PageContainer from '@/components/PageContainer';
import { Spinner } from '@/components/Spinner';
import { Nav } from '@/components/Nav';

function LoadingPage({ hasNav = false }: { hasNav?: boolean }) {
  return (
    <PageContainer name='loading'>
      <div className='h-screen flex flex-col'>
        {!!hasNav && <Nav isMenuOpen={false} />}
        <div className='flex-1 flex-col flex fade-in items-center justify-center w-full'>
          <Spinner className='w-64 text-primary opacity-20' />
        </div>
      </div>
    </PageContainer>
  );
}
export default LoadingPage;
