import { Column } from '@/components/Table/Table';
import { FirmsDTO } from '@shared/types';

export interface FirmRows extends FirmsDTO {
  // eslint-disable-next-line react/no-unused-prop-types
  clientsCount: number;
  // eslint-disable-next-line react/no-unused-prop-types
  firsmsCount: number;
}

export const mapApiFirmsToFirmRows = (values: FirmsDTO[]) => {
  return values.map((value) => {
    return {
      id: value.id,
      domain_name: value.domain_name,
      firm_name: value.firm_name,
      clientsCount: value.firm_clients?.length || 0,
      firsmsCount: value.firm_users?.length || 0,
    } as FirmRows;
  });
};

export const columns: Column<FirmRows>[] = [
  { header: 'Firm Name', accessor: 'firm_name', sortable: true },
  {
    header: 'Domain',
    accessor: 'domain_name',
    sortable: true,
    Cell: ({ domain_name }: FirmRows) => (
      <a
        href={
          domain_name?.startsWith('http://') ||
          domain_name?.startsWith('https://')
            ? domain_name
            : `${domain_name ? `https://${domain_name}` : ''}`
        }
        target='_blank'
        rel='noopener noreferrer'
      >
        {domain_name}
      </a>
    ),
  },
  { header: 'Clients Count', accessor: 'clientsCount', sortable: true },
  { header: 'Users Count', accessor: 'firsmsCount', sortable: true },
];
