import { User, TypeformFormChoice } from '@types';
import * as typeform from '@typeform/embed-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'react-daisyui';
import { ReactElement, useState } from 'react';
import { get } from '@/utils/queries';
import { DashboardCard, LoadingCard } from '@/components/DashboardCard';

const { PopupButton } = typeform;
const isDev = (import.meta.env || process.env)?.VITE_ENV === 'local';
const FORM_ID = 'WH9Yjlnt';
const FORM_PARAM_ID = 'answers-01GAPDR260GMB9132Z9C2CE39K';

const OPTIONS = [
  { value: 'default', label: 'Select an option' },
  {
    value: '01GAPDR260KR5GMW6RE4QK93KM',
    label: 'Review Monthly Finantial reviews',
  },
  { value: '01GAPDR260CXG0A09RDH9CM8RM', label: 'Taxes R+D Credit Help' },
  { value: '459c2ce6-6587-462e-b352-9ae316933e1c', label: 'Payroll Issues' },
  {
    value: '20906d99-c2d1-4fc4-8187-bb0260498d73',
    label: 'Vendor Bills / Client Invoicing',
  },
  {
    value: '0abb8249-99b3-4b13-adb9-259c19c9fd7b',
    label: 'Board Report Prep / Due Diligence Prep',
  },
  {
    value: 'bdf0707e-4d8e-4e41-bcaf-10d774f12df0',
    label: 'Physical Mailbox or New business address',
  },
  {
    value: '8b59046c-5077-43e2-a76b-c0e5b4604a76',
    label: 'Add or change 401K plan',
  },
  { value: 'other', label: 'Other' },
];

// eslint-disable-next-line import/prefer-default-export
export function ScheduleCallCard({
  clientId,
  user,
  test,
}: {
  clientId: number;
  user?: User;
  test?: boolean;
}) {
  const [option, setOption] = useState<string>(OPTIONS[0].value);
  if (!clientId) return null;
  const { Option } = Select;

  const { isLoading } = get(`account/${clientId}`) || {};
  const { data: typeformData } = get<TypeformFormChoice[]>(
    `typeform/${FORM_ID}`
  );

  if (test) return null;

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <DashboardCard data-testid='schedule-a-call'>
      {typeformData && typeformData.length > 1 && (
        <div className='flex flex-col md:flex-row items-center'>
          <FontAwesomeIcon
            icon={faRocket}
            size='3x'
            className='text-violet-600 mr-2 py-3'
          />
          <div className='text-base grow py-3 min-w-[270px]'>
            <div className='font-bold text-base'>
              Schedule a call with our team
            </div>
            <div className='text-base'>
              <Select
                size='sm'
                defaultValue='default'
                className='select select-bordered select-accent w-11/12 max-w-xs mt-4'
                onChange={(selectedOption: string) => setOption(selectedOption)}
              >
                <Option value='default' disabled>
                  Select an option
                </Option>

                {
                  typeformData.map((op) => (
                    <Option key={op.ref} value={op.ref}>
                      {op.label}
                    </Option>
                  )) as unknown as ReactElement
                }
              </Select>
            </div>
          </div>
          <div className='text-right'>
            <PopupButton
              id={FORM_ID}
              enableSandbox={isDev}
              hidden={{
                first_name: user?.first_name as string,
                last_name: user?.last_name as string,
                email_address: user?.email_address as string,
                [FORM_PARAM_ID]: option,
              }}
              transitiveSearchParams={['']}
              className={`btn btn-accent ${
                option === 'default' ? 'btn-disabled' : ''
              }`}
            >
              GET STARTED
            </PopupButton>
          </div>
        </div>
      )}
    </DashboardCard>
  );
}
