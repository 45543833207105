import { QboInvoices } from '@types';
import { TableSort } from '@/types';
import { GenericTable } from '@/components/Table/Table';
import {
  columns,
  mapApiInvoicesToTableData,
  InvoiceTableData,
} from './invoices.util';

function InvoicesTable({
  invoices,
  sortBy,
  sort,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
}: {
  invoices: QboInvoices[];
  sortBy: (field: keyof InvoiceTableData) => void;
  sort: TableSort<InvoiceTableData>;
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (page: number) => void;
}) {
  const invoicesData = mapApiInvoicesToTableData(invoices);

  return (
    <GenericTable
      className='w-full m-2'
      tableName='Invoices'
      data={invoicesData}
      columns={columns}
      sortBy={sortBy}
      sort={sort}
      recordsPerPage={recordsPerPage}
      selectedPage={selectedPage}
      onPageSelect={onPageSelect}
      onSelectRowsChange={onSelectRowsChange}
    />
  );
}

export default InvoicesTable;
