import { useEffect } from 'react';
import type { User } from '@types';
import { TableMenuActions, TableSort } from '@/types';
import './TableUsers.css';
import { GenericTable } from '@/components/Table/Table';
import { columns, handleRowClick } from './tableUsers.util';

interface TableUsersProps {
  sortedUsers: User[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  userActions: TableMenuActions;
  sort: TableSort<User>;
  sortBy: (fieldName: keyof User) => void;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (recordsPerPage: number) => void;
  searchText: string;
}
function TableUsers({
  sortedUsers = [],
  userActions,
  sort,
  sortBy,
  selectedPage,
  setSelectedPage,
  recordsPerPage,
  setRecordsPerPage,
  searchText,
}: TableUsersProps) {
  useEffect(() => {
    setSelectedPage(1);
  }, [sort, setSelectedPage, recordsPerPage, searchText]);

  return (
    <GenericTable
      className=''
      tableName='users'
      data={sortedUsers}
      columns={columns(userActions)}
      sortBy={sortBy}
      sort={sort}
      recordsPerPage={recordsPerPage}
      selectedPage={selectedPage}
      onPageSelect={setSelectedPage}
      onSelectRowsChange={setRecordsPerPage}
      onRowClick={(row) => handleRowClick(row, userActions)}
    />
  );
}

export default TableUsers;
export { TableUsers };
