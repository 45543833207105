import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { Client, ClientUser } from '../../../shared/types';

// generate a custom hook for querying /api/investors/getStartups by investorid
const useInvestor = () => {
  const useStartups = () =>
    useSWR<Client[], Error>(`/api/investors/getStartups`, fetcher);

  const useGetInvestorUsers = (investorId: number) =>
    useSWR<ClientUser[], Error>(
      `/api/users/investor-users/${investorId}`,
      fetcher
    );

  return {
    useStartups,
    useGetInvestorUsers,
  };
};
export default useInvestor;
