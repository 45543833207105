import { SyftProperties } from '@types';

export const getSyftURLToShow = (
  parseSyftUrlOptions: SyftProperties[],
  userFirmsIds: number[],
  userId: number
) => {
  const syftUrlToShow = parseSyftUrlOptions.find((option) => {
    const tabAllowedFirms = option.allowedFirms.map((firm) => firm.firmId);
    const skippedUsers = option.allowedFirms.flatMap((firm) =>
      firm.skippedUsers.map((skipperUserId) => skipperUserId)
    );
    return (
      (option.allowedUsers && option.allowedUsers.includes(userId)) ||
      (option.allowedFirms &&
        userFirmsIds.some((firm) => tabAllowedFirms.includes(firm)) &&
        !skippedUsers.includes(userId))
    );
  })?.syftUrl;

  return syftUrlToShow;
};
