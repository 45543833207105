import { alertErrorMessage } from '@/utils/alerts';
import { effect, signal } from '@preact/signals-react';

const errorSignal = signal<string | null>(null);

export const setErrorSignal = (message: string | null) => {
  errorSignal.value = message;
};

effect(() => {
  if (errorSignal.value) {
    const message = errorSignal.value;
    setTimeout(() => {
      alertErrorMessage(message);

      // To avoid re-rendering the error message, we wait to clear it
      setTimeout(() => {
        setErrorSignal(null);
      }, 2000);
    }, 100);
  }
});
