import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { Spinner } from '@/components/Spinner';
import { alertErrorMessage } from '@/utils/alerts';
import { post } from '@/utils/api';
import { fetchUrl, processDownloadUrl } from '../../utils/teamsFileDownload';

function DownloadLastUpdatedFile({
  fileType,
  fileName,
  fileId,
  fileTeamId,
}: {
  fileType: 'excel' | 'pdf';
  fileName: string;
  fileId: string;
  fileTeamId: string;
}) {
  const [fetching, setFetching] = useState(false);
  const rollbar = useRollbar();
  const isFileEmpty = isEmpty(fileName);

  const obtainUrl = async () => {
    try {
      return await fetchUrl(fileId, fileTeamId);
    } catch (err) {
      alertErrorMessage(
        'This file has been updated. Please wait a few minutes and refresh your page to access the latest version.'
      );
      await post('files/updateTeams', { async: true });
      throw err;
    }
  };

  const handleClick = () => {
    setFetching(true);

    obtainUrl()
      .then(async (url) => processDownloadUrl(url, fileName))
      .catch((err) => rollbar.log(err as Error))
      .finally(() => setFetching(false));
  };

  return (
    <div
      className={`flex flex-col items-center ${isFileEmpty ? 'tooltip' : ''}`}
      data-tip={`${isFileEmpty ? 'File not yet available' : ''}`}
      data-testid='download-last-file-tooltip'
    >
      <button
        className={`btn mt-2 mb-2 h-[90%] w-48 btn-outline mx-3 ${
          isFileEmpty || fetching ? 'btn-disabled' : ''
        }`}
        type='button'
        onClick={handleClick}
      >
        {fetching ? (
          <Spinner />
        ) : (
          <div className='contents'>
            <FontAwesomeIcon
              icon={fileType === 'excel' ? faFileExcel : faFilePdf}
              size='3x'
              className={`mr-2 mr-5 py-1 ${
                fileType === 'excel' ? 'text-green-600' : 'text-red-600'
              }  ${isFileEmpty ? 'text-gray-600' : ''} `}
              data-testid='download-last-file-icon'
            />
            <div>{fileType === 'excel' ? 'EXCEL' : 'PDF'}</div>
          </div>
        )}
      </button>
    </div>
  );
}
export default DownloadLastUpdatedFile;
