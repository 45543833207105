import type { KVPairs, DealData } from '@types';
import { openHubspotDeal } from '@/utils/hubspot';
import { Button } from 'react-daisyui';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Accordion } from '@/components/Accordion/Accordion';
import { TAXES_DEAL_PIPELINES } from '../../../../../../shared/constants/hubspotConstants';

const NAMES_MAP_DEAL: KVPairs = {
  status: 'Status',
  amount_tax_deals: 'Amount',
  n_1099s: '1099s',
  delaware_franchise_tax: 'Delaware Franchise Tax',
  erc_tax: 'Employee Retention Credit',
  federal_income_tax: 'Federal Income Tax',
  k_1s_tax: 'K-1s',
  non_ca_de_franchise_tax: 'State Franchise Tax',
  r_d_tax_credits: 'R&D Tax Credits',
  states_income_tax: 'State(s) Income Tax',
  sales_owner: 'Sales Owner',
  tax_year: 'Tax Year',
};

interface TaxesDealsProps {
  deal: DealData;
  index: number;
  fontSize: string;
  showHubspotLink?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function TaxesDealDetails({
  deal,
  index,
  fontSize,
  showHubspotLink,
}: TaxesDealsProps) {
  const linkValidStatus = ['closedwon', 'churned'];

  return (
    <Accordion
      key={`${deal.deal_id}`}
      showBottomSeparator
      open={index === 0}
      toggler={
        <span className={`${fontSize} font-bold capitalize`}>
          {deal.deal_name}
        </span>
      }
    >
      <div className='flex'>
        <div className='flex-grow'>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.tax_year}:`}
            </span>
            <span className={`${fontSize} font-bold`}>{deal.tax_year}</span>
          </div>
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.status}:`}
            </span>
            <span className={`${fontSize} font-bold`}>{deal.status}</span>
          </div>

          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.amount_tax_deals}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.amount_monthly ? `$ ${deal.amount_monthly}` : 'N/A'}
            </span>
          </div>
          {deal.n_1099s ? (
            <div className='mt-2'>
              <span className={`${fontSize} font-bold`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.n_1099s}`}
              </span>
            </div>
          ) : null}
          {deal.delaware_franchise_tax === 'true' ? (
            <div className='mt-2'>
              <span className={`${fontSize} font-bold`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.delaware_franchise_tax}`}
              </span>
            </div>
          ) : null}
          {deal.erc_tax === 'true' ? (
            <div className='mt-2'>
              <span className={`${fontSize} font-bold`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.erc_tax}`}
              </span>
            </div>
          ) : null}
          {deal.federal_income_tax === 'true' ? (
            <div className='mt-2'>
              <span className={`${fontSize} font-bold`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.federal_income_tax}`}
              </span>
            </div>
          ) : null}
          {deal.k_1s_tax ? (
            <div className='mt-2'>
              <span className={`${fontSize} text-gray-400 mr-2`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.k_1s_tax}:`}
              </span>
              <span className={`${fontSize} font-bold`}>{deal.k_1s_tax}</span>
            </div>
          ) : null}
          {deal.non_ca_de_franchise_tax ? (
            <div className='mt-2'>
              <span className={`${fontSize} text-gray-400 mr-2`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.non_ca_de_franchise_tax}:`}
              </span>
              <span className={`${fontSize} font-bold`}>
                {deal.non_ca_de_franchise_tax}
              </span>
            </div>
          ) : null}
          {deal.r_d_tax_credits === 'true' ? (
            <div className='mt-2'>
              <span className={`${fontSize} font-bold`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.r_d_tax_credits}`}
              </span>
            </div>
          ) : null}
          {deal.states_income_tax ? (
            <div className='mt-2'>
              <span className={`${fontSize} text-gray-400 mr-2`}>
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {`${NAMES_MAP_DEAL.states_income_tax}:`}
              </span>
              <span className={`${fontSize} font-bold`}>
                {deal.states_income_tax}
              </span>
            </div>
          ) : null}
          <div className='mt-2'>
            <span className={`${fontSize} text-gray-400 mr-2`}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${NAMES_MAP_DEAL.sales_owner}:`}
            </span>
            <span className={`${fontSize} font-bold`}>
              {deal.sales_owner ? deal.sales_owner : 'N/A'}
            </span>
          </div>
        </div>
        {showHubspotLink &&
        deal.deal_id &&
        TAXES_DEAL_PIPELINES.includes(deal?.pipeline) &&
        linkValidStatus.includes(deal.status) ? (
          <div className='self-end'>
            <Button
              color='warning'
              data-testid='hubspot-button'
              variant='outline'
              size='sm'
              onClick={() => {
                openHubspotDeal(deal?.deal_id);
              }}
              startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
            />
          </div>
        ) : null}
      </div>
    </Accordion>
  );
}
