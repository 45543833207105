import { Card } from 'react-daisyui';
import { Spinner } from '@/components/Spinner';

export function DashboardCard({
  children,
  className = '',
  cardBodyclassName = '',
  hasMininumWidth = false,
  'data-testid': testId,
}: {
  children: React.ReactNode;
  className?: string;
  cardBodyclassName?: string;
  hasMininumWidth?: boolean;
  'data-testid'?: string;
}) {
  return (
    <div
      className={`bg-gradient-to-r p-[1px] from-[#00a089] to-[#ff0080] ${
        hasMininumWidth ? 'min-w-max rounded' : 'rounded'
      }`}
    >
      <Card
        bordered
        className={`bg-[#1b1b1c] shadow-2xl shadow-inner w-full min-h-[156px] ${className}`}
      >
        <Card.Body
          className={`w-full h-full ${cardBodyclassName}`}
          data-testid={testId}
        >
          {children}
        </Card.Body>
      </Card>
    </div>
  );
}

export function LoadingCard({ className = '' }: { className?: string }) {
  return (
    <DashboardCard className={className} data-testid='loading-card'>
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    </DashboardCard>
  );
}
