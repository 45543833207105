import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-daisyui';
import { useRollbar } from '@rollbar/react';
import { useEffect } from 'react';

interface Props {
  message?: string;
  description?: string;
  isSuperAdmin?: boolean;
  error?: unknown;
}
export default function ErrorAlert({
  message = `There was a problem loading the page`,
  description = `Please try refreshing the page, if the error persists contact the system's administrator.`,
  isSuperAdmin = false,
  error,
}: Props) {
  const rollbar = useRollbar();

  useEffect(() => {
    if (error && rollbar) {
      rollbar.error(`Error logged in ErrorAlert`, {
        error,
      });
    }
  }, [error, rollbar]);

  return (
    <Alert
      // eslint-disable-next-line max-len
      className='rounded-2xl opacity-80  w-[80%] text-sm transition-opacity duration-500 mx-auto my-8'
      icon={<FontAwesomeIcon icon={faTimesCircle} size='2x' />}
      status='error'
    >
      <div>
        <h2 className='text-lg font-bold'>{message}</h2>
        {description && <h3 className='text-base'>{description}</h3>}
        {isSuperAdmin && description && (
          <h4 className='text-base'>Error: {JSON.stringify(error, null, 2)}</h4>
        )}
      </div>
    </Alert>
  );
}
