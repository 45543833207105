import logo from '../assets/opstart-logo-lg.png';

function Logo({
  icon = true,
  text = true,
  className = '',
  iconClassName = 'w-16 h-16',
}: {
  icon?: boolean;
  text?: boolean;
  className?: string;
  iconClassName?: string;
}) {
  return (
    <h1
      className={`text-4xl flex items-start font-montserrat pt-2 pb-2 ${className}`}
    >
      {icon && (
        <img src={logo} alt='app logo' className={`mr-2 ${iconClassName}`} />
      )}
      {text && <span className='font-[600] color-[#FFFDF6]'>OpStart</span>}
    </h1>
  );
}
export default Logo;
export { Logo };
