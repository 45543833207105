/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AccountData, User } from '@types';
import { memo, useCallback, useMemo, useState } from 'react';
import partition from 'lodash/partition';
import { useRollbar } from '@rollbar/react';
import { ToastContainer } from 'react-toastify';
import { sendLoginToOtherUsers, login } from '@/utils/auth/auth';
import RenderUsers from '@/components/UsersAccordionList/RenderUsers';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { put } from '@/utils/api';
import { IUserContext, useUserContext } from '@/context/UserContext';
import {
  alertMessage,
  alertMessageSuccess,
  alertErrorMessage,
} from '@/utils/alerts';
import { getUserFullName } from '@/components/UserList/utils';
import * as typeform from '@typeform/embed-react';

const { PopupButton } = typeform;

interface FilteredUsers {
  internal: User[];
  external: User[];
}

interface Props {
  users: User[];
  size?: 'sm' | 'md';
  showHubspotLink?: boolean;
  showDeleteButton?: boolean;
  clientData: AccountData;
  test?: boolean;
}

function UsersAccordionList({
  users = [],
  size = 'md',
  showHubspotLink,
  showDeleteButton,
  clientData,
  test = false,
}: Props) {
  const rollbar = useRollbar();
  const filteredUsers = useMemo<FilteredUsers>(() => {
    const [external, internal] = partition(
      users?.filter(
        (user) =>
          user.enabled &&
          !user.role.includes('admin') &&
          (user.firms?.length === 0 || !user.firms)
      ),
      ({ role }) => role === 'investor'
    );

    return { internal, external };
  }, [users]);
  const { auth }: IUserContext = useUserContext(); // Use the context
  const [show2faModal, setShow2faModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const url = '/users/update-properties';
  const invalidUserMessage = 'Invalid User';
  const redirectUrl = `${window.location.host}/transactions`;
  const subject = 'Opstart - You have new Tasks';
  const type = 'tasks';

  const fontSize = size === 'md' ? 'text-base' : 'text-sm';
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const FORM_ID: string = (import.meta.env || process.env)
    ?.VITE_TYPEFORM_ADD_NEW_USER_ROLE_HIDDEN;

  const getHiddenProps = useMemo<Record<string, string>>((): Record<
    string,
    string
  > => {
    const userFullName = auth?.id ? getUserFullName(auth) : '';
    const addedById = auth?.id.toString() || '';
    const companyId = clientData?.hubspot_id || '';
    const companyName =
      (clientData?.business_info?.company_name as string) || '';

    return {
      company_name: companyName || '',
      added_by_full_name: userFullName,
      added_by_id: addedById,
      company_id: companyId,
      role: 'Internal',
    };
  }, [auth, clientData]);

  const updateUserProps = useCallback(async (): Promise<void> => {
    setShow2faModal(false);

    await put(
      url,
      users
        .filter((user) => !user.enable2FA)
        .map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.enable2FA = true;
          return {
            enable2FA: true,
            email: user.email_address,
          };
        })
    );
    alertMessage('top-right', 'Enable 2fa for all users');
  }, [users]);
  const sendLogin = async (user: User) => {
    if (user.role === 'investor') {
      setSelectedUser(user);
      setShowEmailModal(true);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error, success } = await sendLoginToOtherUsers({
      email: user.email_address,
      companyGroupEmail:
        (clientData?.plan?.company_group_email as string) || '',
    }).catch(() => {
      alertErrorMessage('Login failed');
    });

    if (error as Error) {
      alertErrorMessage('Login failed');
    } else if (success) {
      alertMessageSuccess('Login Sent Successfully!');
    }
  };
  // const enable2FA = async (email: string, checked: boolean): Promise<void> => {
  //   const user = users.find((u) => u.contact_email === email);
  //   if (user) user.mfaEnable = checked;
  //   await put(url, [
  //     {
  //       enable2FA: user?.mfaEnable,
  //       email: user?.contact_email,
  //     },
  //   ]);
  // };

  const sendTaskReminder = async (email: string) => {
    try {
      const res = await login(email.toLowerCase(), subject, type, redirectUrl);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      if ((res as any).success) {
        rollbar.debug(`Magic link sent to  ${email}`);
        alertMessageSuccess('Link Sent Successfully');
      } else {
        rollbar.error(`Error trying to send the magic link for user ${email}`, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          error: (res as any).error,
        });
        alertErrorMessage('Error sending magic link');
      }
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);

      rollbar.error(`Error trying to send the magic link for user ${email}`, {
        error: err,
      });
    }
  };

  if (users.length <= 0) {
    return (
      <>
        <h2>No Users</h2>
        <div className='flex items-center mb-4'>
          <PopupButton
            id={FORM_ID}
            hidden={getHiddenProps}
            transitiveSearchParams={['']}
            className='btn btn-accent btn-sm'
            key='add-user-button'
          >
            Add User
          </PopupButton>
        </div>
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      {!test && (auth?.primary || !auth?.isInvestor) ? (
        <>
          {/* Added flex and space-x-4 for spacing */}
          {/* <div
            className='flex items-center'
            style={!auth?.isAdmin ? { visibility: 'hidden' } : undefined}
          >
            <input
              type='checkbox'
              className='checkbox checkbox-accent mr-2'
              onChange={(e) => {
                setShow2faModal(e.target.checked);
              }}
            />
            2FA Enabled for all users
          </div> */}
          <GenericConfirmationModal
            onAcceptAction={() => {
              updateUserProps().catch((ex) => rollbar.error(ex as Error));
            }}
            onCloseAction={() => setShow2faModal(false)}
            showModal={show2faModal}
            modalMessage='Are you sure you want to enable 2fa for all the users?'
          />
        </>
      ) : null}

      <h2 className={`text-xl ${fontSize} mb-2 mt-0 pt-0 capitalize`}>
        internal company users
      </h2>

      <div className='flex items-center mb-4'>
        <PopupButton
          id={FORM_ID}
          hidden={getHiddenProps}
          transitiveSearchParams={['']}
          className='btn btn-accent btn-sm'
          key='add-user-button'
        >
          Add User
        </PopupButton>
      </div>

      <RenderUsers
        key='internal company users'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        currentUserEmail={auth?.email_address as string}
        users={filteredUsers.internal}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        sendLogin={sendLogin}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        sendTaskReminder={sendTaskReminder}
        showHubspotLink={showHubspotLink}
        showDeleteButton={showDeleteButton}
        fontSize={fontSize}
        showEmailModal={showEmailModal}
        selectedUser={selectedUser}
        onClose={() => {
          setShowEmailModal(false);
          setSelectedUser(null);
        }}
        companyName={clientData?.business_info?.company_name as string}
        companyEmail={clientData?.plan?.company_group_email as string}
        // enable2FA={(email: string, checked: boolean) =>
        //   enable2FA(email, checked)
        //     .catch((ex) => rollbar.error(ex as Error))
        //     .then(() =>
        //       updateAlertMessage(
        //         `${checked ? 'Enable ' : 'Disable'} 2fa for user ${email}`
        //       )
        //     )
        // }
      />
    </>
  );
}

export default memo(UsersAccordionList);
