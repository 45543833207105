/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { memo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Button } from 'react-daisyui';
import AddFirmModal from '@/components/UsersAccordionList/AddFirmModal';
import { FirmsWithUserDTO, AccountData, User } from '@shared/types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import RenderUsers from '@/components/UsersAccordionList/RenderUsers';
import { sendLoginToOtherUsers, login } from '@/utils/auth/auth';
import { alertMessageSuccess, alertErrorMessage } from '@/utils/alerts';
import { useRollbar } from '@rollbar/react';
import AddUserModal from '@/components/UsersAccordionList/AddUserModal';
import RenderFirms from '@/components/firmsAccordionList/renderFirms';

interface Props {
  firms: FirmsWithUserDTO[];
  size?: 'sm' | 'md';
  showHubspotLink?: boolean;
  clientData: AccountData;
}

function FirmsAccordionList({
  firms = [],
  size = 'md',
  showHubspotLink,
  clientData,
}: Props) {
  const { auth }: IUserContext = useUserContext();
  const rollbar = useRollbar();
  const [showModalAddFirm, setShowModalAddFirm] = useState(false);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const invalidUserMessage = 'Invalid User';
  const redirectUrl = `${window.location.host}/transactions`;
  const subject = 'Opstart - You have new Tasks';
  const type = 'tasks';

  const fontSize = size === 'md' ? 'text-base' : 'text-sm';
  const clientId = clientData.id;

  const firmUserIds = firms
    .flatMap((firm) => firm.firm_users)
    .map((firmUser) => firmUser?.user_id as number);
  const externalUsers = clientData.users
    ?.map((user) => user as unknown as User)
    .filter(
      (user) =>
        user.enabled &&
        user.role === 'investor' &&
        !firmUserIds.includes(user.id)
    );

  const sendLogin = async (user: User) => {
    if (user.role === 'investor') {
      setSelectedUser(user);
      setShowEmailModal(true);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error, success } = await sendLoginToOtherUsers({
      email: user.email_address,
      companyGroupEmail:
        (clientData?.plan?.company_group_email as string) || '',
    }).catch(() => {
      alertErrorMessage('Login failed');
    });

    if (error as Error) {
      alertErrorMessage('Login failed');
    } else if (success) {
      alertMessageSuccess('Login Sent Successfully!');
    }
  };

  const sendTaskReminder = async (email: string) => {
    try {
      const res = await login(email.toLowerCase(), subject, type, redirectUrl);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      if ((res as any).success) {
        rollbar.debug(`Magic link sent to  ${email}`);
        alertMessageSuccess('Link Sent Successfully');
      } else {
        rollbar.error(`Error trying to send the magic link for user ${email}`, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          error: (res as any).error,
        });
        alertErrorMessage('Error sending magic link');
      }
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);

      rollbar.error(`Error trying to send the magic link for user ${email}`, {
        error: err,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className='flex items-center space-x-4'>
        {' '}
        {/* Added flex and space-x-4 for spacing */}
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-0 mb-2'
          data-testid='add-firm-button'
          onClick={() => setShowModalAddFirm((prev) => !prev)}
        >
          Add Firm
        </Button>
        {showModalAddFirm ? (
          <AddFirmModal
            company={{
              id: clientData?.id,
              client_name: '',
              hubspot_id: clientData?.hubspot_id,
            }}
            isOpen={showModalAddFirm}
            onClose={() => setShowModalAddFirm(false)}
          />
        ) : null}
      </div>

      <RenderFirms
        firms={firms}
        clientId={clientId}
        companyName={clientData?.business_info.company_name as string}
        forInvestor={false}
        showHubspotLink={showHubspotLink}
      />
      <h2 className={`text-xl ${fontSize} mt-10 mb-2 capitalize`}>
        external viewers
      </h2>

      <div className='mb-2'>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          data-testid='add-user-button'
          onClick={() => setShowModalAddUser((prev) => !prev)}
        >
          Add User
        </Button>
        {showModalAddUser ? (
          <AddUserModal
            company={{
              id: clientData?.id,
              client_name:
                (clientData.business_info.company_name as string) || '',
              hubspot_id: clientData?.hubspot_id,
            }}
            isOpen={showModalAddUser}
            onClose={() => setShowModalAddUser(false)}
          />
        ) : null}
      </div>

      <RenderUsers
        key='external viewers'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        currentUserEmail={auth?.email_address as string}
        users={externalUsers}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        sendLogin={sendLogin}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        sendTaskReminder={sendTaskReminder}
        showHubspotLink={showHubspotLink}
        showDeleteButton={false}
        fontSize={fontSize}
        showEmailModal={showEmailModal}
        selectedUser={selectedUser}
        onClose={() => {
          setShowEmailModal(false);
          setSelectedUser(null);
        }}
        companyName={clientData?.business_info?.company_name as string}
        companyEmail={clientData?.plan?.company_group_email as string}
        // enable2FA={(email: string, checked: boolean) =>
        //   enable2FA(email, checked)
        //     .catch((ex) => rollbar.error(ex as Error))
        //     .then(() =>
        //       updateAlertMessage(
        //         `${checked ? 'Enable ' : 'Disable'} 2fa for user ${email}`
        //       )
        //     )
        // }
      />
    </>
  );
}

export default memo(FirmsAccordionList);
