import moment from 'moment';
import { FileItem } from '../../../../shared/types/files';

export const getMostRecentMonth = (items: Array<FileItem | undefined>) => {
  const months = items.map((file) =>
    moment(file?.created_at).subtract(1, 'months').month()
  );
  const filteredMonths = months.filter((month) => !!month);
  const monthList = filteredMonths.length > 0 ? filteredMonths : [0];
  const monthValue =
    items.length > 0 ? Math.max(...monthList) : Math.max(...[]);
  const monthName = moment.months(monthValue);
  return { monthValue, monthName };
};

export const getMostRecentYear = (items: Array<FileItem | undefined>) => {
  const years = items.map((file) =>
    file?.created_at ? moment(file?.created_at).year() : undefined
  );
  const filteredYears = years.filter(
    (year) => !!year && !Number.isNaN(year)
  ) as number[];
  const maxYear =
    filteredYears.length > 0 ? Math.max(...filteredYears) : undefined;
  return maxYear;
};
