/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Link, useNavigate } from 'react-router-dom';
import { Button, Navbar } from 'react-daisyui';
import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Logo } from '@/components/Logo';

import { IUserContext, useUserContext } from '@/context/UserContext';
import { getUserInitials } from '@/components/UserList/utils';
import { getMicrosoftDialogUrl } from '@/utils/microsoftDelegatedFlow';
import ImpersonationMessage from '@/components/Nav/ImpersonationMessage';
import ReferralModal from '@/components/Nav/ReferralModal';
import MobileMenu from '@/components/Nav/MobileMenu';
import ScheduleModal from '@/components/Nav/ScheduleModal';
import type { JWTUser, Client } from '@types';
import ChangePasswordModal from '@/components/Nav/ChangePasswordModal';

interface Props {
  companyName?: string;
  companyData?: Client[];
  onMenuClick?: () => void;
  isMenuOpen: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export function Nav({
  companyName,
  companyData,
  onMenuClick,
  isMenuOpen,
}: Props) {
  const {
    auth,
    impersonatingAccountId,
    clearImpersonationSession,
    hasRequestPasswordChange,
    logout,
    removeRequestPasswordChange,
  }: IUserContext = useUserContext(); // Use the context
  const initials = getUserInitials(auth as JWTUser);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(
    hasRequestPasswordChange()
  );
  const { isClient, isInvestor } = auth || {};
  const navigate = useNavigate();

  const closePassword = () => {
    setShowChangePassword(false);
    removeRequestPasswordChange();
  };

  const userLogout = () => {
    logout().finally(() => {
      clearImpersonationSession();
    });
    navigate('/logout');
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const MDFUser = (import.meta.env || process.env)
    ?.VITE_MICROSOFTDELEGATEDFLOW_USER;

  const onShowReferralModal = (show = true) => {
    setShowReferralModal(show);
  };

  const onShowScheduleModal = (show = true) => {
    setShowScheduleModal(show);
  };

  const onShowChangePassword = (show = true) => {
    setShowChangePassword(show);
  };

  const hoverClasses =
    'hover:bg-gray-400 md:hover:bg-transparent md:hover:border-[2px] ' +
    'md:hover:border-transparent';

  return (
    <>
      {companyName && impersonatingAccountId && (
        <ImpersonationMessage
          companyName={companyName}
          companyData={companyData}
        />
      )}
      <div
        className='flex w-full component-preview items-center justify-center gap-2 font-sans mx-auto z-[10]'
        data-testid='dashboard-nav'
      >
        <Navbar className='bg-[#454648] shadow-xl rounded-box items-center capitalize min-h-0 p-0'>
          <Navbar.Start>
            <Button
              className='ml-2 hidden md:flex'
              shape='square'
              color='ghost'
              onClick={onMenuClick}
            >
              <Bars3Icon
                className={`w-6 h-6 transform duration-100
                transform transition ease-linear ${
                  isMenuOpen ? '-rotate-90' : ''
                }`}
              />
            </Button>
            <MobileMenu />
            <Link to='/'>
              <Logo
                className='text-2xl ml-4 items-center min-w-[200px]'
                iconClassName='h-10 w-10'
              />
            </Link>
          </Navbar.Start>
          <Navbar.End className='mr-2'>
            <details
              className='dropdown dropdown-end z-[102]'
              aria-label='user-menu'
            >
              <summary
                tabIndex={0}
                className='avatar online placeholder btn btn-circle'
              >
                <div className='bg-neutral text-neutral-content rounded-full w-12'>
                  <span>{initials}</span>
                </div>
              </summary>
              <div
                tabIndex={0}
                className='w-auto p-0  mt-1 shadow card rounded-lg dropdown-content bg-stone-900 text-gray-300'
              >
                <div className='card-body'>
                  <ul className='list-none'>
                    <li className='pt-2'>
                      <div className='grey-100 text-ellipsis lowercase max-w-[250px] text-ellipsis overflow-hidden'>
                        {auth?.email_address}
                      </div>
                    </li>
                    <li className='pt-2'>
                      <span className='grey-100'>
                        {auth?.role === 'investor' ? 'External' : auth?.role}
                      </span>
                    </li>
                    {isClient ? (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                      <li
                        className={`pt-2 cursor-pointer ${hoverClasses}`}
                        onClick={() => onShowReferralModal()}
                      >
                        Share and get $250 off
                      </li>
                    ) : (
                      false
                    )}
                    {isClient || isInvestor ? (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                      <li
                        className={`pt-2 cursor-pointer ${hoverClasses}`}
                        onClick={() => onShowScheduleModal()}
                      >
                        Schedule a call
                      </li>
                    ) : (
                      false
                    )}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                      className={`pt-2 cursor-pointer ${hoverClasses}`}
                      onClick={() => onShowChangePassword()}
                    >
                      Change Password
                    </li>
                    {auth &&
                      auth.isSuperAdmin &&
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      auth?.email_address === MDFUser &&
                      !auth.delegatedPermissionsActive && (
                        <a
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                          href={getMicrosoftDialogUrl(
                            auth?.id as unknown as number
                          )}
                          className='btn btn-accent btn-md mt-2 capitalize'
                        >
                          Grant Delegated Permissions
                        </a>
                      )}
                    <li>
                      <Button
                        onClick={userLogout}
                        className='btn-link text-gray-300 capitalize text-base pl-0'
                      >
                        logout
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </details>
            <ReferralModal
              isOpen={showReferralModal}
              onClose={() => setShowReferralModal(false)}
            />
            <ScheduleModal
              isOpen={showScheduleModal}
              onClose={() => setShowScheduleModal(false)}
            />
            <ChangePasswordModal
              isOpen={showChangePassword}
              onClose={() => closePassword()}
            />
          </Navbar.End>
        </Navbar>
      </div>
    </>
  );
}
