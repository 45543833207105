import { JSXElementConstructor, ReactElement } from 'react';
import { Button, Pagination, Select } from 'react-daisyui';

const { Option } = Select;
interface Props {
  numPages: number;
  selectedPage: number;
  rowsPerPage: number;
  selectedRowsPerPage: number[];
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (page: number) => void;
  firstLabel?: string;
  lastLabel?: string;
  pagesToShow?: number;
}
// eslint-disable-next-line import/prefer-default-export
export function OSPagination({
  numPages,
  selectedPage,
  rowsPerPage,
  selectedRowsPerPage,
  onPageSelect,
  onSelectRowsChange,
  firstLabel = 'First',
  lastLabel = 'Last',
  pagesToShow = 10,
}: Props) {
  function getPageNumbers(
    currentPage: number,
    totalPages: number
  ): { index: number; label: string }[] {
    const halfNumbersToShow = Math.floor(pagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfNumbersToShow);
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    if (endPage - startPage < pagesToShow - 1) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const buttonPages: { index: number; label: string }[] = [];

    if (startPage > 1) {
      buttonPages.push({ index: 0, label: firstLabel });
    }

    for (let i = startPage; i <= endPage; i += 1) {
      buttonPages.push({ index: i - 1, label: i.toString() });
    }

    if (endPage < totalPages) {
      buttonPages.push({ index: totalPages - 1, label: lastLabel });
    }

    return buttonPages;
  }

  const buttonData = getPageNumbers(selectedPage, numPages);
  return (
    <Pagination className='m-4 inline text-right'>
      <>
        <span className='text-sm'>Rows per page:</span>
        <Select
          size='sm'
          bordered={false}
          className='mx-2'
          data-testid='paginationSelect'
          defaultValue={rowsPerPage}
          onChange={(page: number) => onSelectRowsChange(page)}
        >
          {selectedRowsPerPage.map((option) => (
            <Option value={option} key={`${option}_option`}>
              {option}
            </Option>
          ))}
        </Select>
      </>
      {
        /* eslint-disable */
        buttonData.map((data) => {
          return (
            <span key={`${data.index + 1 }_span`}>
            {data.label === lastLabel ? (<span>...</span>) :  null}
            <Button
              size='sm'
              onClick={() => onPageSelect(data.index + 1)}
              className={`${data.index + 1 === selectedPage ? 'btn-active' : ''} ${
                data.index === 0 ? ' first-button' : ''
              }`}
              key={`${data.index + 1 }_button`}
              >
              {data.label}
            </Button>
            {data.label === firstLabel ? (<span>...</span>) :  null}
              </span>
          );
        }) as unknown as ReactElement<any, string | JSXElementConstructor<any>>
        /* eslint-enabled */
      }
    </Pagination>
  );
}
