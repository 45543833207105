/* eslint-disable max-len */
import GenericCustomEmailModal from '@/components/Modal/GenericCustomEmailModal';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { sendLoginToOtherUsers } from '@/utils/auth/auth';

function SendLoginEmailToInvestorModal({
  companyName,
  showModal,
  onClose,
  email,
  companyEmail,
}: {
  companyName?: string;
  showModal: boolean;
  onClose: () => void;
  email: string;
  companyEmail?: string;
}) {
  const subjectLine = companyName
    ? `${companyName} wants to send you financial reports via OpStart`
    : `OpStart is Inviting You to View Your Portfolio Companies' Financial Reports`;
  const content =
    `Hi, \n\n` +
    `Your portfolio companies want to share monthly financial reports with you via <a href='https://opstart.co'>Opstart</a>.
      \n` +
    `It's free to use, and you already have financial reports & other key documents awaiting review! \n\n` +
    `Simply click the below button to login and unlock better visibility into your portfolio companies' performance. \n\n` +
    `Moving forward, you can bookmark this site (app.opstart.co) and login any time to see the latest updates from every OpStart client in your portfolio. \n\n` +
    `If you have any questions or feedback, simply reply to this email.\n\n`;

  const handleSubmit = async (
    subject: string,
    editedContent: string,
    emailAddress?: string
  ) => {
    const paragraphs = editedContent
      .split('\n')
      .map((paragraph) => paragraph.trim());
    const wrappedParagraphs = paragraphs.map(
      (paragraph) => `
          <p style="color:#FFFDF6;font-size:12px;line-height:20px;width:100%;word-break:break-all;">
          ${paragraph}</p>
          `
    );
    const formattedText = wrappedParagraphs.join('');

    await sendLoginToOtherUsers({
      email: emailAddress as string,
      subject,
      content: formattedText,
      companyGroupEmail: companyEmail,
    })
      .then(() => {
        alertMessageSuccess('Login Sent Successfully!');
      })
      .catch(() => {
        alertErrorMessage('Login failed');
      });
    onClose();
  };

  return (
    <GenericCustomEmailModal
      showModal={showModal}
      initialSubject={subjectLine}
      initialContent={content}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit}
      onClose={onClose}
      email={email}
    />
  );
}

export default SendLoginEmailToInvestorModal;
