import Select from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

export function TableSwitcher({
  options,
  selectedValue,
  setSelectedValue,
}: Props) {
  return (
    <Select
      className='react-select-container border-accent'
      options={options}
      value={options.find((option) => option.value === selectedValue)}
      onChange={(selectedOption) =>
        setSelectedValue(selectedOption?.value || 'Clients')
      }
      styles={{
        control: (provided) => ({
          ...provided,
          minWidth: 150,
          color: 'black',
          backgroundColor: 'black',
          border: '1px solid #00a089',
        }),
        menu: (provided) => ({
          ...provided,
          color: 'black',
          backgroundColor: 'black',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'white',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'black' : 'white',
          backgroundColor: state.isSelected ? 'white' : 'black',
        }),
      }}
    />
  );
}
