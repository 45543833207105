function SyftDashboard({
  syftUrl,
  title,
}: {
  syftUrl: string;
  title?: string;
}) {
  return (
    <div className='px-6 py-4' key={syftUrl}>
      {title ? (
        <div className='text-base grow py-3 min-w-[300px]'>
          <div className='font-bold text-base'>{title} </div>
        </div>
      ) : null}
      {syftUrl ? (
        <iframe
          title='dashboard-syft'
          src={syftUrl}
          height='380px'
          width='100%'
          className='rounded-2xl transparent'
          data-testid={`syft-dashboard-${
            title?.toLowerCase().replace(/\s+/g, '') || ''
          }`}
        />
      ) : null}
    </div>
  );
}

export default SyftDashboard;
