import { ToastContainer } from 'react-toastify';

function PageContainer({
  children,
  name = 'default',
}: {
  children: React.ReactNode;
  name: string;
}) {
  return (
    <div
      className='w-screen h-screen overflow-auto'
      data-testid={`${name}-page-container`}
    >
      <ToastContainer />
      {children}
    </div>
  );
}

export default PageContainer;
