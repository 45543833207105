import { IUserContext, useUserContext } from '@/context/UserContext';
import { Auth } from '@/types';
import { getDefaultRoute } from '@/utils/auth';
import { Navigate } from 'react-router-dom';

function NavigateToDefaultPath() {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();

  return (
    <Navigate
      to={getDefaultRoute(auth as Auth, Boolean(impersonatingAccountId))}
      replace
    />
  );
}

export default NavigateToDefaultPath;
