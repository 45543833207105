import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { post, put, fetchDelete } from '@/utils/api';
import type { ClientInternalNote, QboConfigData } from '@types';

function useInternalNotes() {
  const baseRoute = 'clients/note/';

  const getInternalNotes = (clientId: number) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<QboConfigData, Error>(
      `/api/${baseRoute}${clientId}`,
      fetcher,
      {
        revalidateOnMount: true,
        revalidateOnFocus: false,
      }
    );
  };

  const addInternalNotes = async (clientId: number, notes: string) => {
    return post<{ notes: ClientInternalNote[]; message: string }>(
      `${baseRoute}${clientId}`,
      { notes }
    );
  };

  const updateInternalNotes = async (
    clientId: number,
    noteId: string,
    notes: string
  ) =>
    put<{ notes: ClientInternalNote[]; message: string }>(
      `${baseRoute}${clientId}/${noteId}`,
      { notes }
    );

  const deletedInternalNotes = async (clientId: number, noteId: string) =>
    fetchDelete(`${baseRoute}${clientId}/${noteId}`);

  return {
    getInternalNotes,
    addInternalNotes,
    updateInternalNotes,
    deletedInternalNotes,
  };
}

export default useInternalNotes;
