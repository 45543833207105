/* eslint-disable @typescript-eslint/no-misused-promises */
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IUserContext, useUserContext } from '@/context/UserContext';
import { MENU_BY_USER, getIconDefinitionByName } from '@/components/Nav/utils';
import { MenuOption } from '@/types/menu-options';
import useCustomTabsPermissions from '@/hooks/useCustomTabsPermissions';
import useInvestor from '@/hooks/useInvestor';
import {
  faChevronDown,
  faChevronRight,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import MainMenuItem from '@/components/Nav/MainMenuItem';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { post } from '@/utils/api';
import { validateEmail } from '@/utils/email';
import {
  CustomTabItem,
  User,
  JWTUser,
  Client,
  ExtendedCustomTabItem,
} from '@types';
import 'react-toastify/dist/ReactToastify.css';
import AddNonOpstartUserModal from './AddNonOpstartUserModal';

function AddingCompanyModal({
  showModalStatus = false,
  onClose,
  auth,
}: {
  showModalStatus: boolean;
  onClose: () => void;
  auth: JWTUser;
}) {
  const [showModal, setShowModal] = useState(showModalStatus);
  const [errorMessage, setErrorMessage] = useState('');
  const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [showNonOpstartModal, setShowNonOpstartModal] = useState(false);
  const [email, setEmail] = useState('');
  const [companyName, setCompanyname] = useState('');

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    if (newEmail.length > 6)
      if (!validateEmail(newEmail))
        setErrorMessage('Error! you should provide a valid email');
      else setErrorMessage('');
  };

  const clearInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.value = '';
    }
    setEmail('');
    setErrorMessage('');
  };

  const onHandleAccept = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const clientEmail: string = (emailInputRef?.current as any)?.value || '';
    setEmail(clientEmail);

    let emailExistsinDb = { enabled: false } as User;

    try {
      emailExistsinDb = await post<User>('users/email', {
        clientEmail,
      });
    } catch (error) {
      // The back end reports the error to rollbar if an actual error occurs
    }

    if (emailExistsinDb.enabled && errorMessage.length === 0) {
      const name = emailExistsinDb.clients?.[0]?.client_name;
      setCompanyname(name as string);
      setVerifiedEmail(true);
      setErrorMessage('');
      setShowModal(false);
    } else {
      setVerifiedEmail(false);
    }
    setShowNonOpstartModal(true);
  }, [errorMessage]);

  useEffect(() => {
    setShowModal(showModalStatus);
    if (!showModalStatus) {
      clearInput();
    }
  }, [showModalStatus]);

  return (
    <>
      <GenericConfirmationModal
        onAcceptAction={async () => {
          await onHandleAccept();
          setShowModal((prev) => !prev);
        }}
        onCloseAction={() => {
          onClose();
          clearInput();
        }}
        showModal={showModal}
        modalMessage="To add a Startup enter the founder's work email address below:"
        isLoading={errorMessage.length > 1}
      >
        <div className='font-bold text-sm w-[90%] text-left'>
          <Input
            size='md'
            type='email'
            className='border-accent w-full'
            required
            data-testid='working-email-input'
            ref={emailInputRef}
            placeholder='Work email'
            onBlur={handleEmailChange}
          />
          {errorMessage.length > 0 ? (
            <div role='alert' className='alert alert-error mt-2'>
              <span>{errorMessage}</span>
            </div>
          ) : null}
        </div>
      </GenericConfirmationModal>

      {showNonOpstartModal && (
        <AddNonOpstartUserModal
          showModalStatus={showNonOpstartModal}
          email={email}
          onClose={() => setShowNonOpstartModal(false)}
          auth={auth}
          verifiedEmail={verifiedEmail}
          companyName={companyName}
        />
      )}
    </>
  );
}

function AddNewCompanyButton({ auth }: { auth: JWTUser }) {
  const [showModal, setShowModal] = useState(false);
  if (!auth.isInvestor) return null;
  return (
    <div className='mt-2 w-[90%] justify-center'>
      <Button
        type='submit'
        title='login'
        color='accent'
        size='sm'
        className='btn btn-accent bg-transparentp-2 m-2'
        data-testid='add-new-company-button'
        onClick={() => setShowModal((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faPlusCircle} className='mr-1' />
        add new company
      </Button>
      <AddingCompanyModal
        showModalStatus={showModal}
        onClose={() => setShowModal(false)}
        auth={auth}
      />
    </div>
  );
}
function InvestorsMenu() {
  const {
    impersonatingAccountId,
    impersonate,
    auth,
    setimpersonatingAccountId,
    clearImpersonationSession,
  }: IUserContext = useUserContext();

  const [customTabInfo, setCustomTabInfo] = useState<CustomTabItem[] | null>(
    null
  );
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const { useStartups } = useInvestor();
  const { data: startups, isLoading: isLoadingStartups } = useStartups();
  const { useCustomTabs } = useCustomTabsPermissions();
  const navigate = useNavigate();
  const [impersonateMenuOptions, setImpersonateMenuOptions] = useState<
    MenuOption[]
  >([]);

  const clientId = auth?.isClient
    ? auth?.clients?.[0]?.id
    : impersonatingAccountId || undefined;

  const { data: customTabs, isLoading } = useCustomTabs(
    auth?.id as number,
    clientId
  );

  // Function to handle action (only for the first render)
  const onStartupAction = (
    startupId: number,
    startupName: string,
    url: string
  ) => {
    if (impersonate && auth?.authorizedClientIds.includes(startupId)) {
      impersonate(startupId, startupName);
    } else {
      clearImpersonationSession();
    }
    navigate(url, { replace: true });
  };

  useEffect(() => {
    if (customTabs) setCustomTabInfo(customTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isLoading]);

  useEffect(() => {
    const userFirmsIds = auth?.firms?.map((firm) => firm.id) || [];

    const filteredCustomTabs = (
      (customTabInfo as ExtendedCustomTabItem[]) || []
    ).filter((customTab) => {
      const tabAllowedFirms = (customTab.allowedFirms || []).map(
        (firm) => firm.firmId
      );
      const skippedUsers = (customTab.allowedFirms || []).flatMap((firm) =>
        firm.skippedUsers.map((userId) => userId)
      );
      return (
        (customTab.allowedUsers &&
          customTab.allowedUsers.includes(auth?.id as number)) ||
        (customTab.allowedFirms &&
          userFirmsIds.some((firm) => tabAllowedFirms.includes(firm)) &&
          !skippedUsers.includes(auth?.id as number))
      );
    });
    const prepareCustomTabs: MenuOption[] = (filteredCustomTabs || []).map(
      (customTab: CustomTabItem) => ({
        icon: getIconDefinitionByName(customTab.icon) as IconProp,
        label: customTab.titleOfTab,
        url: `/custom-tab-option/${encodeURIComponent(
          customTab.titleOfTab
        )}?embeddedLink=${encodeURIComponent(
          customTab.embeddedLink
        )}&documentLink=${encodeURIComponent(customTab.documentLink)}`,
      })
    );
    // Function to generate menu options
    const getMenuOptions = () => {
      let options: MenuOption[];
      options = [];
      setImpersonateMenuOptions(
        [...MENU_BY_USER.clientInvestor, ...prepareCustomTabs] || []
      );

      // Remove the /account option for investors when impersonating
      if (impersonatingAccountId && auth?.role === 'investor') {
        options = options.filter((option) => option.url !== '/account');
      }

      return options;
    };
    const menu = getMenuOptions();

    // Set the menu options without the action
    setMenuOptions(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatingAccountId, customTabInfo]);

  // Separate useEffect to handle adding startups to the menu
  useEffect(() => {
    if (
      auth?.role === 'investor' &&
      impersonateMenuOptions &&
      startups &&
      startups?.length > 0 &&
      !isLoadingStartups
    ) {
      const filteredStartups = startups
        ?.sort((a, b) => (a.client_name >= b.client_name ? 1 : -1))
        .filter(
          (startup) =>
            auth.isSuperAdmin || auth.authorizedClientIds.includes(startup.id)
        );

      const startupOptions = filteredStartups?.map((startup: Client) => ({
        label: startup.client_name,
        icon:
          impersonatingAccountId === startup.id
            ? faChevronDown
            : faChevronRight,
        url: `/home-${startup.client_name}`,
        subOptions:
          impersonatingAccountId === startup.id ? impersonateMenuOptions : [],
        action: () => {
          if (setimpersonatingAccountId && impersonatingAccountId) {
            setimpersonatingAccountId(null);
          } else {
            onStartupAction(startup.id, startup.client_name, `/home`);
          }
        },
      }));
      setMenuOptions([...startupOptions]);
      if (
        impersonatingAccountId &&
        !auth.authorizedClientIds.includes(impersonatingAccountId) &&
        !auth.isSuperAdmin
      ) {
        clearImpersonationSession();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startups,
    impersonatingAccountId,
    impersonateMenuOptions,
    isLoadingStartups,
    auth,
  ]);

  if (!auth?.isInvestor) {
    return null;
  }

  return (
    <>
      <div className='text-base grow mt-2 mx-3'>
        <div className='font-bold text-base p-2'>Companies</div>
      </div>
      <ul
        className='menu min-w-full overflow-hidden px-1'
        id='MainMenu'
        role='menu'
      >
        {menuOptions.map((option) => (
          <li
            key={option.label}
            className='&:not(:first-child)]:mt-2'
            role='menuitem'
          >
            <MainMenuItem {...option} />
          </li>
        ))}
      </ul>
      <AddNewCompanyButton auth={auth || false} />
    </>
  );
}

export default InvestorsMenu;
