import { signal } from '@preact/signals-react';

const formDataSignal = signal<FormData | null>(null);

export const setFormDataSignal = (formData: FormData | null) => {
  formDataSignal.value = formData;
};

export const getFormDataSignal = () => {
  return formDataSignal.value;
};
