/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo } from 'react';
import type { KVPairs } from '@types';
import { Divider, Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { patch } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { prettifyKey, prettifyValue, openHubspotClient } from '@/utils';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { filterDeals, removePrefixOnObjectProperties } from './utils';

// eslint-disable-next-line import/prefer-default-export
export function AccountDataDisplay({
  companyData,
  hubspotId,
  clientId,
}: {
  companyData: KVPairs | KVPairs[];
  hubspotId?: string;
  clientId: number;
}) {
  const { auth }: IUserContext = useUserContext();
  const prefix = 'company_';
  const filteredData = useMemo(
    () => removePrefixOnObjectProperties(companyData, prefix),
    [companyData]
  );

  const updateTeamsSettings = () => {
    patch(`clients/update-teams-settings/${clientId}`, null)
      .then(() => {
        alertMessageSuccess(
          'Team updated successfully. It may take a few minutes to take effect'
        );
      })
      .catch(() => {
        alertErrorMessage('Something went wrong. Please try again later.');
      });
  };

  return (
    <>
      <div className='flex justify-end'>
        {auth?.isSuperAdmin ? (
          <Button
            className='mr-3'
            color='warning'
            data-testid='hubspot-button'
            variant='outline'
            size='sm'
            onClick={() => {
              updateTeamsSettings();
            }}
            startIcon={<FontAwesomeIcon icon={faEnvelope as IconProp} />}
          >
            Update Teams Settings
          </Button>
        ) : null}
        <Button
          color='warning'
          data-testid='hubspot-button'
          variant='outline'
          size='sm'
          onClick={() => openHubspotClient(hubspotId)}
          startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
        />
      </div>
      <div
        className='flex justify-start w-full'
        data-testid='account-data-display'
      >
        <RowGroup data={filteredData} />
      </div>
    </>
  );
}

function RowGroup({
  data,
  bordered,
}: {
  data: KVPairs | KVPairs[];
  bordered?: boolean;
}) {
  return (
    <div className={`${bordered ? 'mb-5' : ''}`}>
      {Array.isArray(data)
        ? data.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <RowGroup key={index} data={item} bordered />
          ))
        : Object.entries(filterDeals(data, ['closedwon', 'churned'])).map(
            ([label, value]: [
              string,
              string | number | undefined | KVPairs[]
            ]) => {
              if (Array.isArray(value)) {
                const reversedDeals = value.slice().reverse();
                return reversedDeals.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <Divider />
                    <RowGroup data={item} bordered />
                  </div>
                ));
              }
              if (value) {
                return <Row key={label} label={label} value={value} />;
              }
              return <EmptyRow key={label} label={label} />;
            }
          )}
    </div>
  );
}

function Row({ label, value }: { label: string; value: string | number }) {
  return (
    <div className='my-3 break-all flex'>
      <div className='capitalize text-sm text-right pr-3'>
        {prettifyKey(label)}:
      </div>
      <div className='font-bold text-sm text-left pl-3'>
        {prettifyValue(value)}
      </div>
    </div>
  );
}

function EmptyRow({ label }: { label: string }) {
  return (
    <div className='my-3 break-all flex'>
      <div className='capitalize w-[38%] text-sm text-right pr-3'>
        {prettifyKey(label)}
      </div>
      <div className='font-bold w-[58%] text-sm text-left pl-3'>N/A</div>
    </div>
  );
}
