import { Sort, TableSort } from '@/types';

export const getNewSortState = (fieldName: string, currentSort: Sort) => {
  if (fieldName === currentSort.field) {
    return { ...currentSort, asc: !currentSort.asc };
  }
  return { field: fieldName, asc: true };
};

export const getNewSortStateTable = <T>(
  fieldName: keyof T,
  currentSort: TableSort<T>
): TableSort<T> => {
  if (fieldName === currentSort.field) {
    return { ...currentSort, asc: !currentSort.asc };
  }
  return { field: fieldName, asc: true };
};
