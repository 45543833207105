/* eslint-disable import/prefer-default-export */

export function checkAllPropertiesInArrayHaveValues(
  arrayOfObjects: { [key: string]: object }[]
): boolean {
  // Check if the input is an array and not empty
  if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
    return false;
  }

  // Function to check if all properties of an object have values
  function checkObjectProperties(obj: { [key: string]: unknown }): boolean {
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ''
    );
  }

  // Use the `every` method to check all objects in the array
  return arrayOfObjects.every(checkObjectProperties);
}
