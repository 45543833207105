import { Table } from 'react-daisyui';
import { OSPagination } from '@/components/Pagination/Pagination';
import { MenuAction, Sort } from '@/types';
import { SortArrow } from '@/components/SortArrow/SortArrow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEllipsisV,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { getPageRecords, handleFinancialDeliveryDateDeal } from '@/utils';
import { Client, HubspotPlan } from '@types';
import './TableClient.css';
import SelectColor from '@/components/SelectColor';
import TableDropDown from '@/components/TableDropDown/TableDropDown';

interface TableClientsProps {
  sortedClientRecords: Client[];
  actions: MenuAction[];
  sort: Sort;
  sortBy: (fieldName: string) => void;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (recordsPerPage: number) => void;
  handleFinancialDeliveryDate: (
    financialDate: Date | undefined,
    fileDate: string | undefined
  ) => boolean | undefined;
  formatDate: (date?: string) => string;
  formatString: (string?: string) => string | null;
  countActiveUsers: (client: Client) => number;
  hasSyftUrl: (client: Client) => number;
  hasQbo: (client: Client) => boolean;
  handleController: (plan: HubspotPlan | undefined) => string | undefined;
  showDeliveryDateColumn: boolean | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export function TableClients({
  sortedClientRecords,
  actions,
  sort,
  sortBy,
  selectedPage,
  setSelectedPage,
  recordsPerPage,
  setRecordsPerPage,
  handleFinancialDeliveryDate,
  formatDate,
  formatString,
  countActiveUsers,
  hasSyftUrl,
  hasQbo,
  handleController,
  showDeliveryDateColumn,
}: TableClientsProps) {
  return (
    <>
      <Table zebra id='table-clients'>
        <Table.Head>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('client_name')}
            className='cursor-pointer'
            data-testid='client-name-sorting'
          >
            <span className='text-base font-bold capitalize'>
              Company Name
              <SortArrow sort={sort} fieldName='client_name' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('status')}
            className='cursor-pointer w-[40px]'
            data-testid='client-status'
          >
            <span className='text-base font-bold capitalize'>
              Status <SortArrow sort={sort} fieldName='status' />
            </span>
          </div>
          {showDeliveryDateColumn && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                onClick={() => sortBy('financial_delivery_date')}
                className='cursor-pointer text-center'
              >
                <span className='text-base font-bold capitalize'>
                  Delivery Date
                  <SortArrow sort={sort} fieldName='financial_delivery_date' />
                </span>
              </div>
            </>
          )}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('latest_pdf_date')}
            className='cursor-pointer text-center'
          >
            <span className='text-base font-bold capitalize'>
              Last PDF
              <SortArrow sort={sort} fieldName='latest_pdf_date' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('latest_excel_date')}
            className='cursor-pointer text-center'
          >
            <span className='text-base font-bold capitalize'>
              Last Excel
              <SortArrow sort={sort} fieldName='latest_excel_date' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('syft')}
            className='cursor-pointer text-center'
          >
            <span className='text-base font-bold capitalize text-center'>
              Syft Url
              <SortArrow sort={sort} fieldName='syft' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('qbo')}
            className='cursor-pointer text-center'
          >
            <span className='text-base font-bold capitalize'>
              QBO
              <SortArrow sort={sort} fieldName='qbo' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('active_users')}
            className='cursor-pointer text-center'
          >
            <span className='text-base font-bold capitalize'>
              Users
              <SortArrow sort={sort} fieldName='active_users' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => sortBy('qbo_transactions')}
            className='cursor-pointer'
          >
            <span className='text-base font-bold capitalize'>
              Transactions
              <SortArrow sort={sort} fieldName='qbo_transactions' />
            </span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div onClick={() => sortBy('controllers')} className='cursor-pointer'>
            <span className='text-base font-bold capitalize'>
              Controller
              <SortArrow sort={sort} fieldName='controllers' />
            </span>
          </div>
          <span />
        </Table.Head>
        {sortedClientRecords.length > 0 && (
          <Table.Body className='text-sm'>
            {(
              getPageRecords(
                sortedClientRecords,
                selectedPage,
                recordsPerPage
              ) as Client[]
            ).map((client) => (
              <Table.Row
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                key={client.id}
                hover
                data-testid='client-list-item'
                className='cursor-pointer'
              >
                {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div onClick={() => actions[0].action(client)}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {client.client_name}
                </div>
                {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className='text-center'>
                  <SelectColor
                    clientId={client.id}
                    clientStatus={client.client_profiles?.status}
                  />
                </div>
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                  {showDeliveryDateColumn && (
                    <>
                      {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div
                        className='text-center'
                        onClick={() => actions[0].action(client)}
                        data-testid='delivery-date-cell-tooltip'
                      >
                        {handleFinancialDeliveryDateDeal(
                          client.hubspot_data?.detailedData?.plan
                        )}
                      </div>
                    </>
                  )}
                </>
                {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className={
                    // eslint-disable-next-line no-constant-condition, @typescript-eslint/restrict-template-expressions
                    `text-center ${
                      client.client_profiles?.latest_pdf_name ? 'tooltip' : ''
                    } ${
                      handleFinancialDeliveryDate(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        client?.hubspot_data?.financial_delivery_date,
                        client?.client_profiles?.latest_pdf_date
                      )
                        ? 'text-red-500 font-bold'
                        : ''
                    }`
                  }
                  data-tip={client.client_profiles?.latest_pdf_name}
                  data-testid='latest-pdf-cell-tooltip'
                >
                  {formatDate(client?.client_profiles?.latest_pdf_date)}
                  {formatString(client.client_profiles?.latest_pdf_name)}
                </div>
                {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className={
                    // eslint-disable-next-line no-constant-condition, @typescript-eslint/restrict-template-expressions
                    `text-center ${
                      client.client_profiles?.latest_excel_name ? 'tooltip' : ''
                    } ${
                      handleFinancialDeliveryDate(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        client?.hubspot_data?.financial_delivery_date,
                        client?.client_profiles?.latest_excel_date
                      )
                        ? 'text-red-500 font-bold'
                        : ''
                    }`
                  }
                  data-tip={client.client_profiles?.latest_excel_name}
                  data-testid='latest-excel-cell-tooltip'
                >
                  {formatDate(client?.client_profiles?.latest_excel_date)}
                  {formatString(client.client_profiles?.latest_excel_name)}
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className='text-center'
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {hasSyftUrl(client) ? (
                    <FontAwesomeIcon icon={faCheck} color='green' size='lg' />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color='red' size='lg' />
                  )}
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className='text-center'
                >
                  {hasQbo(client) ? (
                    <FontAwesomeIcon icon={faCheck} color='green' size='lg' />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color='red' size='lg' />
                  )}
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className='text-center'
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {/* {moment(new Date(client.updated_at as string)).format(
            'MMM DD, YYYY'
          )} */}
                  {countActiveUsers(client)}
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className='text-center'
                >
                  {client.qbo_companies?.reduce(
                    (acc, obj) => acc + obj.pending_transactions,
                    0
                  ) || 0}
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => actions[0].action(client)}
                  className='text-center tooltip'
                  data-tip={handleController(
                    client.hubspot_data?.detailedData?.plan
                  )}
                >
                  {handleController(client.hubspot_data?.detailedData?.plan)}
                </div>
                <div>
                  <TableDropDown
                    icon={faEllipsisV}
                    options={actions}
                    itemToPerformActionOn={client}
                    testId='view-client-button'
                    id={client.id.toString()}
                  />
                </div>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {sortedClientRecords.length === 0 && (
        <div className='text-center'>No Clients Found</div>
      )}
      <OSPagination
        numPages={Math.ceil(sortedClientRecords.length / recordsPerPage)}
        selectedPage={selectedPage}
        rowsPerPage={recordsPerPage}
        selectedRowsPerPage={[10, 20, 30, 40, 50]}
        onPageSelect={setSelectedPage}
        onSelectRowsChange={setRecordsPerPage}
      />
    </>
  );
}
