/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { CUSTOM_TABS_OPTIONS } from '@/types';
import { Button } from 'react-daisyui';
import { ToastContainer } from 'react-toastify';
import { post, put } from '@/utils/queries';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Spinner } from '@/components/Spinner';
import partition from 'lodash/partition';
import FormRow from '@/components/FormRow/FormRow';
import CustomTabsForm from '@/components/AccountDetails/components/CustomTabsForm/CustomTabsForm';
import QBOAccessForm from '@/components/AccountDetails/components/QBOAccessForm/QBOAccessForm';
import { safeParseJson } from '@/components/AccountDetails/utils';
import { alertErrorMessage, alertMessage } from '@/utils/alerts';
import useAccountData from '@/hooks/useAccountData';
import { ClientConfig } from '../../../../shared/types/client';
import { CustomTabItem } from '../../../../shared/types/custom-tabs';

export function AccountConfig({
  config,
  clientId,
}: {
  config: ClientConfig[];
  clientId: number;
}) {
  const { refreshAccountData } = useAccountData();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTabs, setLoadingTabs] = useState<boolean>(false);
  // TODO the name ClientConfigTypes is duplicated
  const defaultValues: { [key: string]: string } = {};

  if (config && config.length > 0) {
    const syftUrlOptions = config?.filter(
      (conf) => conf.config_key === 'syft_url'
    )?.[0]?.config_value;

    const parseSyftUrlOptions = safeParseJson(
      syftUrlOptions,
      []
    ) as CustomTabItem[];

    if (syftUrlOptions) {
      parseSyftUrlOptions.forEach((tab, index) => {
        defaultValues[`syftUrl_${index}`] = tab.syftUrl;
      });
    }
  }

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  const oldConfig = config?.filter(
    (conf) => conf.config_key === CUSTOM_TABS_OPTIONS
  )?.[0];

  const getCustomTabsInformation = (): string => {
    if (oldConfig) {
      return oldConfig?.config_value;
    }

    return '[]';
  };

  const saveCustomTabs = async (data: CustomTabItem[]): Promise<void> => {
    setLoadingTabs(true);
    try {
      if (oldConfig) {
        await put(`account/${clientId}/config`, [
          {
            id: oldConfig?.id,
            config_key: CUSTOM_TABS_OPTIONS,
            config_value: JSON.stringify(data),
          },
        ]);
      } else {
        await post(`account/${clientId}/config`, [
          {
            config_key: CUSTOM_TABS_OPTIONS,
            config_value: JSON.stringify(data),
          },
        ]);
      }
      alertMessage('top-right', 'The configuration has been saved.');
    } catch (err) {
      alertErrorMessage(
        `There was an error saving the configuration. Try again or contact the system administrator.`
      );
    } finally {
      setLoadingTabs(false);
    }
  };

  const onSubmit = async (data: unknown) => {
    setLoading(true);
    // we need to get the records we need to update, this deals for updates and inserts and it will
    // work in the future when there are more properties automatically as well
    const [dataToPut, dataToPost] = partition(
      Object.entries(data as string[][]).map((entry) => ({
        id: config?.find((conf) => conf.config_key === entry[0])?.id,
        config_key: entry[0],
        config_value: entry[1],
      })),
      (conf: ClientConfig) => conf.id
    );

    try {
      if (dataToPut.length) {
        await put(`account/${clientId}/config`, dataToPut);
        alertMessage('top-right', 'The configuration has been saved.');
        await refreshAccountData(Number(clientId));
      } else if (dataToPost.length) {
        await post(`account/${clientId}/config`, dataToPost);
        alertMessage('top-right', 'The configuration has been saved.');
        await refreshAccountData(Number(clientId));
      }
    } catch (err) {
      alertErrorMessage(
        `There was an error saving the configuration. Try again or contact the system administrator.`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <QBOAccessForm isLoading={loadingTabs} clientId={clientId} />
      <br />
      <hr />
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} data-testid='edit-config-form'>
        <div className='px-8 mb-4 mt-6' data-testid='account-config'>
          <FormRow
            register={register}
            errors={errors}
            label='syft_url'
            value={defaultValues.syftUrl_0}
            key={defaultValues.syftUrl_0}
          />
        </div>
      </form>
      <div className='flex flex-wrap gap-4 pb-2 mt-4'>
        <div className='font-bold text-sm w-full text-center pl-3'>
          <Button
            color='accent'
            size='md'
            disabled={loading || !isDirty || !isValid}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(onSubmit)}
          >
            Save
            {loading && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
      </div>
      <CustomTabsForm
        isLoading={loadingTabs}
        onSave={saveCustomTabs} // eslint-disable-line @typescript-eslint/no-misused-promises
        customTabsJsonString={getCustomTabsInformation()}
      />
    </>
  );
}
