import { QboInvoices } from '@types';
import moment from 'moment';
import { Column } from '@/components/Table/Table';
import { formatter } from '../../utils';

export interface InvoiceTableData {
  id?: number;
  doc_number: string;
  txn_date: string;
  due_date: string;
  balance: string;
}

export const columns: Column<InvoiceTableData>[] = [
  { header: 'Doc Number', accessor: 'doc_number', sortable: true },
  { header: 'Transaction Date', accessor: 'txn_date', sortable: true },
  { header: 'Due Date', accessor: 'due_date', sortable: true },
  { header: 'Balance', accessor: 'balance', sortable: true },
];

export const mapApiInvoicesToTableData = (values: QboInvoices[]) => {
  return values.map((value) => ({
    doc_number: value.doc_number,
    txn_date: moment(new Date(value.txn_date as unknown as string)).format(
      'MMM DD, YYYY'
    ),
    due_date: moment(new Date(value.due_date as unknown as string)).format(
      'MMM DD, YYYY'
    ),
    balance: formatter.format(Number(value.balance)),
    id: value.id,
  }));
};
