import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { CustomTabItem } from '../../../shared/types/custom-tabs';

function useCustomTabsPermissions() {
  const useCustomTabs = (userId: number, clientId?: number) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<CustomTabItem[], Error>(
      clientId ? `/api/permissions/custom_tabs/${clientId}/${userId}` : null,
      fetcher
    );
  };

  return { useCustomTabs };
}

export default useCustomTabsPermissions;
