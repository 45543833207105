import { DashboardCard } from '@/components/DashboardCard';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { InvestorReportsDetailsData } from '@/pages/InvestorReporting/utils/investorReports';
import { faArrowLeft, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvestorReportsInfo } from '@/pages/InvestorReporting/components/InvestorReportsInfo';
import { Spinner } from '@/components/Spinner';
import { useMemo } from 'react';
// eslint-disable-next-line
import { mapApiInvestorReportsDateToTableData } from '@/pages/InvestorReporting/components/Investors/investorClientList.util';
import { Card } from 'react-daisyui';
import useInvestorReportsData from '@/hooks/useInvestorReportsData';

export function InvestorReportsDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getInvestorReportsClientData } = useInvestorReportsData();
  const data = (location.state || undefined) as {
    clientId: number;
    userId?: number;
    firmId?: number;
    reportId: number;
  };

  const {
    data: reportData,
    isLoading,
    error,
    mutate,
  } = getInvestorReportsClientData(
    data?.clientId || 0,
    data?.userId || 0,
    data?.firmId || 0
  );

  const refreshData = async () => {
    await mutate();
  };

  const clientId = useMemo(() => data?.clientId || 0, [data?.clientId]);
  const investorReportsData: InvestorReportsDetailsData = useMemo(() => {
    if (!reportData) {
      return {
        investor_name: '',
        investor_reports: [],
        selected_investor_report: undefined,
      };
    }
    const mappedData = mapApiInvestorReportsDateToTableData(reportData);
    return {
      investor_name: mappedData[0]?.investor_name,
      investor_reports: reportData,
      selected_investor_report: reportData.find(
        (report) => report.id === data.reportId
      ),
    };
  }, [reportData, data?.reportId]);

  if ((!reportData && !isLoading) || error) {
    navigate('/i-reporting');
    return null;
  }

  if (isLoading) {
    return (
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    );
  }

  return (
    <PageContainer name='investor-report-details'>
      <DashboardWrapper>
        <>
          <div className='p-4'>
            <div className='flex w-full justify-between items-center mb-4'>
              <h1 className='text-lg font-bold uppercase'>
                <FontAwesomeIcon
                  icon={faChartLine}
                  size='lg'
                  className='mr-4 text-white'
                />
                Investor Reporting Detail
              </h1>
            </div>
          </div>
          <DashboardCard className='p-4' cardBodyclassName='p-0'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
              className='flex justify-between items-center'
              onClick={() => navigate('/i-reporting')}
              role='button'
            >
              <h2 className='text-mg font-bold uppercase flex'>
                <FontAwesomeIcon
                  color='#d3d3d3'
                  icon={faArrowLeft}
                  size='lg'
                  className='p-4 cursor-pointer'
                />
                <span className='items-center content-center'>
                  Go back to list
                </span>
              </h2>
            </div>
            <InvestorReportsInfo
              clientId={clientId}
              investorReportsData={investorReportsData}
              refreshData={refreshData}
            />
          </DashboardCard>
        </>
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReportsDetails;
