import React from 'react';
import { MenuOption } from '@/types/menu-options';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useLocation } from 'react-router-dom';

type SubmenuProps = {
  subOptions?: MenuOption[];
};

function SubMenu({ subOptions = [] }: SubmenuProps) {
  return (
    <ul className='w-[90%] block sticky'>
      {subOptions.map((option) => (
        <li key={option.label} className='mt-2' role='menuitem'>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <MainMenuItem {...option} />
        </li>
      ))}
    </ul>
  );
}

type MenuItemLinkProps = {
  isExternal?: boolean;
  url: string;
  label: string;
  action?: () => void;
  icon: IconProp;
  subOptions?: MenuOption[];
};

function MainMenuItem({
  isExternal,
  url,
  label,
  icon,
  action,
  subOptions = [],
}: MenuItemLinkProps) {
  const location = useLocation();
  if (isExternal) {
    return (
      <a
        href={url}
        className='w-full px-2 rounded'
        target='_blank'
        rel='noreferrer'
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size='lg' className='px-2' fixedWidth />
        )}
        {` ${label}`}
      </a>
    );
  }

  if (action) {
    if (subOptions?.length === 0) {
      return (
        <button
          type='button'
          className={`w-full px-2 rounded text-left ${
            location.pathname === url ? 'bg-[#00A089]' : ''
          }`}
          onClick={() => action()}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              size='lg'
              className='px-2'
              fixedWidth
            />
          )}
          {` ${label}`}
        </button>
      );
    }
    <>
      <button
        type='button'
        className={`w-full px-2 rounded  text-left ${
          location.pathname === url ? 'bg-[#00A089]' : ''
        }`}
        onClick={() => action()}
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size='lg' className='px-2' fixedWidth />
        )}
        {` ${label}`}
      </button>
      <SubMenu subOptions={subOptions} />
    </>;
  }
  if (subOptions?.length === 0) {
    return (
      <NavLink
        to={url}
        className={({ isActive: isNavLinkActive }) =>
          `w-full px-2 rounded ${isNavLinkActive ? 'bg-[#00A089]' : ''}`
        }
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size='lg' className='px-2' fixedWidth />
        )}
        {` ${label}`}
      </NavLink>
    );
  }
  return (
    <>
      <NavLink
        to={url}
        className={({ isActive: isNavLinkActive }) =>
          `w-full px-2 rounded ${isNavLinkActive ? 'bg-[#00A089]' : ''}`
        }
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size='lg' className='px-2' fixedWidth />
        )}
        {` ${label}`}
      </NavLink>
      <SubMenu subOptions={subOptions} />
    </>
  );
}

export default MainMenuItem;
