import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
  open: boolean;
  toggler: React.ReactElement | string;
  children: string | JSX.Element;
  showBottomSeparator: boolean;
}
export function Accordion({
  open,
  toggler,
  children,
  showBottomSeparator,
}: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(open);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className='flex direction-row w-full cursor-pointer'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='w-[90%]'>{toggler}</div>
        <div className='w-[10%] text-right'>
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            onClick={() => setIsOpen(!isOpen)}
            className={`text-base cursor-pointer transform duration-100 transform transition ease-linear h-[24px] ${
              isOpen ? '-rotate-90' : ''
            }`}
          />
        </div>
      </div>
      {isOpen && children}
      {showBottomSeparator && <hr className='mt-6 mb-2' />}
    </div>
  );
}
export default Accordion;
