// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectWithStringKeys = { [key: string]: any };

export function fetchWithTimeout(
  url: string,
  options: ObjectWithStringKeys = {}
) {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, options?.timeout || 50000);

  return fetch(url, {
    ...options,
    signal: controller.signal,
  })
    .then((res) => {
      if (
        res.status === 401 &&
        window.location.href !== `${window.location.origin}/logout`
      ) {
        window.location.replace(`${window.location.origin}/logout`);
      }
      clearTimeout(timeout);
      return res;
    })
    .catch((err: Error) => {
      clearTimeout(timeout);
      if (err.name === 'AbortError') {
        throw new Error('request timed out');
      }
      throw new Error(err.message || 'error fetching');
    });
}
