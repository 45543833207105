import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import DownloadLastUpdatedFile from '@/components/LastUpdatedAccountCard/DownloadLastUpdatedFile';
import { FinancialFiles, FileItem } from '../../../../shared/types/files';
import { getMostRecentMonth, getMostRecentYear } from './utils';

// eslint-disable-next-line import/prefer-default-export
export function LastFinancialFilesCard({
  files,
}: {
  files: { pdfFile?: FileItem; excelFile?: FileItem };
}) {
  const { pdfFile, excelFile }: FinancialFiles = (files ||
    {}) as FinancialFiles;

  const { filteredPdfFile, filteredExcelFile } = useMemo(() => {
    const maxYear = getMostRecentYear([pdfFile, excelFile]);
    const filteredPdfFileByYear =
      DateTime.fromISO(pdfFile?.created_at || '').year === maxYear
        ? pdfFile
        : undefined;
    const filteredExcelFileByYear =
      DateTime.fromISO(excelFile?.created_at || '').year === maxYear
        ? excelFile
        : undefined;

    const { monthValue } = getMostRecentMonth([
      filteredPdfFileByYear,
      filteredExcelFileByYear,
    ]);

    const filteredPdfFileByMonth =
      DateTime.fromISO(pdfFile?.created_at || '').minus({ month: 1 }).month -
        1 ===
      monthValue
        ? filteredPdfFileByYear
        : undefined;
    const filteredExcelFileByMonth =
      DateTime.fromISO(excelFile?.created_at || '').minus({ month: 1 }).month -
        1 ===
      monthValue
        ? filteredExcelFileByYear
        : undefined;

    return {
      filteredPdfFile: filteredPdfFileByMonth,
      filteredExcelFile: filteredExcelFileByMonth,
    };
  }, [pdfFile, excelFile]);

  const getTitle = (items: Array<FileItem | undefined>) => {
    return items ? `Download Recent Financials` : 'No financial file available';
  };

  return (
    <div className='space-x-4 items-stretch' data-testid='last-financial-files'>
      <div className='flex justify-between mb-4'>
        <span className='font-bold'>
          {getTitle([filteredPdfFile, filteredExcelFile])}
        </span>
        <Link to='/files'>
          <span className='underline'>Or see all files</span>
        </Link>
      </div>
      <div className='flex h-full justify-around mt-1 mb-1'>
        <DownloadLastUpdatedFile
          fileType='pdf'
          fileName={filteredPdfFile?.name as string}
          fileId={filteredPdfFile?.id as string}
          fileTeamId={filteredPdfFile?.teamId as string}
        />
        <DownloadLastUpdatedFile
          fileType='excel'
          fileName={filteredExcelFile?.name as string}
          fileId={filteredExcelFile?.id as string}
          fileTeamId={filteredExcelFile?.teamId as string}
        />
      </div>
    </div>
  );
}
