// eslint-disable-next-line import/prefer-default-export
export const getMicrosoftDialogUrl = (userId: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const tenantId = (import.meta.env || process.env)?.VITE_TENANT_ID;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const clientId = (import.meta.env || process.env)?.VITE_CLIENT_ID;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const state = (import.meta.env || process.env)?.VITE_STATE;
  return `https://login.microsoftonline.com/${encodeURIComponent(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    tenantId
  )}/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clientId
  )}&response_type=code&response_mode=form_post&scope=${encodeURIComponent(
    // eslint-disable-next-line max-len
    'Calendars.Read Calendars.ReadWrite Group.Read.All Group.ReadWrite.All User.Read profile openid email offline_access'
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  )}&state=${encodeURIComponent(`${state}|${userId}`)}`;
};
