import { Sort, TableSort } from '@/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface Props<T> {
  sort: Sort | TableSort<T>;
  fieldName: keyof T;
}
// eslint-disable-next-line import/prefer-default-export
export function SortArrow<T>({ sort, fieldName }: Props<T>) {
  return (
    <span className='ml-2'>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {sort.field !== fieldName && <></>}
      {sort.field === fieldName && sort.asc && (
        <FontAwesomeIcon icon={faChevronDown} />
      )}
      {sort.field === fieldName && !sort.asc && (
        <FontAwesomeIcon icon={faChevronUp} />
      )}
    </span>
  );
}
