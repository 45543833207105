import { useRollbar } from '@rollbar/react';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import type { Client, File, FileItem, FirmData, FirmClients } from '@types';
import { Button, Menu } from 'react-daisyui';
import { Spinner } from '@/components/Spinner';
import { IUserContext, useUserContext } from '@/context/UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckDouble,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { slugify } from '@/utils/string';
import { post, fetchDelete } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import useFileData from '@/hooks/useFileData';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { updateFilePermissions } from '../utils/utils';
import { UserValue } from '../modals/share/SharePermission';
import {
  AcceptPermission,
  GrantPermissionModal,
  SharePermission,
  FilePreviewModal,
} from '../modals';
import {
  DocIcon,
  FilePath,
  checkIfFileShouldBePreviewed,
} from '../utils/FileUtils';
import { isFileSearchItem } from '../types';
import { fetchUrl, processDownloadUrl } from '../../../utils/teamsFileDownload';
import useLogEvent from '../../../utils/logEvent';

interface IFileComponentProps {
  file: File;
  showLocation?: boolean;
  disabledClick?: boolean;
  currentFolderPath: string;
  currentFolderIdPath: string;
  firmClientsData?: FirmClients[];
  firmUserIds?: number[];
}
function FileComponent({
  file,
  showLocation = true,
  disabledClick,
  currentFolderPath,
  currentFolderIdPath,
  firmClientsData,
  firmUserIds,
}: IFileComponentProps) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext(); // Use the context
  const rollbar = useRollbar();
  const [fetching, setFetching] = useState(false);
  const [showGrantPermissionFolder, setShowGrantPermissionFolder] =
    useState<boolean>(false);
  const [showSharePermission, setShowSharePermission] =
    useState<boolean>(false);
  const [fileToPreview, setFileToPreview] = useState<boolean>(false);
  const [deleteFile, setDeleteFile] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserValue[]>([]);
  const { getFileData, refreshFileData } = useFileData();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const thirdPartyId = params.get('third-party') || null;
  const fileId = params.get('file-id') || null;
  if (fileId) {
    params.delete('third-party');
    params.delete('file-id');
    window.history.replaceState({}, '', decodeURI(window.location.pathname));
  }
  const [showAcceptFolderAccess, setShowAcceptFolderAccess] = useState(
    !!fileId
  );
  const showAcceptButton =
    auth?.isClient && !!thirdPartyId && !!fileId && fileId === file.id;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { logEvent } = useLogEvent();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const clientList: Client[] = useMemo(
    () => auth?.clients as Client[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  const processFileError = async () => {
    alertErrorMessage(
      'This file has been updated. Please wait a few minutes and refresh your page to access the latest version.'
    );
    await post('files/updateTeams', { async: true });
  };

  const obtainUrl = async () => {
    try {
      return await fetchUrl(file.id, file.teamId);
    } catch (err) {
      await processFileError();
      throw err;
    }
  };

  const getClientId = (teamId: string) =>
    clientList.find((c) => c.ms_team_id === teamId)?.id ||
    impersonatingAccountId;
  const clientId = getClientId(file.teamId) as number;

  const isInvestor = auth?.isInvestor;
  const getClientName = (teamId: string) =>
    clientList.find((c) => c.ms_team_id === teamId)?.client_name || '';

  const { data: rootFolder, isValidating: isLoadingFileData } =
    getFileData(clientId);

  const UserHasPermissions =
    (file.permissions?.filter(
      (p) =>
        p.fileId === file.id &&
        (p.allowedUsers.includes(auth?.id as number) ||
          (p.allowed_tags_by_user_id &&
            p.allowed_tags_by_user_id.includes(auth?.id as number)))
    )?.length ?? 0) > 0;
  const blockedForThirdParty = auth?.isInvestor && !UserHasPermissions;

  const downloadFile = () => {
    if (disabledClick || fetching) return;

    if (!blockedForThirdParty) {
      setFetching(true);

      obtainUrl()
        .then(async (url) => {
          await processDownloadUrl(url, file.name);
          await logEvent(file, clientId, auth?.role as string);
        })
        .catch((err) => rollbar.log(err as Error))
        .finally(() => setFetching(false));
    }
  };

  const openPreview = () => {
    setFileToPreview(true);
  };

  const handleClick = () => {
    if (checkIfFileShouldBePreviewed(file.name)) {
      openPreview();
    } else {
      downloadFile();
    }
  };

  const toggleAcceptAccessModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    setShowAcceptFolderAccess(!showAcceptFolderAccess);
  };

  const addUserPermissions = async (
    usersToAdd: UserValue[],
    usersToRemove: UserValue[],
    firmsToAdd: FirmData[],
    firmsToRemove: FirmData[]
  ) => {
    setSelectedUsers(usersToAdd);
    let error = false;
    try {
      const clientFolders = rootFolder?.files[0] as FileItem;
      await updateFilePermissions(
        usersToAdd,
        usersToRemove,
        firmsToAdd,
        firmsToRemove,
        file,
        clientFolders,
        clientId
      );
    } catch {
      error = true;
    } finally {
      setShowGrantPermissionFolder(false);
      if (usersToAdd.length > 0) {
        setShowSharePermission(true);
      }
    }
    if (!error) {
      alertMessageSuccess('Permission granted.');
    } else {
      alertErrorMessage('Something went wrong. Please try again.');
    }
    await refreshFileData();
  };

  const handleDeleteFile = async () => {
    try {
      setIsSaving(true);
      await fetchDelete(`/files/deleteFile/${file.teamId}/${file.id}`);
      await refreshFileData();
      alertMessageSuccess('File deleted successfully!');
      setDeleteFile(false);
      setIsSaving(false);
    } catch {
      alertErrorMessage('Something went wrong. Please try again later.');
      setDeleteFile(false);
      setIsSaving(false);
    }
  };

  const showShareOptions =
    auth?.isClient || (auth?.isAdmin && impersonatingAccountId);

  if (isLoadingFileData) {
    return <Spinner />;
  }

  return (
    <>
      <Menu.Item className='w-full flex justify-center'>
        <>
          {auth && fileToPreview ? (
            <FilePreviewModal
              isOpen={fileToPreview}
              fileItem={file}
              onClose={() => {
                setFileToPreview(false);
              }}
              onDownload={downloadFile}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onError={async () => processFileError()}
            />
          ) : null}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
          <a
            // eslint-disable-next-line max-len
            className={`flex items-start justify-start text-left w-full no-underline focus:outline-none hover:no-underline ${
              blockedForThirdParty || disabledClick
                ? 'pointer-events-none cursor-not-allowed h-12 uppercase font-semibold bg-transparent text-sm'
                : ''
            }`}
            data-testid='file-item'
            type='button'
            onClick={() => handleClick()}
            style={{
              lineHeight: '1em',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              height: '3rem',
              fontSize: '0.875rem',
            }}
          >
            <DocIcon fileName={file.name} isDisabled={blockedForThirdParty} />

            <div className='min-w-0 md:w-[75%]'>
              {/* eslint-disable-next-line max-len */}
              <div className='font-medium mb-1 w-32 sm:w-auto overflow-hidden  overflow-ellipsis sm:overflow-clip whitespace-nowrap'>
                {file.name}
              </div>
              {fetching ? (
                <Spinner />
              ) : (
                <div className='text-xs text-gray-500'>
                  {isInvestor
                    ? `${dayjs(file.updated_at).format(
                        'MMM D YYYY'
                      )} - ${getClientName(file.teamId)}`
                    : dayjs(file.updated_at).format('MMM D YYYY')}
                  {isFileSearchItem(file) && showLocation ? (
                    <FilePath path={file.path} />
                  ) : null}
                </div>
              )}
            </div>
            <div className='right-4 absolute flex justify-end w-1/2'>
              {!auth?.isInvestor ? (
                <Button
                  size='xs'
                  variant='outline'
                  className={`${blockedForThirdParty ? 'hidden' : ''}
          menu-hover btn-accent group cursor-pointer ml-2`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteFile(true);
                  }}
                >
                  <FontAwesomeIcon
                    data-testid='delete-file-button'
                    icon={faTrash}
                    size='lg'
                    className='text-accent'
                  />
                </Button>
              ) : null}
              {auth?.isClient && showAcceptButton ? (
                <div className='contents'>
                  <Button
                    onClick={(e) => {
                      toggleAcceptAccessModal(e);
                    }}
                    size='xs'
                    className='btn-accent ml-2'
                  >
                    <FontAwesomeIcon
                      data-testid='accept-permission-button'
                      icon={faCheckDouble}
                      size='lg'
                      title='Accept permissions request'
                    />
                  </Button>
                  <AcceptPermission
                    isOpen={showAcceptFolderAccess}
                    clientId={impersonatingAccountId || auth?.clients?.[0]?.id}
                    thirdPartyId={Number(thirdPartyId)}
                    onClose={() => {
                      setShowAcceptFolderAccess(false);
                    }}
                    fileItem={file}
                  />
                </div>
              ) : null}
              {showShareOptions ? (
                <div className='contents'>
                  <Button
                    size='xs'
                    data-testid='user-access-button'
                    variant='outline'
                    className={`${blockedForThirdParty ? 'hidden' : ''}
            menu-hover btn-accent group cursor-pointer ml-2`}
                    style={{
                      pointerEvents: 'all',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowGrantPermissionFolder(true);
                    }}
                  >
                    <FontAwesomeIcon
                      data-testid='grant-permissions-modal'
                      icon={faEye}
                      size='lg'
                      className='text-accent'
                    />
                  </Button>
                </div>
              ) : null}
            </div>
          </a>
        </>
      </Menu.Item>
      {showShareOptions ? (
        <>
          <GrantPermissionModal
            auth={auth}
            clientId={impersonatingAccountId || auth?.clients?.[0]?.id || 0}
            isOpen={showGrantPermissionFolder}
            onClose={() => {
              setShowGrantPermissionFolder(false);
            }}
            folderOrFile={file}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSave={addUserPermissions}
            firmClientsData={firmClientsData}
            firmUserIds={firmUserIds}
          />
          <SharePermission
            auth={auth}
            isOpen={showSharePermission}
            onClose={() => setShowSharePermission(false)}
            currentPath={`${currentFolderPath}/${slugify(file.name)}`}
            currentFolderIdPath={`${currentFolderIdPath}/${file.id}`}
            name={file.name}
            clientId={impersonatingAccountId || auth?.clients?.[0]?.id}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            itemType='file'
          />
        </>
      ) : null}
      {deleteFile ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={handleDeleteFile}
          onCloseAction={() => setDeleteFile(false)}
          showModal={!!deleteFile}
          modalMessage={`Are you sure you want to remove the file ${file.name}?`}
          isLoading={isSaving}
        />
      ) : null}
    </>
  );
}

export default FileComponent;
