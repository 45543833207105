/* eslint-disable react/destructuring-assignment */
import { User, Role } from '@types';
import { TableMenuActions } from '@/types';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Column } from '@/components/Table/Table';
import TableDropDown from '@/components/TableDropDown/TableDropDown';
import { RoleBadge } from './RoleBadge';

const getRoleDescription = (user: User) => {
  if (!user.enabled) {
    return `${user.role} - Archived` as Role;
  }
  if (user.role === 'investor') {
    return 'Third Party' as Role;
  }
  return user.role;
};

const getActionsFromRole = (user: User, actions: TableMenuActions) => {
  switch (user.role) {
    case 'client':
      return actions.clients;
    case 'admin':
      if (user.enabled) {
        return actions.admins;
      }
      return actions.disabledUsers;
    case 'investor':
      return actions.investors;
    case 'specialadmin':
    case 'superadmin':
      return actions.superAdmins;
    default:
      return [];
  }
};
export const handleRowClick = (row: User, actions: TableMenuActions) => {
  if (row.role !== 'client') {
    actions.admins[0].action(row);
  }
};

export const columns = (actions: TableMenuActions): Column<User>[] => [
  {
    header: 'First Name',
    accessor: 'first_name',
    sortable: true,
    Cell: (row: User) => (
      <div
        role='button'
        tabIndex={0}
        className={row.enabled ? '' : 'line-through text-gray-500'}
      >
        {row.first_name}
      </div>
    ),
  },
  {
    header: 'Last Name',
    accessor: 'last_name',
    sortable: true,
    Cell: (row: User) => (
      <div
        role='button'
        tabIndex={0}
        className={row.enabled ? '' : 'line-through text-gray-500'}
      >
        {row.last_name}
      </div>
    ),
  },
  {
    header: 'Email',
    accessor: 'email_address',
    sortable: true,
    Cell: (row: User) => (
      <div
        role='button'
        tabIndex={0}
        className={row.enabled ? '' : 'line-through text-gray-500'}
      >
        {row.email_address}
      </div>
    ),
  },
  {
    header: 'Role',
    accessor: 'role',
    sortable: true,
    Cell: (row: User) => (
      <div role='button' tabIndex={0} className='text-center'>
        <RoleBadge role={getRoleDescription(row)} />
      </div>
    ),
  },
  {
    header: '#Clients',
    accessor: 'clients',
    sortable: false,
    Cell: (row: User) => (
      <div
        role='button'
        tabIndex={0}
        className={
          row.enabled ? 'text-center' : 'text-center line-through text-gray-500'
        }
      >
        {row.role === 'admin' ? row.clients?.length : 'N/A'}
      </div>
    ),
  },
  {
    header: '',
    accessor: 'id',
    Cell: (row: User) =>
      !(row.role === 'client' && !row.hubspot_id) && !!row.role ? (
        <TableDropDown
          icon={faEllipsisV}
          options={getActionsFromRole(row, actions)}
          testId='edit-user-button'
          itemToPerformActionOn={row}
          id={row.id.toString()}
        />
      ) : null,
  },
];
