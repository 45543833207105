import { Button, Input } from 'react-daisyui';
import { useRollbar } from '@rollbar/react';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '@/components/Spinner';
import { post, get, put, fetchDelete } from '@/utils/queries';
import { get as getApi } from '@/utils/api';
import { TransactionAutoComplete } from '@/pages/Transactions/TransactionAutoComplete';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import '../../../../pages/Transactions/TableTransactions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  QBOCategory,
  QboCompanyWithCategoryName,
} from '@shared/types/qbo_data';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';

interface QBOAccessFormProps {
  isLoading: boolean;
  clientId: number;
}

export interface AskMyClientTokenData {
  realmId?: string;
  access_token?: string;
  refresh_token?: string;
  x_refresh_token_expires_in?: number;
}

interface QBOMessage {
  realmId: string;
  code: string;
}

function QBOAccessForm({ isLoading, clientId }: QBOAccessFormProps) {
  const rollbar = useRollbar();
  const [qboCategories, setQboCategories] = useState<QBOCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<QBOCategory | null>(
    null
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateSuccessfully, setUpdateSuccessfully] = useState<
    boolean | undefined
  >(undefined);
  const [newQboCompany, setNewQboCompany] = useState<boolean>(false);
  const [qboRealmId, setQboRealmId] = useState<string>('');
  const [qboCompanyName, setQboCompanyName] = useState<string>('');
  const [configSaved, setConfigSaved] = useState<boolean>(false);
  const [qboToken, setQboToken] = useState<AskMyClientTokenData | null>(null);
  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [companyToDelete, setCompanyToDelete] =
    useState<QboCompanyWithCategoryName | null>(null);

  let messageEventHandled = false;
  let interval: NodeJS.Timer | undefined;
  const baseAMCUrl = '/api/askmyclient';
  const {
    data: qboCompaniesData,
    isValidating: isValidatingCompanies,
    mutate: mutateCompanies,
  } = get<QboCompanyWithCategoryName[]>(
    `transactions/getClientCompanies/${clientId}`
  );

  const qboCompanies = useMemo(() => {
    return qboCompaniesData ?? [];
  }, [qboCompaniesData]);

  useEffect(() => {
    if (updateSuccessfully || updateSuccessfully === false) {
      setTimeout(() => setUpdateSuccessfully(undefined), 3000);
    }
  }, [updateSuccessfully]);

  const onCategoryChange = (category: QBOCategory | undefined) => {
    const choosenCategory = !isEmpty(category?.toString())
      ? category
      : undefined;
    if (choosenCategory) setSelectedCategory(choosenCategory);
  };

  const handleQBOTokenGenerationEvent = async (
    event: MessageEvent<QBOMessage>,
    popUpWindow: Window,
    isRefreshToken: boolean
  ) => {
    const { code, realmId } = event.data;

    if (code && realmId) {
      try {
        messageEventHandled = true;
        setQboRealmId(realmId);
        const tokenResponse = await axios.post(`${baseAMCUrl}/exchange/token`, {
          code,
          realmId,
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const tokenBody = tokenResponse.data;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        setQboToken(tokenBody.tokenObj as AskMyClientTokenData);
        const companyData = await axios.post(`${baseAMCUrl}/companyInfo`, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          tokenObj: tokenBody.tokenObj,
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const companyName = companyData.data.data.companyInfo
          .CompanyName as string;
        if (!isRefreshToken) {
          setQboCompanyName(companyName);
          const responseCategories = await axios.post(
            `${baseAMCUrl}/allCategories`,
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              tokenObj: tokenBody.tokenObj,
            }
          );
          const resultCategories =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (responseCategories.data.data as QBOCategory[]).map((cat) => {
              const newCat = {
                id: cat.id,
                external_id: cat.id,
                name: cat.name,
                type: cat.type,
              };
              return newCat as QBOCategory;
            });
          setQboCategories(resultCategories);
          setIsSaving(false);
          setNewQboCompany(true);
        } else {
          await put('transactions/qbo_new_token/', {
            clientId,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            token: tokenBody.tokenObj as AskMyClientTokenData,
            qboCompanyName: companyName,
          });
          await mutateCompanies();
          setIsSaving(false);
          alertMessageSuccess('Token refreshed.');
        }
      } catch (err) {
        setNewQboCompany(false);
        rollbar.error('QBOAccessForm.tsx:68 ~ handleQBOEvent', err as Error);
        setIsSaving(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('message', (newEvent) =>
        handleQBOTokenGenerationEvent(
          newEvent as MessageEvent<QBOMessage>,
          popUpWindow,
          isRefreshToken
        )
      );
      popUpWindow?.close();
    }
  };

  const checkRepeatedRealmId = () => {
    return (
      qboCompanies.find((comp) => comp.realm_id === qboRealmId) !== undefined
    );
  };

  const verifyPopUpClosed = (popUpWindow: Window) => {
    if (popUpWindow.closed && !messageEventHandled) {
      clearInterval(interval);
      setUpdateSuccessfully(false);
      setIsSaving(false);
    }

    if (popUpWindow.closed && messageEventHandled) {
      clearInterval(interval);
    }
  };

  const handleConfig = async (isRefreshToken: boolean, realmId?: string) => {
    setConfigSaved(false);
    setNewQboCompany(false);
    setIsSaving(true);
    setQboRealmId('');
    setQboCompanyName('');
    setQboToken(null);
    messageEventHandled = false;

    try {
      const url = realmId
        ? `${baseAMCUrl}/authuri/${realmId}`
        : `${baseAMCUrl}/authuri`;
      const response = await axios.get(url);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!response.data.error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { authUri } = response.data;
        const newWindowOptions =
          'width=700,height=550,resizable=yes,scrollbars=yes,menubar=no,location=no,status=no';
        const popUpWindow = window.open(
          authUri as string,
          '_blank',
          newWindowOptions
        );
        interval = setInterval(
          () => verifyPopUpClosed(popUpWindow as Window),
          500
        );

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.addEventListener('message', (event) =>
          handleQBOTokenGenerationEvent(
            event as MessageEvent<QBOMessage>,
            popUpWindow as Window,
            isRefreshToken
          )
        );
      } else {
        rollbar.error(
          'QBOAccessForm.tsx:92 ~ handleConfig',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          response.data.error as Error
        );
        alertErrorMessage(
          'Error while updating QBO configuration, try again later.'
        );
        setIsSaving(false);
      }
    } catch (err) {
      rollbar.error(
        'QBOAccessForm.tsx:97 ~ handleQBOTokenGenerationEvent',
        err as Error
      );
      alertErrorMessage(
        'Error while updating QBO configuration, try again later.'
      );
      setIsSaving(false);
    }
  };

  const handleRefresh = async (comp: QboCompanyWithCategoryName) => {
    await handleConfig(true, comp.realm_id);
  };

  const handleSaveNewConfig = async () => {
    if (selectedCategory && qboRealmId) {
      setIsSaving(true);
      try {
        await post('transactions/qbo_company_data/', {
          clientId,
          realmId: qboRealmId,
          categoryId: selectedCategory.external_id,
          token: qboToken,
          qboCompanyName,
        });
        alertMessageSuccess('QBO configuration updated successfully.');
        setConfigSaved(true);
        setNewQboCompany(false);
        await mutateCompanies();
        setIsSaving(false);
      } catch (err) {
        rollbar.error(
          'QBOAccessForm.tsx:110 ~ handleSaveNewConfig',
          err as Error
        );
        alertErrorMessage(
          'Error while updating QBO configuration, try again later.'
        );
        setIsSaving(false);
      }
    } else {
      alertErrorMessage(
        'Error while updating QBO configuration, try again later.'
      );
    }
  };

  const refreshQBOData = async () => {
    setRefreshInProgress(true);

    try {
      await getApi('transactions/syncQBO');
    } finally {
      setRefreshInProgress(false);
    }
  };

  const deleteQBOCompany = async () => {
    try {
      const companiesAfterDelete = (qboCompaniesData ?? []).filter(
        (comp) => comp.id !== companyToDelete?.id
      );
      await fetchDelete('transactions/qboCompany', {
        qboCompanyId: companyToDelete?.id,
        vendorAccessTokenId: companyToDelete?.vendor_access_token_id,
      });
      await mutateCompanies(companiesAfterDelete, {
        revalidate: false,
        optimisticData: companiesAfterDelete,
      });

      alertMessageSuccess('QBO configuration removed successfully.');
    } catch (err) {
      rollbar.error('QBOAccessForm.tsx:125 ~ deleteQBOCompany', err as Error);
      alertErrorMessage(
        'Error while removing QBO configuration, try again later.'
      );
    }

    setShowDeleteModal(false);
    setCompanyToDelete(null);
  };

  if (isValidatingCompanies) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  return (
    <div>
      <ToastContainer />

      <GenericConfirmationModal
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAcceptAction={deleteQBOCompany}
        onCloseAction={() => setShowDeleteModal(false)}
        showModal={showDeleteModal}
        modalMessage={`You are about to delete the QBO configuration for: ${
          companyToDelete?.name
            ? `${companyToDelete?.name}`
            : `${companyToDelete?.realm_id as string} - QBO Company`
        }, including all its categories and transactions. Are you sure you want to proceed?`}
      />

      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-transaction'
      >
        <Spinner className='spinner' />
      </div>
      <div className='flex my-4' data-testid='qbo-form'>
        <div className='w-1/2 flex items-center text-lg font-bold'>
          QBO Configuration
        </div>
        <div className='w-1/2 text-right'>
          <Button
            color='accent'
            size='sm'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => handleConfig(false)}
            data-testid='configure-button'
            disabled={newQboCompany}
          >
            <FontAwesomeIcon icon={faPlus} className='mr-1' />
            Add Qbo Company
          </Button>
        </div>
      </div>

      {qboCategories.length > 0 && newQboCompany ? (
        <>
          <div className='my-5 break-all flex'>
            <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
              Qbo Company Name
            </div>
            <div className='font-bold text-sm w-[58%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent text-gray-600 option-opacity'
                defaultValue={qboCompanyName}
                data-testid='input_qboCompanyName'
                disabled
              />
            </div>
          </div>
          <div className='my-5 break-all flex'>
            <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
              Realm Id
            </div>
            <div className='font-bold text-sm w-[58%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent text-gray-600 option-opacity'
                defaultValue={qboRealmId}
                disabled
              />
            </div>
          </div>
          <div
            className={`my-5 break-all flex ${
              updateSuccessfully ? 'input-disabled' : ''
            }`}
          >
            <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
              Category
            </div>
            <TransactionAutoComplete
              key={`autocomplete-${clientId}-new-company`}
              onOptionSelected={onCategoryChange}
              disabled={updateSuccessfully || configSaved}
              clientId={clientId}
              optionsCategories={qboCategories}
            />
          </div>
          <span
            className={`flex justify-center text-red-500 ${
              checkRepeatedRealmId() ? '' : 'hidden'
            }`}
          >
            There is already a company with the same realm id
          </span>
          <div
            className={`text-center pt-2 ${
              updateSuccessfully || configSaved ? 'hidden' : ''
            }`}
          >
            <Button
              color='accent'
              size='md'
              disabled={!selectedCategory || checkRepeatedRealmId() || isSaving}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSaveNewConfig}
              data-testid='save-button'
            >
              Save
              {isLoading && <Spinner className='mx-auto w-4 text-base ml-4' />}
            </Button>
          </div>
          {!isEmpty(qboCompanies) && qboCompanies.length > 0 ? (
            <>
              <br />
              <hr className='mx-36' />
            </>
          ) : null}
        </>
      ) : null}

      {!isEmpty(qboCompanies) && qboCompanies.length > 0 ? (
        <>
          {qboCompanies.map((comp, index) => (
            <div key={`data-${comp.id as number}`}>
              <div className='my-5 break-all flex'>
                <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
                  Qbo Company Name
                </div>
                <div className='font-bold text-sm w-[58%] text-left pl-3 flex'>
                  <Input
                    size='sm'
                    type='text'
                    className='border-accent text-gray-600 option-opacity'
                    defaultValue={comp.name}
                    disabled
                  />
                  <Button
                    color='accent'
                    size='sm'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() => handleRefresh(comp)}
                    className='ml-2 tooltip'
                    data-testid='refresh'
                    data-tip='Create a new access token'
                  >
                    <FontAwesomeIcon icon={faRefresh} className='mr-1' />
                    Regenerate Token
                  </Button>
                  <Button
                    color='error'
                    data-testid='disable-button'
                    variant='outline'
                    size='sm'
                    className='ml-1'
                    onClick={() => {
                      setShowDeleteModal(true);
                      setCompanyToDelete(comp);
                    }}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  />
                </div>
              </div>
              <div className='my-5 break-all flex'>
                <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
                  Realm Id
                </div>
                <div className='font-bold text-sm w-[58%] text-left pl-3'>
                  <Input
                    size='sm'
                    type='text'
                    className='border-accent text-gray-600 option-opacity'
                    defaultValue={comp.realm_id}
                    data-testid={`input_${comp.realm_id}_${index}`}
                    disabled
                  />
                </div>
              </div>
              <div className='my-5 break-all flex'>
                <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label data-testid={`label_${comp.client_amc_category_id}`}>
                    Category
                  </label>
                </div>
                <div className='font-bold text-sm w-[58%] text-left pl-3'>
                  <TransactionAutoComplete
                    key={`autocomplete-${comp.id as number}`}
                    value={comp.client_amc_category_name}
                    onOptionSelected={onCategoryChange}
                    disabled={updateSuccessfully || !isEmpty(qboCompanies)}
                    clientId={clientId}
                  />
                </div>
              </div>
              {index < qboCompanies.length - 1 ? (
                <hr className='mx-36' />
              ) : null}
            </div>
          ))}
          <div
            className={`my-5 break-all flex justify-center ${
              newQboCompany ? 'hidden' : ''
            }`}
          >
            <Button
              color='accent'
              id='refresh'
              size='sm'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => refreshQBOData()}
              data-testid='refresh-button'
            >
              <FontAwesomeIcon
                icon={faRefresh}
                size='lg'
                className={`mr-2 text-white ${
                  refreshInProgress ? 'spinning' : ''
                }`}
              />
              Refresh QBO Data
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default QBOAccessForm;
