// not possible to use an enum because of TS error "An enum member cannot have a numeric name."
// eslint-disable-next-line import/prefer-default-export
export const DealStage: { [key: string]: string } = {
  52748624: 'Churned (Sales Pipeline)',
  52381049: 'Closed won (Taxes)',
  52745120: 'Churned (Taxes)',
  26490234: 'New (Sales Pipeline)',
  26490235: 'No Decision (Sales Pipeline)',
  appointmentscheduled: 'Meeting scheduled (Sales Pipeline)',
  qualifiedtobuy: 'Qualified to buy (Sales Pipeline)',
  contractsent: 'Contract sent (Sales Pipeline)',
  closedwon: 'Active',
  closedlost: 'Closed lost (Sales Pipeline)',
  34468695: 'UpSold (Sales Pipeline)',
  45292405: 'DownSold (Sales Pipeline)',
  35139255: 'Inactive Prospect (Sales Pipeline)',
  40564822: 'Churned (Sales Pipeline)',
  43126566: 'New (Taxes Pipeline)',
  43126567: 'Meting Scheduled (Taxes Pipeline)',
  43126568: 'No Decision (Taxes Pipeline)',
  43126569: 'Qualified to buy (Taxes Pipeline)',
  43126570: 'Contract sent (Taxes Pipeline)',
  43126571: 'Closed won (Taxes Pipeline)',
  43126572: 'Closed lost (Taxes Pipeline)',
  43126592: 'Inactive Prospect (Taxes Pipeline)',
  43126593: 'Churned (Taxes Pipeline)',
};

export const MAX_SINGLE_FILE_SIZE = 1024 * 1024 * 4; // 20 mb

export const acceptedFileExtensions =
  '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf';
