import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { GenericTable } from '@/components/Table/Table';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils';
import {
  InvestorListProps,
  InvestorReportingTableData,
} from '@/pages/InvestorReporting/utils/investorReports';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { post } from '@/utils/queries';
import { InvestorReportDTO } from '@types';
import { alertErrorMessage, alertMessage } from '@/utils/alerts';
import {
  columnsForInvestorView,
  mapApiInvestorReportsDateToTableData,
} from './investorClientList.util';

function InvestorClientList({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
}: InvestorListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TableSort<InvestorReportingTableData>>({
    field: 'action' as keyof InvestorReportingTableData,
    asc: true,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] =
    useState<InvestorReportDTO | null>(null);

  const openNudgeModal = (row: InvestorReportingTableData) => {
    setSelectedReport(row);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedReport(null);
  };

  const sendReminder = async () => {
    if (selectedReport) {
      try {
        await post(`investorReports/nudge/client`, {
          investorReportId: selectedReport.id,
        });
        alertMessage('top-right', 'Reminder sent');
      } catch (error) {
        alertErrorMessage('Failed to send reminder');
      }
      closeModal();
      await mutate('investorReports');
    }
  };

  const sortBy = (fieldName: keyof InvestorReportingTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const sortedReports = useMemo(() => {
    return investorReports.filter((report) =>
      report.client?.client_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    );
  }, [investorReports, searchText]);

  const reportData = mapApiInvestorReportsDateToTableData(sortedReports);
  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData}
        columns={columnsForInvestorView(openNudgeModal)}
        sortBy={sortBy}
        sort={sort}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          navigate(`/i-reporting-details`, {
            state: {
              clientId: row.client_id,
              userId: row.user_id,
              firmId: row.firm_id,
              reportId: row.id,
            },
          });
        }}
      />
      <GenericConfirmationModal
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAcceptAction={sendReminder}
        onCloseAction={closeModal}
        showModal={isModalOpen}
        modalMessage='Send a reminder for the data request?'
      />
    </div>
  );
}

export default InvestorClientList;
