import type { EventLog } from '@types';
import { GenericTable } from '@/components/Table/Table';
import { TableSort } from '@/types';
import { apiFieldMapping } from '../../../../../../shared/utils/logs';
import {
  columns,
  mapApiEventLogsToEventLogsData,
  EventLogsTableData,
} from './eventsTable.util';

function EventsTable({
  records,
  sortBy,
  sort,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
}: {
  records: EventLog[];
  sortBy: (fieldName: keyof EventLogsTableData) => void;
  sort: TableSort<EventLogsTableData>;
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (page: number) => void;
}) {
  const logsData = mapApiEventLogsToEventLogsData(records);

  const handleSortBy = (fieldName: keyof EventLogsTableData) => {
    const apiField = apiFieldMapping[fieldName as string] || fieldName;
    sortBy(apiField as keyof EventLogsTableData);
  };

  return (
    <GenericTable
      className='w-full m-2'
      tableName='EventLogs'
      data={logsData}
      columns={columns}
      sortBy={handleSortBy}
      sort={sort}
      recordsPerPage={recordsPerPage}
      selectedPage={selectedPage}
      onPageSelect={onPageSelect}
      onSelectRowsChange={onSelectRowsChange}
    />
  );
}

export default EventsTable;
