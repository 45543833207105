export const isValidURL = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
export const isValidURLOrEmpty = (url: string): boolean =>
  (url !== '' && isValidURL(url)) || url === '';
export default isValidURL;
