import { memo } from 'react';
import { ToastContainer } from 'react-toastify';
import { DashboardCard } from '@/components/DashboardCard';
import type { FirmsWithUserDTO } from '@types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorAddUserCard } from '@/components/InvestorAddUserCard/InvestorAddUserCard';
import RenderFirms from '@/components/firmsAccordionList/renderFirms';
import { Spinner } from '@/components/Spinner';
import useFirms from '@/hooks/useFirms';

const AccountInvestorDetails: React.FC<{
  size?: 'sm' | 'md';
  test?: boolean;
}> = memo(({ size = 'md', test }: { size?: 'sm' | 'md'; test?: boolean }) => {
  const { auth }: IUserContext = useUserContext();
  const { useGetFirmsWithUsers } = useFirms();
  const { data: firms, isValidating } = useGetFirmsWithUsers();

  if (isValidating) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  return (
    <div className='flex flex-wrap w-full m-4'>
      <div className='w-1/2 p-2'>
        <DashboardCard>
          <h2 className='text-base uppercase font-bold'>Firms</h2>
          <ToastContainer />
          {!test ? (
            <div className='flex items-center space-x-4 mb-4'>
              {' '}
              {auth?.firms && auth?.firms.some((firm) => firm.primary) ? (
                <div key='investor-add-users' data-testid='investor-add-users'>
                  <InvestorAddUserCard />
                </div>
              ) : null}
            </div>
          ) : null}
          <RenderFirms
            firms={(firms as FirmsWithUserDTO[]) || []}
            companyName=''
            forInvestor
            showHubspotLink={false}
            size={size}
          />
        </DashboardCard>
      </div>
    </div>
  );
});

export { AccountInvestorDetails };
