import { useMemo } from 'react';
import { Alert, Menu } from 'react-daisyui';

import { Link } from 'react-router-dom';

import { DashboardCard, LoadingCard } from '@/components/DashboardCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Folder } from '@types';
import useFileData from '@/hooks/useFileData';
import { FolderOrFileComponent } from '../FileBrowser/components';
import { getMostRecentFiles } from './utils';
import { MAX_RECENT_FILES_FOR_INVESTORS } from './constants';

export default function InvestorFilesCard() {
  const { auth }: IUserContext = useUserContext(); // Use the context
  const { getFileData } = useFileData();
  const { data: files } = getFileData();

  const recentFiles = useMemo(() => {
    if (!files) return [];

    return getMostRecentFiles(files as Folder, MAX_RECENT_FILES_FOR_INVESTORS);
  }, [files]);

  if (!files || !auth) {
    return <LoadingCard />;
  }

  return (
    <DashboardCard data-testid='investor-recent-files-card'>
      <Link to='/files' className='flex items-center'>
        <FontAwesomeIcon
          icon={faFolderOpen}
          className='mr-5 text-accent'
          size='2x'
        />
        <h3 className='font-bold text-base'>Recent Files</h3>
      </Link>
      {recentFiles.length > 0 && (
        <Menu className='overflow-y-auto'>
          {recentFiles.map((file) => (
            <FolderOrFileComponent
              key={file.id}
              item={file}
              showLocation={false}
              currentFolderPath={file.linkPath}
            />
          ))}
        </Menu>
      )}
      {recentFiles.length === 0 && (
        <Alert
          className='rounded-2xl opacity-60 text-left w-full text-sm mt-4'
          icon={<ExclamationCircleIcon className='w-8 h-8' />}
          status='warning'
        >
          <div>There are no files changed recently.</div>
        </Alert>
      )}
    </DashboardCard>
  );
}
