import { fetcher } from '@/utils/queries';
import useSWR from 'swr';
import { FirmClients } from '../../../shared/types';

const useFirms = () => {
  const baseUrl = '/api/firms';

  const useGetFirmsClients = (clientId: number) => {
    return useSWR<FirmClients[], Error>(
      `${baseUrl}/clients/${clientId}`,
      fetcher
    );
  };

  const useGetFirmsWithUsers = () => {
    return useSWR<FirmClients[], Error>(`${baseUrl}/users`, fetcher);
  };

  return { useGetFirmsClients, useGetFirmsWithUsers };
};

export default useFirms;
