import { GenericModal } from '@/components/Modal';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { FirmsDTO, SimpleUserDto } from '@types';
import { useState, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import { Button, Input } from 'react-daisyui';
import Select, { MultiValue } from 'react-select';
import { patch, post } from '@/utils/api';
import { useRollbar } from '@rollbar/react';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { useForm } from 'react-hook-form';

interface NewUserFormData {
  first_name: string;
  last_name: string;
  email_address: string;
  job_title: string;
}

interface SelectOptions {
  value: number;
  label: string;
}

export default function AddInvestorModal({
  firm,
  isOpen,
  onClose,
}: {
  firm: FirmsDTO;
  isOpen: boolean;
  onClose: () => void;
}) {
  const rollbar = useRollbar();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email_address: '',
    } as NewUserFormData,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [optionsSelected, setOptionsSelected] = useState<
    MultiValue<SelectOptions>
  >([]);
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const { auth }: IUserContext = useUserContext(); // Use the context
  const users = useRef<SimpleUserDto[]>([]);

  const autocomplateOptions: SelectOptions[] = users.current.map((user) => ({
    value: user.id || 0,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const apiUsersByEmailCall = async (newQuery: string) => {
    try {
      const role = 'investor';
      const usersListAPIUrl = '/api/users/list';
      if (!newQuery) {
        return [];
      }
      const res = await fetcher<SimpleUserDto[]>(
        `${usersListAPIUrl}/${role}/${newQuery.toLowerCase()}`
      );
      return res;
    } catch {
      return [];
    }
  };

  const filterUsers = async (newQuery: string) => {
    users.current = await apiUsersByEmailCall(newQuery);
    const searchInputs: SelectOptions[] = users.current
      .filter(
        (user) =>
          user.id !== auth?.id &&
          (!user.firmsIds || !user.firmsIds.includes(firm.id))
      )
      .map((user) => ({
        value: user.id || 0,
        label: `${user.first_name} ${user.last_name}`,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterUsers(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  const onSaveExistingUser = async () => {
    const body = {
      usersIds: optionsSelected.map((option) => option.value),
      firmId: firm?.id,
    };
    try {
      await patch('users/firms', body);
      alertMessageSuccess('User/s added successfully');
      setOptionsSelected([]);
      setSearchMap([]);
      onClose();
    } catch (ex) {
      rollbar.error('AddInverstorModal.tsx ~ onSave', ex as Error);
      alertErrorMessage('Something went wrong. Please try again.');
    }
  };

  const onSaveNewUser = async (data: NewUserFormData) => {
    const body = {
      emailAddress: data.email_address,
      firstName: data.first_name,
      lastName: data.last_name,
      jobTitle: data.job_title,
      firmId: firm?.id,
    };
    try {
      await post('users/addToFirm', body);
      alertMessageSuccess('User/s created successfully');
      setOptionsSelected([]);
      setSearchMap([]);
      onClose();
    } catch (ex) {
      if ((ex as Error).message === 'User already exists') {
        alertErrorMessage('User already exists.');
      } else {
        rollbar.error('AddInverstorModal.tsx ~ onSave', ex as Error);
        alertErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} isFullWidth={false}>
      <h1 className='text-lg font-bold'>Select User</h1>
      <div className='text-sm text-gray-500'>
        Please be aware that any user you add will be granted the same access
        permissions as your account.
      </div>
      <br />
      <div>Search existing investors by email address:</div>
      <div data-testid='select-investor-add'>
        <Select
          isMulti
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          placeholder='Search users ...'
          closeMenuOnSelect={false}
          options={searchMap}
          inputValue={searchQuery}
          value={optionsSelected}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={handleSearchQueryChange}
          onChange={(value) => {
            setOptionsSelected(value);
            setSearchMap([]);
          }}
          filterOption={() => true} // Only filter querying the api
          onFocus={() => setSearchMap([])}
        />
      </div>
      <Button
        type='submit'
        title='login'
        color='accent'
        size='sm'
        className='btn btn-accent bg-transparentp-2 m-2 ml-0'
        data-testid='add-new-company-button'
        disabled={optionsSelected.length === 0}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => onSaveExistingUser()}
      >
        Add User
      </Button>
      <br />
      <br />
      <div className='flex justify-center items-center'>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
        <span className='px-2'>OR</span>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
      </div>
      <br />
      <div>Add a new Investor:</div>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSaveNewUser)}>
        <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
          <div className='capitalize w-[25%] text-sm text-right pr-3'>
            First Name
          </div>
          <div className='font-bold text-sm w-[70%] text-left pl-3'>
            <Input
              size='sm'
              type='text'
              className='border-accent w-[90%]'
              required
              data-testid='first_name'
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('first_name', {
                required: 'First Name is required',
              })}
            />
          </div>
        </div>
        <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
          <div className='capitalize w-[25%] text-sm text-right pr-3'>
            Last Name
          </div>
          <div className='font-bold text-sm w-[70%] text-left pl-3'>
            <Input
              size='sm'
              type='text'
              className='border-accent w-[90%]'
              required
              data-testid='last_name'
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('last_name', { required: 'Last Name is required' })}
            />
          </div>
        </div>
        <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
          <div className='capitalize w-[25%] text-sm text-right pr-3'>
            Email Address
          </div>
          <div className='font-bold text-sm w-[70%] text-left pl-3'>
            <Input
              size='sm'
              type='email'
              className='border-accent w-[90%]'
              required
              data-testid='email_address'
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('email_address', {
                required: 'Email Address is required',
              })}
            />
            {errors.last_name && <p>{errors.last_name.message}</p>}
          </div>
        </div>
        <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
          <div className='capitalize w-[25%] text-sm text-right pr-3'>
            Job Title
          </div>
          <div className='font-bold text-sm w-[70%] text-left pl-3'>
            <Input
              size='sm'
              type='text'
              className='border-accent w-[90%]'
              required
              data-testid='job_title'
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('job_title', { required: 'Job Title is required' })}
            />
          </div>
        </div>
        {/* <br /> */}
        <Button
          color='accent'
          size='sm'
          disabled={!isValid}
          type='submit'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          // onClick={() => onDisable()}
        >
          Add New User
          {/* {loading && <Spinner className='mx-auto w-4 text-base ml-4' />} */}
        </Button>
      </form>
    </GenericModal>
  );
}
