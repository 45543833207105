import useSWR from 'swr';

import { useEffect, useRef } from 'react';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { fetcher } from '@/utils/queries';
import moment, { DurationInputArg2 } from 'moment';
import { useLocation } from 'react-router-dom';
import { setErrorSignal } from '@/signals/errorSignal';
import useIsLoggedIn from '@/hooks/useIsLoggedIn';
import { isEmpty } from 'lodash';
import { AuthCookie } from '@/types';

const useQuery = () => new URLSearchParams(useLocation().search);

const useVerification = (queryString: string, addInvestor: boolean) => {
  const params = new URLSearchParams(queryString);
  const redirectUrl = params.get('redirectUrl') || '/';
  const isVerified = useRef<boolean>(false);
  const { data, error } = useSWR<AuthCookie, Error>(
    addInvestor
      ? `api/auth/AddInvestorAndVerify${queryString}`
      : `api/auth/verify${queryString}`,
    fetcher
  );
  const { setAuth, setError, setRequestPasswordChange }: IUserContext =
    useUserContext(); // Use the context
  const query = useQuery();
  const { getJwtUserData } = useIsLoggedIn(window.location.search);
  let changeUserAtempt = false;

  useEffect(() => {
    if (data && !error) {
      if (!data.user.hasPassword) {
        setRequestPasswordChange();
      }
      if (addInvestor)
        localStorage.setItem(
          'investorAdded',
          JSON.stringify({
            firstName: query.get('firstName') || '',
            lastName: query.get('lastName') || '',
            email: query.get('email') || '',
          })
        );
      const {
        token: tokenInLocalStorage,
        expiresIn: expiresInInLocalStorage,
        user: verifiedUser,
      } = getJwtUserData();

      const verified = Boolean(data !== null && !error);
      if (setAuth && verified) {
        if (
          !verifiedUser ||
          isEmpty(verifiedUser) ||
          data.user.id === verifiedUser.id
        ) {
          const expires = moment()
            .add(data.maxAge[0], data.maxAge[1] as DurationInputArg2)
            .toDate();
          setAuth(
            {
              ...data.user,
            },
            data.access_token,
            expires
          );
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          changeUserAtempt = true;
          setErrorSignal(
            'You are already logged in with another user account.'
          );
          setAuth(
            { ...verifiedUser },
            tokenInLocalStorage,
            expiresInInLocalStorage,
            true
          );
        }

        isVerified.current = true;
      }
    }
    if (error && setError) setError(error);
  }, [data, error, setAuth, setError, addInvestor, query]);

  return {
    isVerified: isVerified.current,
    redirectUrl: changeUserAtempt ? '/' : redirectUrl,
    error,
  };
};
export default useVerification;
