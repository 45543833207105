import {
  InformationCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { Input, InputGroup, Button, Alert } from 'react-daisyui';
import { useRollbar } from '@rollbar/react';

import { Logo } from '@/components/Logo';
import { Spinner } from '@/components/Spinner';
import PageContainer from '@/components/PageContainer';
import { BrandShadowBox } from '@/components/BrandShadowBox';
import { getDefaultRoute, login, loginWithPassword } from '@/utils/auth';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Auth, AuthCookie } from '@/types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import moment, { DurationInputArg2 } from 'moment';

const isLocal = (import.meta.env || process.env).VITE_ENV === 'local';

function DevLogin() {
  return (
    <a
      href='http://localhost:54324/monitor'
      className='block text-white underline text-xs mt-2'
    >
      (dev login email)
    </a>
  );
}

export default function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isMagicLink, setIsMagicLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const rollbar = useRollbar();
  const {
    auth,
    clearImpersonationSession,
    impersonatingAccountId,
    setAuth,
  }: IUserContext = useUserContext(); // Use the context
  const subject = 'Opstart - Your Magic Link';
  const type = 'default';

  const invalidUserMessage = 'No account with that email exists';
  const addInvestorErrorMessage =
    'Error while adding an investor. Please enter your email to log in';
  useEffect(() => {
    const errorType = searchParams.get('error') || '';
    switch (errorType) {
      case 'addInvestor':
        setAlertMessage(addInvestorErrorMessage);
        break;
      case '':
        break;
      default:
        setErrorMessage(invalidUserMessage);
        break;
    }
    if (clearImpersonationSession) clearImpersonationSession();
  }, [clearImpersonationSession, searchParams]);
  const submitMagicLink = async () => {
    try {
      rollbar.debug(`before requesting the magic link for this email ${email}`);

      const res = await login(
        email.toLowerCase(),
        subject,
        type,
        searchParams.get('redirectUrl') || undefined
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      if ((res as any).success) {
        rollbar.debug(`Magic link sent to  ${email}`);
        setAlertMessage('Check your email for a login link');
      } else {
        rollbar.error(`Error trying to send the magic link for user ${email}`, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          error: (res as any).error,
        });
        setErrorMessage(
          'An error occurred while trying to send the magic link. Please try again.'
        );
      }
    } catch (err) {
      setErrorMessage(invalidUserMessage);

      rollbar.error(`Error trying to send the magic link for user ${email}`, {
        error: err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitWithPassword = async () => {
    try {
      rollbar.debug(`before log in with password for email ${email}`);

      const resp = (await loginWithPassword(email, password)) as AuthCookie;
      if (resp) {
        if (setAuth) {
          const { maxAge } = resp;
          const expires = maxAge
            ? moment()
                .add(maxAge[0], maxAge[1] as DurationInputArg2)
                .toDate()
            : undefined;
          setAuth(resp?.user, resp?.access_token, expires);
        }
        setAlertMessage(
          'Your password has been verified. You will be redirected shortly.'
        );
        navigate('/');
      } else {
        rollbar.error(`Error trying to login user ${email}`);
        setErrorMessage(
          'An error occurred while trying to login. Please try again.'
        );
      }
    } catch (err) {
      let message =
        'An error occurred while trying to login, is your email/password correct? Please try again.';
      const messageFromError = (err as Error).message || '';
      if (messageFromError) {
        message = messageFromError;
      }
      rollbar.error(
        `Error trying to login user ${email} - Message: ${message}`
      );
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async (e: React.FormEvent, onlyMagicLink = false) => {
    e.preventDefault();
    setIsLoading(true);
    setAlertMessage('');
    setErrorMessage('');

    if (onlyMagicLink) {
      setPassword('');
      setIsMagicLink(true);
      await submitMagicLink();
    } else {
      await submitWithPassword();
    }

    setIsMagicLink(false);
  };

  const showAlert = !!(alertMessage || errorMessage || (auth as Auth)?.message);

  if (auth && !showAlert) {
    return (
      <Navigate
        to={getDefaultRoute(auth as Auth, Boolean(impersonatingAccountId))}
        replace
      />
    );
  }

  return (
    <PageContainer name='login'>
      <div className='h-full flex flex-col items-center pt-40 mx-auto'>
        <BrandShadowBox className='flex flex-col items-center'>
          <Logo
            className='text-2xl ml-4 items-center w-[160px]'
            iconClassName='w-[41px] h-[41px]'
          />
          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={submitHandler}
            className='w-[80%] flex flex-col justify-center'
          >
            <div className='w-full mt-4'>
              <InputGroup
                className={`${alertMessage && !errorMessage ? 'hidden' : ''}`}
              >
                <Input
                  type='email'
                  placeholder='login email'
                  className='w-full'
                  color='accent'
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </InputGroup>
            </div>
            <div className='w-full mt-4'>
              <InputGroup
                className={`${alertMessage && !errorMessage ? 'hidden' : ''}`}
              >
                <Input
                  type='password'
                  placeholder='password'
                  className='w-full'
                  color='accent'
                  required
                  data-testid='password-input'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading || isMagicLink}
                />
              </InputGroup>
            </div>

            {!alertMessage && (
              <div className='mt-4 text-center'>
                <Button
                  type='submit'
                  title='login'
                  color='accent'
                  className='btn btn-accent'
                  data-testid='login-button'
                >
                  Login
                  {!isLoading &&
                    (!alertMessage ? (
                      <ArrowRightCircleIcon className='w-6 h-6 ml-2' />
                    ) : (
                      <CheckCircleIcon className='w-6 h-6 ml-2' />
                    ))}
                  {isLoading && <Spinner className='w-6 h-6 ml-2' />}
                </Button>
              </div>
            )}
            <br />
          </form>
          {/* className={`${alertMessage && !errorMessage ? 'hidden' : ''}`} */}

          <div
            className={`${
              alertMessage && !errorMessage ? 'hidden' : 'w-[80%]'
            }`}
          >
            <div className='flex justify-center items-center'>
              <div style={{ borderBottom: '1px solid white', width: '50%' }} />
              <span className='px-2'>OR</span>
              <div style={{ borderBottom: '1px solid white', width: '50%' }} />
            </div>
            <br />
            <div className='flex justify-center'>
              <Button
                type='button'
                title='login'
                color='accent'
                className='btn btn-accent'
                data-testid='set-login-magiclink'
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  submitHandler(e, true);
                }}
              >
                Send an Email link
                {isLoading && <Spinner className='w-6 h-6 ml-2' />}
              </Button>
            </div>
          </div>
          <Alert
            // eslint-disable-next-line max-len
            className={`rounded-2xl opacity-60 my-10 text-sm text-center w-[80%] transition-opacity duration-500 mt-2 ${
              showAlert ? 'opacity-100' : 'opacity-0 hidden'
            }`}
            icon={<InformationCircleIcon className='w-8 h-8' />}
            status={`${errorMessage ? 'error' : 'success'}`}
          >
            <div data-testid='alert-info-message'>
              {errorMessage ||
                alertMessage ||
                (auth as unknown as Auth)?.message}
              {isLocal && !errorMessage && alertMessage && <DevLogin />}
            </div>
          </Alert>
        </BrandShadowBox>
      </div>
    </PageContainer>
  );
}

export { Login };
