import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { ToastContainer } from 'react-toastify';
import type { File } from '@types';
import { fetchPreviewUrl } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { LoadingCard } from '@/components/DashboardCard';

interface Props {
  isOpen: boolean;
  fileItem: File;
  onClose: () => void;
  onDownload: () => void;
  onError: () => void;
}

export default function FilePreviewModal({
  isOpen,
  fileItem,
  onClose,
  onDownload,
  onError,
}: Props) {
  const {
    data: previewUrl,
    isLoading: isLoadingFile,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    error,
  } = fetchPreviewUrl(fileItem.id, fileItem.teamId);

  if (error) {
    onError();
    onClose();
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} isFullWidth isFullHeight>
      {isLoadingFile ? (
        <LoadingCard />
      ) : (
        <>
          <div className='pt-1 pb-1 cursor-pointer z-10 absolute top-2'>
            <Button
              color='accent'
              id='upload'
              data-testid='download-preview-button'
              size='sm'
              onClick={onDownload}
            >
              <FontAwesomeIcon
                icon={faDownload}
                size='lg'
                className='mr-2 text-white'
              />
              Download File
            </Button>
          </div>
          <br />
          <ToastContainer />
          <div className='flex flex-col'>
            <div className='flex items-center'>
              <div className='w-full h-full'>
                {!error && previewUrl ? (
                  <iframe
                    src={`${previewUrl?.getUrl}&action=embedview&nb=true`}
                    id={fileItem.id}
                    className='w-[86vw] h-[85vh] max-h-[85vh] bg-transparent border-none'
                    title={fileItem.name}
                  />
                ) : null}
              </div>
              <div id='Blocker' />
            </div>
          </div>
        </>
      )}
    </GenericModal>
  );
}
