import { GenericModal } from '@/components/Modal';
import { get, post } from '@/utils/queries';
import { EmptyState } from '@/components/EmptyState';
import { Button, Alert, Textarea } from 'react-daisyui';
import { useState, useMemo, useRef } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useRollbar } from '@rollbar/react';
import { User } from '@types';
import { Spinner } from '@/components/Spinner';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import type { Client, FileItem, JWTUser } from '@types';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

interface UserValue {
  value: number;
  label: string;
}

interface Props {
  auth: JWTUser;
  clientId: number;
  isOpen: boolean;
  fileItem?: FileItem;
  filePath?: string;
  onClose: () => void;
}

export default function RequestPermission({
  auth,
  clientId,
  isOpen,
  fileItem,
  filePath,
  onClose,
}: Props) {
  const rollbar = useRollbar();
  const [selectedUsers, setSelectedUsers] = useState<UserValue[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const selectRef = useRef(null);
  const pathParams = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: company, isLoading: isLoadingClient } = get(
    `account/${clientId}`
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: userData, isValidating: isLoading } = get(
    `users/list/${clientId}`
  );
  const path = filePath || pathParams['*'] || '';
  const pathStack = useMemo(() => {
    const params = pathParams['*'] || filePath;
    const parts = _.split(params, '/');
    return _.drop(parts, 2).join('/') || '-';
  }, [pathParams, filePath]);

  const [content, setContent] = useState('');
  useMemo(
    () => {
      let message = '';
      if (fileItem) {
        message =
          `I'd like to access ${fileItem.name} (${pathStack} folder) through the OpStart Portal. \n` +
          `Please click the link below to review the file and grant access, or email ${
            (company as Client)?.hubspot_data?.group_email || ''
          }` +
          ` if you need assistance managing user access permissions.`;
      } else {
        message =
          `I’d like to access the financial files for ${
            !isLoadingClient ? (company as Client).client_name : ' - '
          }. \n` +
          `Please click the link below to review the portal and grant access, or email ${
            (company as Client)?.hubspot_data?.group_email || ''
          }` +
          ` if you need assistance managing user access permissions.`;
      }
      setContent(message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(company as Client)?.hubspot_data?.group_email, fileItem, pathStack]
  );

  const title = fileItem
    ? 'This file has not been shared with you yet'
    : 'No files have been shared with you yet';

  const primaryUser = useMemo(() => {
    if (userData) {
      const primary = (userData as User[]).find((user: User) => user.primary);
      if (primary) {
        setSelectedUsers([{ value: primary.id, label: primary.email_address }]);
      }
      return primary;
    }
    return undefined;
  }, [userData]);

  const options = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      (userData as User[] | undefined)
        ?.filter((user: User) => user.role === 'client')
        .map((user: User) => ({
          value: user.id,
          label: user.email_address,
        })),
    [userData]
  );

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.target.value);
  };

  const onSave = async () => {
    if (selectedUsers.length === 0) {
      setSubmitAttempted(true);
    }

    setIsSaving(true);
    let subject = '';
    let redirectUrl = '';
    if (fileItem) {
      const fileOrFolder = fileItem.type === 'folder' ? 'folder' : 'file';
      subject = `${auth.first_name} ${auth.last_name} has requested access to a ${fileOrFolder}`;
      const encodedUri = encodeURIComponent(
        `?third-party=${auth.id}&file-id=${fileItem.id}`
      );
      redirectUrl = `${window.location.origin}/files/${path}${encodedUri}`;
    } else {
      subject = `${auth.first_name} ${auth.last_name} has requested access to financial files`;
      redirectUrl = encodeURIComponent(
        `${window.location.origin}/account/${clientId}/${auth.id}?edit-permissions-modal=open&client-user=true`
      );
    }
    const type = 'requestPermissions';

    const invalidUserMessage = 'Invalid User';
    let error = false;
    try {
      await Promise.all(
        selectedUsers.map(async (user) => {
          if (!error) {
            // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-unsafe-assignment
            const res = await post('/files/requestAccess', {
              destination: user.label.toLowerCase(),
              emailSubject: subject,
              linkType: type,
              redirectUrl,
              mainContent: content,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            if (res.success) {
              rollbar.debug(`Magic link sent to  ${user.label.toLowerCase()}`);
              alertMessageSuccess('Link Sent Successfully');
            } else {
              rollbar.error(
                `RequestPermission.tsx - onSave - Error trying to send the magic 
                link for user ${user.label.toLowerCase()}`,
                {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
                  error: res.error,
                }
              );
              alertErrorMessage('Error sending task reminder');
              error = true;
            }
          }
        })
      );
      setIsSaving(false);
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);
      setIsSaving(false);
    }
  };

  if (isLoading || isLoadingClient) {
    return <Spinner />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!userData.length) {
    return <EmptyState name='clients' />;
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} isFullWidth>
      <>
        <ToastContainer />
        <div className='flex h-full flex-col'>
          <div className='flex items-center'>
            <div className='w-full'>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              {!primaryUser ? (
                <>
                  <div className='font-bold text-base'>Select Users:</div>
                  <br />
                  <div className='font-bold text-sm text-left pl-3'>
                    <Select
                      id='selectuser'
                      isMulti
                      className='react-select-container border-accent'
                      classNamePrefix='react-select'
                      placeholder='select clients ...'
                      closeMenuOnSelect={false}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      options={options}
                      value={selectedUsers}
                      ref={selectRef}
                      onChange={(value) =>
                        setSelectedUsers(value as UserValue[])
                      }
                    />
                    {submitAttempted && !selectedUsers?.length && (
                      <Alert
                        icon={<ExclamationTriangleIcon className='w-5 h-5' />}
                        className='my-2 text-sm'
                      >
                        Please select at least one user.
                      </Alert>
                    )}
                  </div>
                  <br />
                </>
              ) : null}
              <div className='font-bold text-base'>
                {/* Edit the message we will send to the user(s): */}
                {title}
              </div>
              <div className='text-sm'>
                Send the company a message (below) to request access
              </div>
              <Textarea
                id='message-founders'
                className='w-full input-accent p-2 rounded-md mt-4 text-left'
                rows={15}
                cols={20}
                placeholder='Content'
                value={content}
                onChange={handleContentChange}
              />
            </div>
          </div>
          <br />
          <br />
          <div className='flex justify-center'>
            <Button
              type='submit'
              title='confirm'
              color='accent'
              size='sm'
              className='btn btn-accent'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSave}
              disabled={!selectedUsers?.length}
            >
              Send
              {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
            </Button>
          </div>
        </div>
      </>
    </GenericModal>
  );
}
