import { prettifyKey } from '@/utils';
import { Input } from 'react-daisyui';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

interface Map {
  [key: string]: { message: string };
}

interface Props {
  label: string;
  value: string | number;
  errors: FieldErrorsImpl<object>;
  register: UseFormRegister<FieldValues>;
}
function FormRow({ label, value, errors, register }: Props) {
  return (
    <div className='my-5 break-all flex'>
      <div className='capitalize w-[38%] text-sm text-right pr-3 pt-2'>
        {prettifyKey(label)}
      </div>
      <div className='font-bold text-sm w-[58%] text-left pl-3'>
        <Input
          size='sm'
          type='text'
          className='border-accent'
          defaultValue={value}
          data-testid={`input_${label}`}
          // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-use-before-define
          {...register(label as never, {
            required: 'This field is required',
          })}
        />
        {(errors as Map)[label] && (
          <div className='text-red-600 font-normal mt-2'>
            {(errors as Map)[label]?.message}
          </div>
        )}
      </div>
    </div>
  );
}

export default FormRow;
