import { useCallback, useEffect, useRef, useState } from 'react';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client, FirmsSimpleDTO } from '@types';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import Select, { SingleValue } from 'react-select';
import { useForm } from 'react-hook-form';
import { Input } from 'react-daisyui';

export interface NewFirmFormData {
  firm_name: string;
  domain_name: string;
  first_name: string;
  last_name: string;
  email_address: string;
  job_title: string;
}

interface SelectOptions {
  id: number;
  label: string;
  firm_name?: string;
}

interface SelectFirmStepProps extends GenericWizardSteps {
  company: Client;
  disableNext: (value: boolean) => void;
  setExistingFirm: (value: FirmsSimpleDTO | undefined) => void;
  setNewFirmData: (value: NewFirmFormData | undefined) => void;
}

export default function SelectFirmStep({
  company,
  disableNext,
  setExistingFirm,
  setNewFirmData,
}: SelectFirmStepProps) {
  const { auth }: IUserContext = useUserContext();
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firm_name: '',
      domain_name: '',
      first_name: '',
      last_name: '',
      email_address: '',
    } as NewFirmFormData,
  });
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFirm, setSelectedFirm] = useState<
    SingleValue<SelectOptions> | undefined
  >();
  const firms = useRef<FirmsSimpleDTO[]>([]);
  const formData = watch();
  const prevValues = useRef(formData);

  useEffect(() => {
    disableNext(!selectedFirm && !isValid);
    if (isValid) {
      if (JSON.stringify(prevValues.current) !== JSON.stringify(formData)) {
        setNewFirmData(formData);
        prevValues.current = formData; // Actualiza los valores previos
      }
    } else {
      setNewFirmData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFirm, isValid, formData]);

  const autocomplateOptions: SelectOptions[] = firms.current.map((firm) => ({
    id: firm.id || 0,
    label: firm.firm_name,
    firm_name: firm.firm_name,
  }));

  const apiFirmsByName = async (newQuery: string) => {
    try {
      const firmsListAPIUrl = '/api/firms';
      if (!newQuery) {
        return [];
      }
      const res = await fetcher<FirmsSimpleDTO[]>(
        `${firmsListAPIUrl}/${newQuery.toLowerCase()}`
      );
      return res;
    } catch {
      return [];
    }
  };

  const filterFirms = async (newQuery: string) => {
    firms.current = await apiFirmsByName(newQuery);
    const searchInputs: SelectOptions[] = firms.current
      .filter(
        (firm) =>
          firm.id !== auth?.id && !firm.clientsIds?.includes(company?.id || 0)
      )
      .map((firm) => ({
        id: firm.id || 0,
        label: firm.firm_name,
        firm_name: firm.firm_name,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterFirms(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  const validateDomain = (value: string) => {
    const domainPattern = /^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return (
      domainPattern.test(value) ||
      "Please enter a valid domain without the protocol (e.g., 'example.com')"
    );
  };

  const validateEmail = (value: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(value) || 'Please enter a valid email.';
  };

  return (
    <div title='Select Firm'>
      <h1 className='text-lg font-bold'>Select Firm</h1>
      <br />
      <div>Search existing firms:</div>
      <div data-testid='select-firm-add'>
        <Select
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          closeMenuOnSelect
          isClearable
          options={searchMap}
          inputValue={searchQuery}
          value={selectedFirm}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={handleSearchQueryChange}
          onChange={(value) => {
            setSelectedFirm(value);
            if (value) {
              setExistingFirm({
                id: value.id,
                firm_name: value.firm_name,
              } as FirmsSimpleDTO);
            } else {
              setExistingFirm(undefined);
            }
            setSearchMap([]);
          }}
          filterOption={() => true} // Only filter querying the api
          onFocus={() => setSearchMap([])}
        />
        <br />
        <div className='flex justify-center items-center'>
          <div style={{ borderBottom: '1px solid white', width: '50%' }} />
          <span className='px-2'>OR</span>
          <div style={{ borderBottom: '1px solid white', width: '50%' }} />
        </div>
        <br />
        <div>Add a new Investor:</div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              Firm Name
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent w-[90%]'
                required
                data-testid='firm_name'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('firm_name', {
                  required: 'Firm Name is required',
                })}
                disabled={!!selectedFirm}
              />
              {errors.firm_name && (
                <p className='text-red-600'>{errors.firm_name.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              Domain Name
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent w-[90%]'
                required
                data-testid='domain_name'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('domain_name', {
                  required: 'Domain Name is required',
                  validate: validateDomain,
                })}
                disabled={!!selectedFirm}
              />
              {errors.domain_name && (
                <p className='text-red-600'>{errors.domain_name.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              First Name
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent w-[90%]'
                required
                data-testid='first_name'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('first_name', {
                  required: 'First Name is required',
                })}
                disabled={!!selectedFirm}
              />
              {errors.first_name && (
                <p className='text-red-600'>{errors.first_name.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              Last Name
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent w-[90%]'
                required
                data-testid='last_name'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('last_name', {
                  required: 'Last Name is required',
                })}
                disabled={!!selectedFirm}
              />
              {errors.last_name && (
                <p className='text-red-600'>{errors.last_name.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              Email Address
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='email'
                className='border-accent w-[90%]'
                required
                data-testid='email_address'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email_address', {
                  required: 'Email Address is required',
                  validate: validateEmail,
                })}
                disabled={!!selectedFirm}
              />
              {errors.email_address && (
                <p className='text-red-600'>{errors.email_address.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 pb-2 mt-4 items-center'>
            <div className='capitalize w-[25%] text-sm text-right pr-3'>
              Job Title
            </div>
            <div className='font-bold text-sm w-[70%] text-left pl-3'>
              <Input
                size='sm'
                type='text'
                className='border-accent w-[90%]'
                required
                data-testid='job_title'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('job_title', {
                  required: 'Job Title is required',
                })}
                disabled={!!selectedFirm}
              />
              {errors.job_title && (
                <p className='text-red-600'>{errors.job_title.message}</p>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
