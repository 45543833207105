import { Bars3Icon } from '@heroicons/react/24/outline';
import { Button, Dropdown } from 'react-daisyui';
import NavItems from '@/components/Nav/NavItems';

function MobileMenu() {
  return (
    <Dropdown>
      <Button color='ghost' tabIndex={0} className='ml-2 md:hidden'>
        <Bars3Icon className='w-6 h-6' />
      </Button>
      <Dropdown.Menu tabIndex={0} className='w-52 menu-compact mt-3'>
        <NavItems isMobile />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MobileMenu;
