import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';

function GenericConfirmationModal({
  onAcceptAction,
  onCloseAction,
  showModal,
  modalMessage,
  children,
  isFullWidth,
  isLoading,
}: // eslint-disable-next-line  @typescript-eslint/no-unused-vars
{
  onAcceptAction: () => void;
  onCloseAction: () => void;
  showModal: boolean;
  modalMessage: string;
  children?: React.ReactNode;
  isFullWidth?: boolean;
  isLoading?: boolean;
}) {
  return (
    <GenericModal
      isOpen={showModal}
      onClose={onCloseAction}
      isFullWidth={!!isFullWidth}
    >
      {modalMessage}
      {children ? <div className='mt-8 flex'>{children}</div> : null}
      <div className='mt-8 flex justify-end'>
        <Button
          color='accent'
          variant='outline'
          size='sm'
          onClick={onCloseAction}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color='accent'
          className='ml-2'
          size='sm'
          onClick={onAcceptAction}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </div>
    </GenericModal>
  );
}

export default GenericConfirmationModal;
