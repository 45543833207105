import { TableSort } from '@/types';
import { QboInvoices, HubspotPlan } from '@types';
import { GenericTable } from '@/components/Table/Table';
import { columns, mapApiInvoicesToTableARData, TableARData } from './util';

interface TableARProps {
  invoices: QboInvoices[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
  sort: TableSort<TableARData>;
  sortBy: (field: keyof TableARData) => void;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (recordsPerPage: number) => void;
  handleController: (plan: HubspotPlan | undefined) => string | undefined;
}

export function TableAR({
  invoices,
  sort,
  sortBy,
  selectedPage,
  setSelectedPage,
  recordsPerPage,
  setRecordsPerPage,
  handleController,
}: TableARProps) {
  const tableData = mapApiInvoicesToTableARData(invoices, handleController);

  return (
    <GenericTable
      className='w-full m-2'
      tableName='AR'
      data={tableData}
      columns={columns}
      sortBy={sortBy}
      sort={sort}
      recordsPerPage={recordsPerPage}
      selectedPage={selectedPage}
      onPageSelect={setSelectedPage}
      onSelectRowsChange={setRecordsPerPage}
    />
  );
}
