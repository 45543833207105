import { DocumentIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faFileExcel,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';

export function FilePath({ path }: { path: string }) {
  return (
    <span className='text-xs text-gray-500 ml-2 break-words '>{path}</span>
  );
}
export function DocIcon({
  fileName,
  isDisabled,
}: {
  fileName: string;
  isDisabled?: boolean;
}) {
  const iconClasses = `w-6 mr-2 flex-shrink-0 ${
    isDisabled ? 'opacity-50' : ''
  }`;
  const extension = /(?:\.([^.]+))?$/.exec(fileName)?.[1]?.replace(/x$/i, '');

  if (!extension) {
    return (
      <DocumentIcon
        className={`${iconClasses} text-accent`}
        data-testid='fallback-icon'
      />
    );
  }

  const ExtMap: Record<string, React.ReactElement> = {
    doc: <DocumentTextIcon className={`${iconClasses} text-accent`} />,
    pdf: (
      <FontAwesomeIcon
        icon={faFilePdf}
        size='xl'
        color='#DA251D'
        className={iconClasses}
      />
    ),
    xls: (
      <FontAwesomeIcon
        icon={faFileExcel}
        data-testid='fa-file-xls-icon'
        size='xl'
        color='#339966'
        className={iconClasses}
      />
    ),
    csv: (
      <FontAwesomeIcon
        icon={faFileCsv}
        size='xl'
        className={`${iconClasses} text-accent`}
      />
    ),
  };

  return (
    ExtMap[extension] ?? (
      <DocumentIcon className={`${iconClasses} text-accent`} />
    )
  );
}

const downloadOnlyExtensions = ['csv', 'txt'];

export const checkIfFileShouldBePreviewed = (fileName: string) => {
  const match = fileName.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
  const extension = match ? match[1] : null;
  return !downloadOnlyExtensions.includes(extension || '');
};
