import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-daisyui';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Folder, Client, RootFolder } from '@types';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { post } from '@/utils/api';
import { DashboardCard } from '@/components/DashboardCard';
import { FileBrowser } from '@/components/FileBrowser';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { get } from '@/utils/queries';
import { alertErrorMessage, alertMessage } from '@/utils/alerts';
import useFileData from '../../hooks/useFileData';

// eslint-disable-next-line import/prefer-default-export
export function Files() {
  const { auth, impersonatingAccountId, companyName }: IUserContext =
    useUserContext(); // Use the context
  const { getFileData } = useFileData();
  const {
    data: rootFolder,
    isValidating: isLoading,
    error,
  } = impersonatingAccountId
    ? getFileData(impersonatingAccountId)
    : getFileData();

  const { data: companyData } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const [updatedRootFolder, setUpdateRootFolder] = useState<RootFolder>();

  const [updatingInProgress, setUpdatingInProgress] = useState<boolean>(false);

  if (error) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  const props = {
    ...(companyName && { companyName }),
    ...(companyData && { companyData }),
  };

  return (
    <PageContainer name='files'>
      <DashboardWrapper {...props}>
        <ToastContainer />
        <div className='p-4'>
          <div className='flex w-full justify-between items-center mb-4'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faCloud}
                size='lg'
                className='mr-4 text-white'
              />
              {!auth?.isAdmin || impersonatingAccountId
                ? 'Shared Files'
                : 'All External Files'}
            </h1>
            {auth?.isAdmin ? (
              <Button
                color='accent'
                id='refresh'
                size='sm'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  setUpdatingInProgress(true);
                  alertMessage(
                    'bottom-center',
                    'Syncing in progress. Please wait a few minutes and then refresh the page.'
                  );
                  try {
                    const result = await post<RootFolder>(
                      'files/updateTeams',
                      {
                        async:
                          auth?.isSuperAdmin ||
                          (!auth?.isSuperAdmin && !auth?.clients?.length)
                            ? true
                            : '',
                      },
                      {
                        timeout:
                          auth?.isSuperAdmin ||
                          (!auth?.isSuperAdmin && !auth?.clients?.length)
                            ? 20000000
                            : 60000,
                      }
                    );
                    setUpdateRootFolder(result);
                  } catch (errorAPI) {
                    if ((errorAPI as Error).message === 'request timed out') {
                      alertErrorMessage(
                        'The sync did not finish in time, please refresh your page and try again.'
                      );
                    }
                  } finally {
                    setUpdatingInProgress(false);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faRefresh}
                  size='lg'
                  className={`mr-2 text-white ${
                    updatingInProgress ? 'spinning' : ''
                  }`}
                />
                Refresh
              </Button>
            ) : null}
          </div>
          <DashboardCard className='p-4' cardBodyclassName='p-0'>
            <FileBrowser
              rootFolder={
                (updatedRootFolder as Folder) ?? (rootFolder as Folder)
              }
              isLoading={isLoading}
            />
          </DashboardCard>
        </div>
      </DashboardWrapper>
    </PageContainer>
  );
}
