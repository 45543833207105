import { InboxIcon } from '@heroicons/react/24/outline';

function EmptyState({ name }: { name?: string }) {
  return (
    <div className='text-lg text-gray-500 text-center m-5'>
      <InboxIcon className='w-16 mx-auto mb-2' />
      No
      {name ? ` ${name} ` : 'ne'}
      found
    </div>
  );
}

export default EmptyState;
export { EmptyState };
