/* eslint-disable no-param-reassign */
import type { User, Client } from '@types';
import { Button, Input } from 'react-daisyui';
import { useForm, Controller } from 'react-hook-form';

import { post, put } from '@/utils/queries';
import { Spinner } from '@/components/Spinner';
import { useState } from 'react';
import { getNewUser } from '@/components/UserList/utils';
import { alertErrorMessage } from '@/utils/alerts';
import { useRollbar } from '@rollbar/react';
import type { ClientListOption, UserFormData } from './types';
import { ClientListInput } from './ClientListInput';

// eslint-disable-next-line import/prefer-default-export
export function EditUser({
  user = getNewUser(),
  onSave,
  close,
  onDisable,
}: {
  user?: User;
  close: () => void;
  onSave: (user: User) => void;
  onDisable: () => void;
}) {
  const rollbar = useRollbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, register, control, watch } = useForm({
    defaultValues: {
      id: user.id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email_address: user?.email_address,
      role: user?.role,
      clients:
        user.clients?.map((client: Client) => ({
          label: client.client_name,
          value: client.id,
        })) ?? [],
    } as UserFormData,
  });

  // we need to live-update showing the client list based
  // on the admin role
  const roleInputValue = watch('role');

  const onSubmit = async (data: UserFormData) => {
    setLoading(true);
    data.clientIds = data.clients?.map(
      (client: ClientListOption) => client.value
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const response = await (user.id
      ? put(`users/${user.id}`, data)
      : post('users', data)
    ).catch((error: Error) => {
      rollbar.error('EditUserForm.tsx - onSubmit', error);
      alertErrorMessage('Error updating the User');
      setLoading(false);
    });

    setLoading(false);
    if (response) {
      onSave(
        user.id
          ? {
              ...user,
              ...data,
              clients: data.clients.map((e) => ({
                id: e.value,
                client_name: e.label,
              })),
              clientIds: data.clientIds,
            }
          : {
              ...data,
              clients: data.clients.map((e) => ({
                id: e.value,
                client_name: e.label,
              })),
              clientIds: data.clientIds,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              id: response.id,
              enabled: true,
            }
      );
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)} data-testid='edit-user-form'>
      <div className='flex w-full justify-between items-center mb-2'>
        <h3 className='text-large font-bold'>
          {user.id ? 'Edit' : 'New'} User
        </h3>{' '}
      </div>

      <div className='flex flex-wrap gap-4 pb-2 mt-4'>
        <div className='capitalize w-[23%] text-sm text-right pr-3'>
          First Name
        </div>
        <div className='font-bold text-sm w-[73%] text-left pl-3'>
          <Input
            size='sm'
            type='text'
            className='border-accent'
            required
            data-testid='first_name'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('first_name')}
          />
        </div>
      </div>
      <div className='flex flex-wrap gap-4 pb-2 mt-4'>
        <div className='capitalize w-[23%] text-sm text-right pr-3'>
          Last Name
        </div>
        <div className='font-bold text-sm w-[73%] text-left pl-3'>
          <Input
            size='sm'
            type='text'
            className='border-accent'
            required
            data-testid='last_name'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('last_name')}
          />
        </div>
      </div>
      <div className='flex flex-wrap gap-4 pb-2 mt-4'>
        <div className='capitalize w-[23%] text-sm text-right pr-3'>Email</div>
        <div className='font-bold text-sm w-[73%] text-left pl-3'>
          <Input
            size='sm'
            type='email'
            className='border-accent'
            placeholder='email'
            data-testid='email_address'
            required
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email_address')}
          />
        </div>
      </div>
      <div className='flex flex-wrap gap-4 pb-2 mt-4'>
        <div className='capitalize w-[23%] text-sm text-right pr-3'>Role</div>
        <div className='font-bold text-sm w-[73%] text-left pl-3'>
          <select
            className='select flex-1 select-sm focus:outline-offset-0 select-bordered border-accent'
            placeholder='role'
            data-testid='role'
            required
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('role')}
            defaultValue='admin'
          >
            <option key='admin' value='admin'>
              Admin
            </option>
            <option key='specialadmin' value='specialadmin'>
              Specialadmin
            </option>
            <option key='superadmin' value='superadmin'>
              Superadmin
            </option>
          </select>
        </div>
      </div>
      {roleInputValue !== 'superadmin' && roleInputValue !== 'specialadmin' && (
        <div className='flex flex-wrap gap-4 pb-2 mt-4'>
          <div className='capitalize w-[23%] text-sm text-right pr-3'>
            Clients
          </div>
          <div className='font-bold text-sm w-[73%] text-left pl-3'>
            <Controller
              name='clients'
              control={control}
              render={({ field }) => (
                <ClientListInput
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{ ...field, ref: null }}
                  multi={user.role !== 'client'}
                />
              )}
            />
          </div>
        </div>
      )}
      <div className='flex flex-wrap gap-4 pb-2 mt-4 justify-center'>
        <div className='capitalize text-sm text-right pr-3'>
          <Button
            size='md'
            color='info'
            className='opacity-70 mr-3'
            onClick={close}
            type='button'
            data-testid='cancel-edit-user'
          >
            Cancel
          </Button>
        </div>
        <div className='font-bold text-sm text-left pl-3'>
          <Button color='accent' size='md' disabled={loading}>
            Save {user.id ? 'Changes' : ''}
            {loading && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
        <div
          className={
            user.role === 'admin'
              ? 'font-bold text-sm text-left pl-3'
              : 'hidden'
          }
        >
          <Button
            color='error'
            size='md'
            disabled={loading || !user?.enabled}
            type='button'
            onClick={() => onDisable()}
          >
            Disable User
            {loading && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
      </div>
    </form>
  );
}
