/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const env = import.meta.env || process.env;
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const pendoScript = `
(function(apiKey){
(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo')
})('${env.VITE_PENDO_KEY || ''}');`;
const usePendo = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  const getPendo = () => (env.VITE_PENDO_KEY ? (window as any)?.pendo : false);
  const isInstalled = () => Boolean(env.VITE_PENDO_KEY);
  const isReady = () =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    getPendo() && getPendo()?.isReady && getPendo()?.isReady() === true;

  return {
    script: env.VITE_PENDO_KEY ? pendoScript : '',
    isReady,
    isInstalled,
    initialize: (config: object) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const pendo = getPendo();
      if (!pendo) return;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      pendo.initialize(config);
    },
    identify: (config: object) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const pendo = getPendo();
      if (!pendo) return;
      if (isReady()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        pendo.identify(config);
      }
    },
  };
};

usePendo.headScript = pendoScript;

export default usePendo;
