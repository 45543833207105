import type { ClientDataSection, AccountData, KVPairs } from '@types';
import { useCallback, memo } from 'react';
import { DashboardCard } from '@/components/DashboardCard';
import { Accordion } from '@/components/Accordion/Accordion';
import UsersAccordionList from '@/components/UsersAccordionList/UsersAccordionList';
import FirmsAccordionList from '@/components/firmsAccordionList/firmsAccordionList';
import { User } from '../../../../shared/types/user';

const NAMES_MAP: KVPairs = {
  company_name: 'Name',
  company_type: 'Type',
  company_domain: 'Domain',
  company_incorporation_year: 'Incorporation Year',
  company_incorporation_state: 'Incorporation State',
  closed_date: 'Closed Date',
  company_group_email: 'Group Email',
  company_referred_by: 'Referred By',
  contact_email: 'Contact Email',
  job_title: 'Job Title',
  deal_name: 'Deal Name',
  amount_monthly: 'MRR',
  monthly_hours_cap: 'Hours Cap',
  entity_type: 'Entity Type',
  industry: 'Industry',
  business_lines: 'Business Lines',
};

const AccountClientDetails: React.FC<{
  clientData: AccountData;
  test?: boolean;
}> = memo(
  ({
    clientData,
    test = false,
  }: {
    clientData: AccountData;
    test?: boolean;
  }) => {
    const renderOptions = useCallback(
      (section: ClientDataSection, exclude: string[]) => {
        try {
          if (clientData[section])
            return Object.entries(clientData[section] as KVPairs).map(
              ([key, value]) => {
                if (!exclude.includes(key) && value) {
                  return (
                    <div key={key} className='pb-1'>
                      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                      <span className='text-gray-400 mr-2'>{`${NAMES_MAP[key]}:`}</span>
                      <span className='text-base font-bold'>
                        {value as string}
                      </span>
                    </div>
                  );
                }
                return null;
              }
            );
        } catch (e) {
          return 'No data';
        }
        return null;
      },
      [clientData]
    );

    const renderDeals = useCallback((deals: KVPairs[]) => {
      if (deals?.length === 0) return null;
      return deals
        .filter(
          (deal) =>
            deal?.status && ['closedwon', 'terminated'].includes(deal.status)
        )
        .map((deal: KVPairs, index: number) => (
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={`deal_${index}`}
            showBottomSeparator
            open={index === 0}
            toggler={
              <div>
                <span className='text-gray-400 mr-2'>
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  {`${NAMES_MAP.deal_name}:`}
                </span>
                <span className='text-base font-bold'>{deal.deal_name}</span>
              </div>
            }
          >
            <div>
              <div className='mt-2'>
                <span className='text-gray-400 mr-2'>
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  {`${NAMES_MAP.monthly_hours_cap}:`}
                </span>
                <span className='text-base font-bold'>
                  {deal.monthly_hours_cap
                    ? `${deal.monthly_hours_cap}h`
                    : 'N/A'}
                </span>
              </div>
            </div>
          </Accordion>
        ));
    }, []);

    return (
      <div className='flex flex-wrap w-full m-4'>
        <div className='w-1/2 p-2 h-auto'>
          <DashboardCard className='h-auto '>
            <>
              <h2 className='text-base uppercase font-bold mb-4'>
                business info
              </h2>
              {renderOptions('business_info', ['company_type'])}
            </>
          </DashboardCard>
        </div>

        <div className='w-1/2 p-2'>
          <DashboardCard>
            <h2 className='text-base uppercase font-bold mb-4'>plan</h2>
            {renderOptions('plan', [
              'deals',
              'company_referred_by',
              'closed_date',
            ])}
            <hr className='mt-4 mb-4' />

            {clientData?.plan?.deals && clientData?.plan?.deals?.length > 0 ? (
              <>
                {renderDeals(clientData?.plan?.deals)}

                <div className='text-center'>
                  <a
                    href='https://meetings.hubspot.com/adelle-barte'
                    target='_blank'
                    className='btn btn-accent btn-sm mt-5 w-[200px]'
                    rel='noreferrer'
                  >
                    Change plan
                  </a>
                </div>
              </>
            ) : null}
          </DashboardCard>
        </div>
        {/* this should be uncommented once the 2fa project is done. */}
        {/* <div className='w-1/2 p-2'>
        <DashboardCard>
          <TwoFASettings />
        </DashboardCard>
      </div> */}

        {clientData?.users?.length > 0 ? (
          <div className='w-1/2 p-2'>
            <DashboardCard>
              <UsersAccordionList
                test={test}
                showDeleteButton
                clientData={clientData}
                users={(clientData?.users || []) as unknown as User[]}
              />
            </DashboardCard>
          </div>
        ) : null}

        <div className='w-1/2 p-2'>
          <DashboardCard>
            <h2 className='text-base uppercase font-bold'>
              connected investors
            </h2>
            <FirmsAccordionList
              showHubspotLink
              clientData={clientData}
              firms={clientData?.firms || []}
            />
          </DashboardCard>
        </div>
      </div>
    );
  }
);

export { AccountClientDetails };
