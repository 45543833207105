/* istanbul ignore file */
import { IUserContext, useUserContext } from '@/context/UserContext';
import * as typeform from '@typeform/embed-react';

const { PopupButton } = typeform;

const FORM_ID = 'Eje4sss0';
const url = window.location.href;

// eslint-disable-next-line import/prefer-default-export
export function BugReportButton() {
  const { auth }: IUserContext = useUserContext(); // Use the context
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const user = auth;

  return (
    <PopupButton
      id={FORM_ID}
      hidden={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        user_id: user?.id.toString() ?? '',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        fname: user?.first_name ?? 'there!',
        url,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        email: user?.email_address ?? 'not@loggedin.com', // This is here until Kate can fix the flow of the form to allow users not logged in to input their details.
      }}
      transitiveSearchParams={['']}
      className='btn bg-teal-500 text-4xl text-white w-15 h-15 rounded-full'
    >
      &#9993;
    </PopupButton>
  );
}
