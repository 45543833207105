import { Logo } from '@/components/Logo';
import { Spinner } from '@/components/Spinner';
import PageContainer from '@/components/PageContainer';

import { get } from '@/utils/queries';

export default function APISample() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { error, data } = get('data');

  return (
    <PageContainer name='sample'>
      <div className='w-full h-screen flex flex-col justify-center items-center'>
        <Logo className='flex items-center text-6xl' />

        <div className='text-sm mt-10'>
          {!data && !error && <Spinner />}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {data && !error && data?.data}
        </div>
      </div>
    </PageContainer>
  );
}
export { APISample };
