import { useEffect, useMemo } from 'react';
import { Alert } from 'react-daisyui';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { Client, SyftProperties } from '@types';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';

import { get } from '@/utils/queries';

import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { HomeClient } from '@/pages/Home/HomeClient';
import { HomeInvestor } from '@/pages/Home/HomeInvestor';
import { useRollbar } from '@rollbar/react';
import { safeParseJson } from '@/components/AccountDetails/utils';
import { getSyftURLToShow } from '@/utils/clientConfigUtils';

interface Props {
  wipeImpersionation?: boolean;
}

export default function Home({ wipeImpersionation }: Props) {
  const {
    auth,
    error: errorAuth,
    companyName,
    impersonatingAccountId,
    setimpersonatingAccountId,
  }: IUserContext = useUserContext();
  const rollbar = useRollbar();

  useEffect(() => {
    if (wipeImpersionation && setimpersonatingAccountId) {
      setimpersonatingAccountId(null);
    }
  }, [wipeImpersionation, setimpersonatingAccountId]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const clientId = useMemo(() => {
    try {
      if (!auth?.isClient && impersonatingAccountId) {
        return impersonatingAccountId;
      }
      const userClientId = auth?.clients?.[0]?.id as number;
      return userClientId;
    } catch (error) {
      rollbar.error('Home.tsx useMemo for clientid', error as Error);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.clients, auth?.isClient, impersonatingAccountId, rollbar]);

  const { data: companyData, error } = get<Client>(
    `account/${clientId as number}`
  ) || {
    data: null,
    error: null,
  };

  const { data: clients } = auth?.isClient
    ? get<Client>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const isInvestor = auth?.isInvestor;

  const syftUrlOptions: string = useMemo(
    () =>
      companyData?.config?.filter(
        (configItem) => configItem?.config_key === 'syft_url'
      )?.[0]?.config_value ?? '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, companyData]
  );

  const parseSyftUrlOptions = safeParseJson(
    syftUrlOptions,
    []
  ) as SyftProperties[];

  if (error || errorAuth) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={(error || errorAuth) as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  if (!auth) {
    return <LoadingPage hasNav />;
  }

  if (!clientId) {
    return (
      <PageContainer name='home'>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <DashboardWrapper companyName={companyName || undefined}>
          <div className='max-w-sm mx-auto p-5'>
            <Alert icon={<ExclamationCircleIcon className='w-6 h-6' />}>
              You must select a client
            </Alert>
          </div>
        </DashboardWrapper>
      </PageContainer>
    );
  }

  const userFirmsIds = auth?.firms?.map((firm) => firm.id) || [];
  const syftUrlToShow = getSyftURLToShow(
    parseSyftUrlOptions,
    userFirmsIds,
    auth?.id
  );

  return (
    <PageContainer name='home'>
      <DashboardWrapper
        companyName={companyName || undefined}
        //
        companyData={(clients as Client[]) || []}
      >
        {impersonatingAccountId || !isInvestor ? (
          <HomeClient
            clientId={clientId}
            syftUrl={
              impersonatingAccountId && auth.isInvestor
                ? syftUrlToShow
                : parseSyftUrlOptions[0]?.syftUrl
            }
          />
        ) : (
          <HomeInvestor />
        )}
      </DashboardWrapper>
    </PageContainer>
  );
}
