import { useEffect, RefObject } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLInputElement>,
  callback: () => void
) => {
  useEffect(() => {
    function handleClickOutside(event: { target: Node | null | EventTarget }) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setTimeout(() => {
          if (ref.current) {
            callback();
          }
        }, 100);
      }
    }
    document.addEventListener(`mouseup`, (event) => handleClickOutside(event));
    return () => {
      document.removeEventListener(`mouseup`, (event) =>
        handleClickOutside(event)
      );
    };
  }, [ref, callback]);
};

export default useClickOutside;
