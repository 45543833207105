import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingPage } from '@/pages/LoadingPage';
import useVerification from '@/hooks/useVerification';
import { useEffect } from 'react';

function useUniversalNavigate() {
  const navigate = useNavigate();

  return function universalNavigate(to: string) {
    if (to.startsWith('http://') || to.startsWith('https://')) {
      // Absolute URL, use window.location
      window.location.href = to;
    } else {
      // Relative path, use navigate
      navigate(to);
    }
  };
}
function MagicLoginCallback({ addInvestor }: { addInvestor?: boolean }) {
  const location = useLocation();
  const universalNavigate = useUniversalNavigate();

  const {
    isVerified,
    redirectUrl,
    error: errorVerification,
  } = useVerification(location.search, addInvestor ?? false);

  useEffect(() => {
    if (isVerified) {
      universalNavigate(redirectUrl);
    }
    if (errorVerification) {
      universalNavigate(
        `/login?error=${addInvestor ? 'addInvestor' : 'login'}`
      );
    }
  }, [
    isVerified,
    errorVerification,
    redirectUrl,
    addInvestor,
    universalNavigate,
  ]);

  return (
    <div>
      <LoadingPage />
    </div>
  );
}

export default MagicLoginCallback;
