import React, { useEffect } from 'react';

interface PasswordPolicyCheckerProps {
  password: string;
  isVisible?: boolean;
  onPasswordValidChange?: (isValid: boolean) => void;
}

const getPasswordPolicy = (password: string) => [
  {
    key: '10characters',
    label: 'At least 10 characters',
    isValid: password.length >= 10,
  },
  { key: 'number', label: 'At least a number', isValid: /\d/.test(password) },
  {
    key: 'symbol',
    label: 'At least a symbol',
    isValid: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
  },
  {
    key: 'capital',
    label: 'At least any capital letter',
    isValid: /[A-Z]/.test(password),
  },
];

function PasswordPolicyChecker({
  password,
  isVisible = true,
  onPasswordValidChange,
}: PasswordPolicyCheckerProps) {
  const passwordPolicy = getPasswordPolicy(password);

  useEffect(() => {
    const isPasswordValid = passwordPolicy.every((policy) => policy.isValid);

    if (onPasswordValidChange) {
      onPasswordValidChange(isPasswordValid);
    }
  }, [password, onPasswordValidChange, passwordPolicy]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='mt-4'>
      <h4>Password Policy:</h4>
      <ul>
        {passwordPolicy.map((policy) => (
          <li
            key={policy.key}
            className={policy.isValid ? 'text-green-600' : 'text-red-600'}
          >
            {policy.isValid ? '✓' : '✗'} {policy.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PasswordPolicyChecker;
